.homepage-slider-section-pp {
    background-color: white;
    position: relative;
    .shows-container {
        width: 97%;
        margin-right: 100px;
        .flex-content {
            position: relative;
            width: 100%;
            .slick-containers {
                position: relative;
                display: flex;
                width: 77%;
                margin: 0 auto;
                margin-top: 16vh;
                flex: 1 0 auto;
            }
            .description-slick {
                width: 100%;
                &.single-slide {
                    .slick-list {
                        width: 100%;
                    }
                    .slick-track {
                        width: 100% !important;
                        .slick-slide {
                            width: 100% !important;
                        }
                    }
                }
                .shows-slick {
                    color: white;
                    display: flex;
                    height: 400px;
                    margin-top: 35px;
                    background-color: $secondary-color;
                    .current-slide {
                        display: none;
                    }
                    .text-content {
                        padding: 30px;
                        margin-left: 30px;
                        width: 45%;
                        .guest {
                            background-color: white;
                            color: $secondary-color;
                            padding: 7px 10px;
                            font-style: inherit;
                            text-transform: uppercase;
                        }
                        h3 {
                            margin-block-end: 0;
                            margin-bottom: 5px;
                        }
                        .date {
                            font-family: $font-medium-italic;
                            font-size: 13px;
                            @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
                                font-family: $font-regular;
                                font-style: italic;
                            }
                        }
                        a {
                            display: flex;
                            align-items: center;
                            color: white;
                            text-decoration: none;
                            max-width: 250px;
                            &:focus {
                                outline: none;
                            }
                            p {
                                font-family: $font-semibold;
                            }
                            img {
                                height: 23px;
                                margin-right: 10px;
                            }
                        }
                    }
                    .show-image {
                        width: 55%;
                        position: relative;
                        .container {
                            height: 107%;
                            margin-right: 20px;
                            position: absolute;
                            bottom: 0;
                            overflow: hidden;
                            width: 100%;
                            img {
                                height: 100%;
                                width: auto;
                                max-width: 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                } 
                .slick-dots {
                    text-align: end;
                    li {
                        margin: 0;
                        button {
                            &:before {
                                font-size: 35px;
                                opacity: 1;
                                color: $light-bg;
                            }
                        }
                    }
                    .slick-active {
                        button {
                            &:before {
                                color: $secondary-color;
                            }
                        }
                    }  
                }
            }
            .big-title {
                transform: rotate(-90deg);
                font-size: 75px;
                position: absolute;
                right: 0;
                top: 117px;
                right: -239px;
                color: $main-color;
                font-family: $font-semibold;
            }
        }
        .slide-number {
            margin-left: 11%;
            font-size: 110px;
            margin-block-start: 0;
            margin-block-end: 0;
            color: $medium-grey;
            display: flex;
            align-items: center;
            position: relative;
            max-height: 85px;
            overflow: hidden;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                max-height: 110px;
            }
            p {
                font-size: 110px;
                margin-block-start: 0;
                margin-block-end: 0;
                margin-top: 0;
                @supports (-ms-ime-align:auto) {
                    margin-top: 100px;
                }
            }
            .pagination-slick {
                width: 100%;
            }
        }
    }
    .red-block {
        height: 100%;
        width: 130px;
        position: absolute;
        right: 0;
        top: 25px;
        background-color: $secondary-color;
        @supports (-ms-ime-align:auto) {
            top: 75px;
        }
    }
}

@media screen and (max-width: #{$desktop-middle}px) {
    .homepage-slider-section-pp {
        .shows-container {
            .flex-content {
                .description-slick {
                    .shows-slick {
                        .show-image {
                            .container {
                                bottom: -25px;
                                img {
                                    height: 88%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .red-block {
            width: 100px;
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    .homepage-slider-section-pp {
        .shows-container {
            .flex-content {
                .big-title {
                    font-size: 60px;
                    right: -196px;
                }
                .description-slick {
                    .shows-slick {
                        height: 370px;
                        margin-top: 25px;
                        .show-image {
                            .container {
                                bottom: -32px;
                                img {
                                    min-height: 85%;
                                    max-width: 102%;
                                }
                            }
                        }
                        .text-content {
                            padding: 15px;
                        }
                    }
                }
            }
        }
        .red-block {
            width: 70px;
            top: 80px;
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .homepage-slider-section-pp {
        .shows-container {
            .flex-content {
                .description-slick {
                    .shows-slick {
                        .show-image {
                            width: 60%;
                            .show-image {
                                .container {
                                    img {
                                        max-height: 80%;
                                    }
                                }
                            }
                        }
                        .text-content {
                            width: 40%;
                        }
                    }
                }
            }
            .slide-number {
                margin-left: 11%;
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .homepage-slider-section-pp {
        padding-bottom: 100px;
        .shows-container {
            margin-right: 0;
            .flex-content {
                .slick-containers {
                flex-direction: column-reverse;
                width: 80%;
                margin: 0 auto;
                    .big-title {
                        right: auto;
                        position: relative;
                        transform: none;
                        font-size: 30px;
                        text-align: center;
                        text-transform: uppercase;
                        margin-top: 60px;
                        margin-block-end: 0;
                        top: 0;
                    }
                    .description-slick {
                        margin-top: 0;
                        .shows-slick {
                            flex-direction: column;
                            height: 500px;
                            .text-content {
                                width: 90%;
                                margin: 0 auto;
                            }
                            .show-image {
                                width: 100%;
                                height: 100%;
                                .container {
                                    height: 320px;
                                    width: 65%;
                                    right: 0;
                                    margin-right: 0;
                                    bottom: -31px;
                                    img {
                                        max-width: 85%;
                                        min-height: 82%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .slide-number {
                display: none;
            }
        }
        .red-block {
            width: 100%;
            height: 70px;
            bottom: 0;
            top: inherit;
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    .homepage-slider-section-pp {
        .shows-container {
            width: 100%;
            .flex-content {
                .slick-containers {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .homepage-slider-section-pp {
        .shows-container {
            .flex-content {
                .slick-containers {
                    .description-slick {
                        width: 100%;
                        .shows-slick {
                            width: 100%;
                            height: 720px;
                            .show-image {
                                .container {
                                    width: 100%;
                                    height: 100%;
                                    margin-right: 0;
                                    img {
                                        min-height: 67%;
                                        width: 80%;
                                        top: 60%;
                                        height: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    .homepage-slider-section-pp {
        .shows-container {
            .flex-content {
                .slick-containers {
                    .description-slick {
                        .shows-slick {
                            height: 705px;
                            .show-image {
                                .container {
                                    img {
                                        min-height: 85%;
                                        top: 50%;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}