@charset "UTF-8";

/**
 * @name Mascarille
 * @author Les Vikings
 */
 $medias_dir: "../../../assets/";
 $font_dir: "../../../assets/fonts/montserrat/";

 /* BREAKPOINTS */

$desktop-large:    1920;
$desktop-middle:   1600;
$desktop-medium:   1440;
$desktop-small:    1280;
$tablet-landscape: 1024;
$tablet-medium:    900;
$tablet-portrait:  768;
$mobile-landscape: 640;
$mobile-portrait:  420;


/* COLORS */

$main-color: #404040;
$darker-main-color: #343434;
$lighter-main-color: #5b5b5b;
$secondary-color: #8b0000;
$darker-secondary-color: #6b0101;
$lighter-secondary-color: #a80101;
$light-bg: #f2f2f2;
$medium-grey: #d9d9d9;
$lighter-gray: #f7f4f6;
$success-color: #4ca14e;

/* FONTS */

$font-regular: 'Montserrat Regular';
$font-medium: 'Montserrat Medium';
$font-medium-italic: 'Montserrat Medium Italic';
$font-semibold: 'Montserrat Semibold';
$font-bold: 'Montserrat Bold';

/* VARS */
$header-height: 77px;


/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat Regular';
    font-style: normal;
    font-weight: 400;
    src: url('#{$font_dir}montserrat-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('#{$font_dir}montserrat-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font_dir}montserrat-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font_dir}montserrat-v13-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font_dir}montserrat-v13-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
         url('#{$font_dir}montserrat-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font_dir}montserrat-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-500.woff') format('woff'), /* Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font_dir}montserrat-v13-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-500italic - latin */
  @font-face {
    font-family: 'Montserrat Medium Italic';
    font-style: italic;
    font-weight: 500;
    src: url('#{$font_dir}montserrat-v13-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
         url('#{$font_dir}montserrat-v13-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font_dir}montserrat-v13-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font_dir}montserrat-v13-latin-500italic.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-600 - latin */
  @font-face {
    font-family: 'Montserrat Semibold';
    font-style: normal;
    font-weight: 600;
    src: url('#{$font_dir}montserrat-v13-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
         url('#{$font_dir}montserrat-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font_dir}montserrat-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-600.woff') format('woff'), /* Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font_dir}montserrat-v13-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-weight: 700;
    src: url('#{$font_dir}montserrat-v13-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
         url('#{$font_dir}montserrat-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font_dir}montserrat-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-700.woff') format('woff'), /* Modern Browsers */
         url('#{$font_dir}montserrat-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font_dir}montserrat-v13-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
