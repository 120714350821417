@charset "UTF-8";

/** Generic form styles **/

.generic-form {
    input {
        width: 100%;
        border: none;
        border-bottom: $secondary-color 5px solid;
        font-family: $font-regular;
        padding-bottom: 8px;
        margin-bottom: 20px;

        &:focus {
            outline: none;
        }
    }

    select {
        width: 100%;
        border: none;
        border-bottom: $secondary-color 5px solid;
        font-family: $font-regular;
        margin-top: 8px;
        margin-bottom: 20px;
        padding-bottom: 8px;
        background: none;
        background: url("#{$medias_dir}/images/arrow-select.png") no-repeat center;
        -webkit-appearance: none;
        background-position-x: 100%;

        &:focus {
            outline: none;
        }
    }

    .field {
        position: relative;

        .checkbox {
            margin-bottom: 10px;

            input {
                width: 6px;
                opacity: 0;
                position: absolute;
                margin-top: 0;
            }

            label {
                width: 95%;
                margin-top: 15px;
                color: $main-color;
                font-family: $font-regular;
                font-size: 13px;

                a {
                    color: $secondary-color;
                    text-decoration: none;
                }
            }

            input + label:before {
                content: '';
                border: 3px solid $secondary-color;
                display: inline-block;
                vertical-align: middle;
                width: 6px;
                height: 6px;
                padding: 2px;
                margin-right: 5px;
                text-align: center;
            }

            input:checked + label:before {
                content: "";
                border: 3px solid $secondary-color;
                background: $secondary-color;
            }
        }
    }

    .field-with-label {
        display: flex;
        flex-direction: column;
    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        button {
            background-color: $secondary-color;
            color: white;
            border: 0;
            padding: 10px 15px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: $font-medium;

            &:focus {
                outline: none;
            }
        }
    }
}
