body {
    &.homepage {
        main {
            display: flex;
            align-items: center;
        }
    }
}
.homepage-sliders {
    width: 75vw;
    height: 100vh;
    color: $main-color;
    .container {
        display: block !important;
        width: 80%;
        margin: 0 auto;
        position: relative;
        height: 85vh;
        margin-top: 18vh;
        h2 {
            font-size: 25px;
        }
        .sliders-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .content-slider {
                position: relative;
                width: 35%;
                margin-right: 70px;
                .slider {
                    position: relative;
                    h3 {
                        margin-block-start: 0;
                    }
                    .cards {
                        .slick-dots {
                            text-align: center;
                            li {
                                margin: 0;
                                button {
                                    &:before {
                                        font-size: 35px;
                                        opacity: 1;
                                        color: $light-bg;
                                    }
                                }
                            }
                            .slick-active {
                                button {
                                    &:before {
                                        color: $secondary-color;
                                    }
                                }
                            }  
                        }
                        .single-card {
                            height: auto;
                            .container-image {
                                width: 100%;
                                height: 32vh;
                                overflow: hidden;
                                position: relative;
                                img {
                                    width: 100%;
                                    display: block;
                                    margin: 0 auto 0 auto;
                                    height: auto;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    min-height: 100%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                            .card-body {
                                padding-top: 10px;
                                text-align: center;
                                min-height: 30vh;
                                background-color: $medium-grey;
                                .header {
                                    margin-bottom: 25px;
                                    margin-top: 5vh;
                                    p {
                                        margin-block-start: 0;
                                        margin-block-end: 0;
                                        font-family: $font-semibold;
                                        text-transform: uppercase;
                                        font-size: 20px;
                                    }
                                    span {
                                        font-style: inherit;
                                    }
                                }
                                p {
                                    width: 90%;
                                    margin: 0 auto;
                                }
                                .button-animation-container {
                                    margin-top: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: #{$desktop-medium}px) {
    .homepage-sliders {
        width: 100vw;
    }
}
@media screen and (max-width: #{$tablet-landscape}px) {
    .homepage-sliders {
        width: 100%;
        margin-bottom: 25px;
        height: auto;
        .container {
            width: 90%;
            .sliders-container {
                .right-slider {
                    margin-right: 0;
                }
                .content-slider {
                    width: 46%;
                    .slider {
                        .cards {
                            .single-card {
                                .card-body {
                                    min-height: 250px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: #{$tablet-portrait}px) {
    .homepage-sliders {
        margin-bottom: 70px;
        .container {
            height: 100%;
            .sliders-container {
                .content-slider {
                    .slider {
                        .cards {
                            .single-card {
                                .container-image {
                                    height: 225px;
                                }
                                .card-body {
                                    .header {
                                        height: 65px;
                                        p {
                                            font-size: 18px;
                                        }
                                    }
                                    p {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: #{$mobile-landscape}px) {
    .homepage-sliders {
        min-height: 1230px;
        margin-bottom: 0;
        .container {
            margin-top: 0;
            .sliders-container {
                flex-direction: column;
                .content-slider {
                    margin-right: 0;
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
}