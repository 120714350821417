.shows-listing {
    position: relative;
    width: 100%;
    &.over {
        .show-list {
            ul {
                li {
                    .show-poster {
                        .container {
                            a {
                                .overlay {
                                    display: block;
                                    height: 100%;
                                    width: 100%;
                                    background-color: #ececec;
                                    opacity: 0.6;
                                }
                                .ended-show {
                                    width: 80%;
                                    height: 55%;
                                    min-height: inherit;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .shows-header {
        width: 100%;
        height: 350px;
        position: relative;
        .image {
            width: 70%;
            overflow: hidden;
            position: relative;
            height: 100%;
            img {
                height: auto;
                min-width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-height: 100%;
            }
        }
        .title {
            width: 30%;
            height: 100%;
            background-color: $secondary-color;
            h1 {
                text-transform: initial;
                color: white;
                text-align: center;
                margin-top: 30%;
            }
        }
    }
    .sub-nav {
        background-color: $secondary-color;
        font-family: $font-regular;
        .mobile-menu {
            display: none;
        }
        ul {
            margin: 0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            width: 426px;
            margin: 0 auto;
            color: $main-color;
            position: relative;
            height: 50px;
            .show-status {
                text-transform: uppercase;
                font-size: 13px;
                display: flex;
                height: 100%;
                position: relative;
                align-items: center;
                padding: 0 20px;
                justify-content: center;
                &.active {
                    background-color: white;
                    a {
                        color: $main-color;
                    }
                }
                &:hover {
                    background-color: white;
                    a {
                        color: $main-color;
                    }
                }
                a {
                    color: white;
                    text-decoration: none;
                    position: relative;
                }
            }
        }
    }
    .shows-introduction {
        width: 750px;
        margin: 0 auto;
        text-align: center;
        color: $main-color;
        margin-top: 40px;
        border-bottom: 2px solid $light-bg;
        padding-bottom: 20px;
        h2 {
            text-transform: uppercase;
        }
    }
    .show-list {
        width: 1200px;
        margin: 50px auto;
        color: $main-color;
        ul {
            li {
                margin: 60px 0;
                &:nth-child(2n) {
                    flex-direction: row-reverse;
                }
                .show-poster {
                    width: 45%;
                    .container {
                        width: 85%;
                        margin: 0 auto;
                        height: 450px;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: auto;
                            display: block;
                            margin: 0 auto 0 auto;
                            height: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            min-height: 100%;
                            transform: translate(-50%, -50%);
                        }
                        a {
                            .overlay, .ended-show {
                                display: none;
                            }
                        }
                    }
                }
                p {
                    justify-content: center;
                    display: flex;
                    width: 100%;
                }
                .block-show {
                    width: 55%;
                    padding-left: 80px;
                    a {
                        text-decoration: none;
                        color: $main-color;
                    }
                    h3 {
                        text-transform: uppercase;
                        margin-block-end: 0;
                    }
                    p {
                        text-align: left;
                        display: block;
                    }
                    .theater {
                        p {
                            padding-right: 10px;
                            border-right: 1px solid $main-color;
                            width: auto;
                            &.author {
                                border: none;
                                padding-left: 10px;
                                font-style: italic;
                            }
                        }
                    }
                    .flex {
                        img {
                            height: 20px;
                            margin-right: 10px;
                        }
                    }
                    .description {
                        width: 70%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width :#{$desktop-small}px) {
    .shows-listing {
        .shows-header {
            .title {
                h1 {
                    margin-top: 50%;
                }
            }
        }
        .show-list {
            width: 1000px;
            ul {
                li {
                    .show-poster {
                        .container {
                            height: 360px;
                        }
                    }
                    .block-show {
                        .description {
                            width: 85%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .shows-listing {
        .shows-header {
            flex-direction: column;
            margin-top: 63px;
            height: inherit;
            .image {
                width: 100%;
                height: 250px;
            }
            .title {
                width: 100%;
                height: 100px;
                h1 {
                    margin-top: 30px;
                }
            }
        }
        .shows-introduction {
            width: 600px;
        }
        .show-list {
            width: 95%;
            ul {
                li {
                    &.flex {
                        flex-direction: column;
                    }
                    .show-poster {
                        width: 80%;
                        .container {
                            height: 550px;
                        }
                    }
                    .block-show {
                        width: 85%;
                        margin-top: 30px;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    .shows-listing {
        .show-list {
            ul {
                li {
                    .show-poster {
                        .container {
                            height: 415px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .shows-listing {
        .shows-header {
            z-index: 3;
        }
        .shows-introduction {
            width: 85%;
        }
        .sub-nav {
            position: relative;
            .mobile-menu {
                display: flex;
                text-transform: uppercase;
                justify-content: center;
                cursor: pointer;
                align-items: center;
                background-color: #d9d9d9;
                position: relative;
                z-index: 3;
                img {
                    height: 12px;
                    transform: rotate(-90deg);
                    margin-left: 10px;
                }
            }
            ul {
                background: #d9d9d9;
                position: absolute;
                left: 0;
                top: -100%;
                width: 100%;
                height: auto;
                z-index: 1;
                transform:translateY(-100%);
                transition: all 250ms ease;
                flex-direction: column;
                padding-left: 0;
                &.active {
                    transform:translateY(0);
                    top: 40px;
                }
                .show-status {
                    margin: 8px 0;
                    &.active {
                        background-color: transparent;
                    }
                }
            }
        }
        .show-list {
            ul {
                padding-left: 0;
                li {
                    .show-poster {
                        width: 100%;
                        .container {
                            width: 100%;
                        }
                    }
                    .block-show {
                        .description {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    .shows-listing {
        .show-list {
            ul {
                li {
                    .show-poster {
                        .container {
                            height: 325px;
                        }
                    }
                }
            }
        }
    }
}