.checkout {
    .thank-you {
        width: 50%;
        margin: auto;
        text-align: center;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3 {
            font-size: 25px;
            text-transform: uppercase;
            color: $main-color;
            margin-bottom: 0;
        }
        .subtitle {
            margin-top: 0;
            color: $main-color;
            font-size: 14px;
        }
        .text {
            color: $main-color;
            font-size: 14px;
            margin-bottom: 60px;
        }
        .button-wrap {
            .button-animation-container {
                margin: 0 10px;
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .checkout {
        .thank-you {
            height: auto;
            width: 90%;
            min-height: 85vh;
            img {
                width: 200px;
            }
            h3 {
                font-size: 25px;
                text-transform: uppercase;
                color: $main-color;
                margin-bottom: 0;
            }
            .subtitle {
                margin-top: 0;
                color: $main-color;
                font-size: 14px;
            }
            .text {
                margin-bottom: 30px;
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .checkout {
        .thank-you {
            min-height: 80vh;
            .button-wrap {
                .button-animation-container {
                    margin: 10px 0;
                }
            }
        }
    }
}