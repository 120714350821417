.checkout {
    .column-wrap {
        .column {
            &.payment {
                width: 65%;
                background-color: white;
                position: relative;
                h3 {
                    color: $main-color;
                }
                #sylius-payment-methods {
                    width: 90%;
                    margin: auto;
                    .unmargined {
                        margin: 40px 0;
                        .header {
                            font-family: $font-medium;
                        }
                    }
                    .item {
                        margin-bottom: 10px;
                        .custom-control {
                            display: flex;
                            align-items: center;
                            p {
                                margin-left: 5px;
                            }
                        }
                    }
                    .img-wrap {
                        padding-left: 25px;
                        span {
                            background-repeat: no-repeat;
                            display: flex;
                            height: 40px;
                            width: 100px;
                            &.bankcard {
                                background-image: url(#{$medias_dir}/medias/cards.png);
                            }
                            &.paypal {
                                background-image: url(#{$medias_dir}/medias/paypal.png);
                            }
                        }
                    }
                }
                .button-wrap {
                    position: absolute;
                    bottom: 0;
                    width: 90%;
                    align-items: center;
                    .regular-button {
                        &.action {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .checkout {
        .column-wrap {
            .column {
                &.payment {
                    width: 100%;
                    .button-wrap {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .regular-button {
                            text-align: center;
                            &.dark {
                                font-size: 13px!important;
                                display: inline-block;
                                width: -webkit-fill-available;
                            }
                            &.action {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .checkout {
        .column-wrap {
            .column {
                &.payment {
                    .button-wrap {
                        position: relative;
                        width: 100%;
                        align-items: initial;
                        .regular-button {
                            &.dark {
                                font-size: 13px!important;
                                display: inline-block;
                                width: -webkit-fill-available;
                            }
                            &.action {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}