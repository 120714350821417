@charset "utf-8";

.sylius-flash-message, .ui.icon.message {
    background-color: white;
    border-radius: 50px;
    padding: 5px 30px;
    margin-bottom: 10px;

    &.positive {
        color: $success-color;
    }

    &.negative {
        color: $secondary-color;
    }

    &.info {
        color: $success-color;
    }

    .content {
        padding-top: 10px;

        .header {
            font-family: $font-medium;
            font-size: 14px;
        }

        p {
            margin-top: 0;
        }
    }
}


@media screen and (max-width: #{$tablet-landscape}px) {
    .sylius-flash-message {
        border-radius: initial;
        padding: 5px 10px;
    }
}
