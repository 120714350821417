.single-page {
    min-height: 61vh;
    width: 100%;
    padding-top: 110px;

    .flex {
        display: flex;
        align-items: center;
    }

    .header {
        width: 100%;
        height: 350px;
        position: relative;
        .image {
            width: 70%;
            overflow: hidden;
            position: relative;
            height: 100%;
            img {
                height: auto;
                min-width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-height: 100%;
            }
        }
        .title {
            width: 30%;
            height: 100%;
            background-color: $secondary-color;
            h1 {
                text-transform: initial;
                color: white;
                text-align: center;
                margin-top: 30%;
            }
        }
    }

    .introduction {
        width: 750px;
        margin: 0 auto;
        text-align: center;
        color: $main-color;
        margin-top: 40px;
        border-bottom: 2px solid $light-bg;
        padding-bottom: 20px;
        h2 {
            text-transform: uppercase;
        }
    }

    .wysiwyg {
        margin: auto;
        width: 80%;
        color: $main-color;
        padding-bottom: 20px;
    }

    .container {
        width: 80%;
        margin: auto;
        display: flex;
        padding: 60px 0;
        justify-content: space-between;
        div {
            width: 48%;
        }
        .wysiwyg {
            border: none;
            text-align: left;
            padding: 0;
            margin: auto 0;
        }
    }
    .media {
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
            width: 100%;
        }
    }

    .container-img {
        height: 280px;
        position: relative;
        margin-bottom: 60px;
        .image {
            height: 280px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
            }
        }
        .content-wrap {
            position: absolute;
            top: 50px;
            width: 80%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .single-page {
        min-height: 70vh;
        padding-top: 75px;
        .header {
            flex-direction: column;
            margin-top: 63px;
            height: inherit;
            .image {
                width: 100%;
                height: 250px;
            }
            .title {
                width: 100%;
                height: 100px;
                h1 {
                    margin-top: 30px;
                }
            }
        }
        .introduction {
            width: 600px;
        }

        .wysiwyg {
            width: 95%;
            margin: auto;
        }

        .container {
            width: 100%;
            flex-direction: column;
            padding: 20px 0;
            div {
                width: 100%;
            }
            .wysiwyg {
                width: 95%;
                margin: auto;
            }
            .media {
                height: 150px;
            }
        }
        .media {
            height: 150px;
        }

        .container-img {
            height: 150px;
            margin-bottom: 20px;
            .image {
                height: 150px;
            }
            .content-wrap {
                top: 0;
                text-align: center;
                .content {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .single-page {
        .introduction {
            width: 90%;
        }

    }
}
