.homepage-intro-section-pp {
    display: flex;
    .block-content {
        height: 87%;
        margin-top: 13%;
        width: 40vw;
        background-color: $main-color;
        color: white;
        position: relative;
        .float-text {
            position: absolute;
            margin-top: 15vh;
            left: 100px;
            width: 100%;
            z-index: 3;
            .ticket-price {
                display: flex;
                align-items: center;
                margin: 0 auto;
                img {
                    height: 20px;
                    margin-right: 10px;
                }
                p {
                    margin-right: 10px;
                }
            }
            .bottom-content {
                margin-bottom: 20px;
            }
            a {
                display: flex;
                align-items: center;
                color: white;
                text-decoration: none;
                width: fit-content;
                &:focus {
                    outline: none;
                }
                p {
                    font-family: $font-semibold;
                }
                img {
                    height: 23px;
                    margin-right: 10px;
                }
            }
        }
        .social-medias {
            position: absolute;
            bottom: 100px;
            left: 100px;
            img {
                height: 30px;
                margin-right: 10px;
            }
            a {
                text-decoration: none;
            }
        }
    }
    .block-video {
        width: 60vw;
        height: 100%;
        position: relative;
        overflow: hidden;
        .bg-vid {
            min-height: 100%;
            min-width: 110%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    .homepage-intro-section-pp {
        .block-content {
            width: 35vw;
        }
        .block-video {
            width: 55vw;
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .homepage-intro-section-pp {
        .block-content {
            width: 40vw;
            height: 88%;
            margin-top: 12%;
            .float-text {
                margin-top: 6vh;
            }
        }
        .block-video {
            width: 60vw;
        }
    }
}

@media screen and (max-width: #{$tablet-medium}px) {
    .homepage-intro-section-pp {
        flex-direction: column;
        height: auto !important;
        .block-content {
            width: 100%;
            margin-top: 125px;
            height: auto;
            padding: 45px 0 15px 0;
            .float-text {
                position: relative;
                left: initial;
                width: 85%;
                margin: 0 auto;
                text-align: center;
                z-index: initial;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    left: 0;
                }
                .ticket-price {
                    justify-content: center;
                }
            }
            .social-medias {
                position: relative;
                bottom: initial;
                left: initial;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                img {
                    margin: 5px;
                }
            }
        }
        .block-video {
            height: 670px;
            width: 100%;
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .homepage-intro-section-pp {
        .block-content {
            .float-text {
                .ticket-price {
                    width: 320px;
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    .homepage-intro-section-pp {
        .block-content {
            .float-text {
                width: 100%;
            }
        }
        .block-video {
            height: 500px;
            .bg-vid {
                max-width: 175%;
            }

        }
    }
}
