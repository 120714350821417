.create-account-page {
  background: $light-bg;
    .container {
        width: 80%;
        margin: 0 auto;
        padding-top: 150px;
        font-family: $font-regular;
        min-height: 90vh;
        .header-account {
            width: 80%;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            .icon {
                background-image: url(#{$medias_dir}/images/icon-your-account.svg);
                background-repeat: no-repeat;
                background-position: center center;
                display: flex;
                height: 100px;
                width: 100px;
                margin-right: 30px;
            }
            h1 {
                font-size: 26px;
                font-family: $font-semibold;
                color: $main-color;
                text-transform: uppercase;
                margin: 0;
            }
            .login-path {
                a {
                    color: $secondary-color;
                    font-size: 13px;
                    margin-top: 5px;
                }
            }
        }
        .create-account-container {
          background: white;
          padding: 70px;
            form {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                .left-container, .right-container {
                  width: 50%;
                    input {
                        width: 100%;
                        border: none;
                        border-bottom: 3px solid $secondary-color;
                        margin-bottom: 15px;
                        font-family: $font-regular;
                        &:focus {
                            outline: none;
                        }
                    }
                    .global-informations {
                        width: 90%;
                    }

                    .field {
                      .checkbox {
                        margin-bottom: 10px;
                        input {
                            width: 6px;
                            opacity: 0;
                            position: absolute;
                            margin-top: 0;
                        }
                        label {
                            width: 95%;
                            margin-top: 15px;
                            color: $main-color;
                            font-family: $font-regular;
                            font-size: 13px;
                            a {
                                color: $secondary-color;
                                text-decoration: none;
                            }
                        }
                        input + label:before {
                            content: '';
                            border: 3px solid $secondary-color;
                            display: inline-block;
                            vertical-align: middle;
                            width: 6px;
                            height: 6px;
                            padding: 2px;
                            margin-right: 5px;
                            text-align: center;
                        }

                        input:checked + label:before {
                            content: "";
                            border: 3px solid $secondary-color;
                            background: $secondary-color;
                        }
                      }
                    }
                }
              .submit-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
              }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
  .create-account-page {
    .container {
      width: 90%;
      padding-top: 100px;

      .header-account {
        h1 {
          font-size: 16px;
        }
        .icon {
            height: 60px;
            background-size: 50px 50px;
            margin-right: 10px;
            width: 60px;
        }
      }

      .create-account-container {
        padding: 50px;
        form {
          flex-direction: column;
            .left-container, .right-container {
              width: 100%;

              .global-informations {
                width: 100%;
              }
            }
        }
      }
    }
  }
}

@media screen and (max-width: #{$mobile-portrait}px) {
  .create-account-page {
    .container {
      .header-account {
        width: 100%;

        .icon {
          flex-shrink: 0;
        }
      }
      .create-account-container {
        padding: 20px;
      }
    }
  }
}
