body {
    &.homepage {
        .main-header {
            &#header-top {
                &.top {
                    top: 25px;
                }
            }
        }
    }
}
.main-header {
    display: flex;
    height: 77px;
    position: fixed;
    width: 97vw;
    left: 3vw;
    top: 25px;
    background-color: $main-color;
    z-index: 3;
    &#header-top {
        transition: 0.7s;
        .scrolled & {
            top: 0;
        }
    }
    .container-fluid {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .main-header__logo {
            display: contents;
            img {
                height: 70px;
                margin: 10px 20px;
            }
        }
        .menu {
            font-family: $font-regular;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            right: 15px;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                top: -16px;
            }
            @supports (-ms-ime-align:auto) {
                top: -16px;
            }
            .navigation-bar {
                height: 100%;
                position:relative;
                .nav-mobile-logo {
                    display: none;
                }
                .header-nav-list {
                    display: flex;
                    margin-block-end: 0;
                    margin-block-start: 0;
                    height: 100%;
                    list-style: none;
                    margin-right: 10px;
                    .nav-item {
                        margin: 0 5px;
                        // padding-top: 28px;
                        position: relative;
                        flex-direction: column;
                        .nav-item-container{
                            display: flex;
                            align-items: center;
                            height: 100%;
                        span {
                            display: none;
                        }
                        a {
                            color: white;
                            text-transform: uppercase;
                            text-decoration: none;
                            padding: 15px 20px;
                            position: relative;
                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 20px;
                                bottom: 10px;
                                width: 0;
                                height: 2px;
                                background-color: white;
                                transition: all ease 0.5s;
                            }
                            &:hover {
                                &:after {
                                    width: 50%;
                                    height: auto;
                                }
                            }
                        }
                    }
                    &:hover{
                        .menu_level_1{
                            top: 77px;
                            overflow: visible;
                            display: block
                        }
                    }
                        .menu_level_1{
                            padding: 0px;
                            display: none;
                            width: 100%;                       
                            li{
                                list-style-type: none;
                                background-color: $main-color;
                                margin: 0px;
                                padding: 20px 10px 0px 10px;
                                transition: background-color 0.3s ease-out;  
                                
                                &:hover{
                                    background-color: $light-bg;                                  
                                    span{
                                      color: $main-color;
                                    }
                                }

                                &:after{
                                    content: '';
                                    height: 1px;
                                    width: 70%;
                                    background-color: $light-bg;
                                    display: block;
                                    margin: 0 auto;
                                    margin-top: 20px
                                }
                                &:last-child{
                                    padding-bottom: 20px;
                                    &:after{ 
                                    display: none;
                                    }
                                }
                                &:first-child{
                                    position: relative;
                                    &:before{ 
                                    content: '';
                                    display: block;
                                    height: 2px;
                                    width: 100%;
                                    background-color: $light-bg;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    }
                                }
                                span{
                                    display: block;
                                    color: $light-bg;
                                    font-style: inherit;
                                    font-size: 13px;
                                    text-transform: uppercase;
                                    text-align: center;
                                }
                            }
                            
                        }
                    }
                }
            }
            .cart-icon {
                background-color: $secondary-color;
                color: white;
                font-family: $font-regular;
                text-transform: uppercase;
                text-decoration: none;
                display: flex;
                height: 100%;
                align-items: center;
                padding: 0 30px;
                #sylius-cart-button {
                    display: flex;
                    align-items: center;
                    position: relative;
                    p {
                        font-size: 17px;
                    }
                    .cart-count {
                        color: $secondary-color;
                        font-family: $font-semibold;
                        position: relative;
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        background-color: white;
                        position: absolute;
                        right: -22px;
                        top: 5px;
                        width: 20px;
                        height: 20px;
                        border-radius: 15px;
                    }
                }
            }
            .account, .cart-icon {
                margin-right: 15px;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    padding-top: 35px;
                }
                img {
                    height: 30px;
                }
            }
            .burger {
                display: none;
            }
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    .main-header {
        .container-fluid {
            .menu {
                .navigation-bar {
                    .header-nav-list {
                        .nav-item a {
                            font-size: 14px;
                        }
                    }
                }
                .cart-icon {
                    #sylius-cart-button {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .main-header {
        top: 15px;
        .container-fluid {
            .menu {
                .navigation-bar {
                    .header-nav-list {
                        .nav-item {
                            font-size: 15px;
                            a {
                                padding: 15px;
                            }
                        }
                    }
                }
                .cart-icon {
                    padding: 0 15px;
                    #sylius-cart-button {
                        p {
                            font-size: 15px;
                        }
                        .cart-count {
                            width: 17px;
                            height: 17px;
                            right: -11px;
                            top: -4px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    body {
        &.homepage {
            .main-header {
                &#header-top {
                    &.top {
                        top: 0;
                    }
                }
            }
        }
    }
    .main-header {
        top: 0px;
        width: 100%;
        left: 0;
        box-sizing: border-box;
        z-index: 4;
        height: 63px;

        .container-fluid {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            .main-header__logo {
                display: block;
                height: 100px;
                img {
                    display: block;
                    height: 60px;
                    margin: 20px 20px 0 20px;
                }
            }
            .menu {
                right: 0;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    top: 0;
                }
                @supports (-ms-ime-align:auto) {
                    top: 0;
                }
                .navigation-bar {
                    background: $secondary-color;
                    position: absolute;
                    right: -20px;
                    top: 0;
                    width: 400px;
                    height: 100vh;
                    transform:translateX(100%);
                    transition: all 250ms ease;
                    border-radius: 40px;
                    z-index: 2;
                    &.is-active {
                        transform:translateX(0);
                    }
                    .nav-mobile-logo {
                        display: block;
                        position: absolute;
                        bottom: 30px;
                        right: 30px;
                        height: 150px;
                    }
                    .header-nav-list {
                        padding-top: 15vh;
                        padding-left: 50px;
                        flex-direction: column;
                        .nav-item {
                            font-size: 28px;
                            font-family: $font-semibold;
                            position: relative;
                            display: flex;
                            align-items: baseline;
                            padding-top: 15px;
                            &:hover {
                                a {
                                    color: $secondary-color;
                                }
                            }
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: -35px;
                                top: 24px;
                                height: 30px;
                                width: 0;
                                transition: width 0s ease, background .7s ease;
                                z-index: 2;
                            }
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                left: 35px;
                                bottom: 15px;
                                height: 30px;
                                width: 0;
                                background: white;
                                transition: width .4s ease;
                                z-index: 2;
                            }
                            &:hover:before {
                                width: 100%;
                                background: white;
                                transition: width .7s ease;
                            }
                            &:hover:after {
                                width: 100%;
                                background: transparent;
                                transition: all 0s ease;
                            }
                            &.active {
                                &:before {
                                    width: 100%;
                                    background: white;
                                    transition: width .7s ease;
                                }
                                a {
                                    color: $secondary-color;
                                }
                            }
                            .nav-item-container{ 
                                align-items: baseline;
                            span {
                                display: block;
                                color: white;
                                font-size: 20px;
                                font-family: $font-regular;
                                font-style: initial;
                                opacity: 0.5;
                            }
                            a {
                                text-transform: initial;
                                transition: all ease 0.6s;
                                position: relative;
                                z-index: 3;
                                padding-left: 10px;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                            .menu_level_1{
                                padding-left: 40px;
                                li{
                                    background-color: transparent;
                                    padding: 20px 10px 20px 0px;
                                    
                                    &:hover{
                                        background-color: transparent;
                                    }
    
                                    &:after{
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        width: 100%;
                                    }
                                    &:last-child{
                                        padding-bottom: 20px;
                                        &:after{ 
                                        display: none;
                                        }
                                    }
                                    &:first-child{
                                        &:before{
                                            // width: 100%; 
                                        }
                                    }
                                    span{ 
                                        text-align: left;
                                        font-family: $font-medium;
                                    }
                                }
                                
                            }
                        }
                    }
                }
                .cart-icon {
                    display: none;
                }
                .burger {
                    display: block;
                    height: 100%;
                    background-color: $secondary-color;
                    width: 80px;
                    z-index: 3;
                    cursor: pointer;
                    transition: all ease 0.2s;
                    transform-origin: center;
                    img {
                        height: 16px;
                        padding-top: 24px;
                        padding-left: 23px;
                    }
                    &.rotate {

                        transform: rotate(-90deg);

                    }
                }
                .account {
                    margin-right: 15px;
                    img {
                        height: 26px;
                    }
                }
            }
            .infos-block {
                width: auto;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                .phone-block, .address-block {
                    margin-right: 5px;
                    margin-bottom: 5px;
                    p {
                        margin-block-start: 0;
                        margin-block-end: 0;
                        min-width: 0;
                        text-align: right;
                    }
                }
                .address-block {
                    img {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .main-header {
        .container-fluid {
            .main-header__logo {
                img {
                    margin-left: 2px;
                }
            }
            .menu {
                .navigation-bar {
                    width: 280px;
                    .header-nav-list {
                        padding-top: 110px;
                        padding-left: 30px;
                        .nav-item {
                            font-size: 22px;
                            span {
                                font-size: 18px;
                            }
                        }
                    }
                    .nav-mobile-logo {
                        height: 110px;
                    }
                }
            }
            .infos-block {
                p {
                    font-size: 12px;
                }
                .address-block, .phone-block {
                    img {
                        height: 10px;
                    }
                }
                .address-block {
                    align-items: initial;
                    img {
                        margin-top: 3px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    .main-header .container-fluid .infos-block .address-block p {
        max-width: 143px;
    }
}
