@charset "UTF-8";
/**
 * Private Mascarille
 *
 * @author Les Vikings <contact@les-vikings.fr>
 * @since 2019-06-18
 */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/**
 * @name Mascarille
 * @author Les Vikings
 */
/* BREAKPOINTS */
/* COLORS */
/* FONTS */
/* VARS */
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  src: url("../../../assets/fonts/montserrat/montserrat-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-regular.woff2") format("woff2"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-regular.woff") format("woff"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-regular.ttf") format("truetype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 500;
  src: url("../../../assets/fonts/montserrat/montserrat-v13-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500.woff2") format("woff2"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500.woff") format("woff"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500.ttf") format("truetype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat Medium Italic';
  font-style: italic;
  font-weight: 500;
  src: url("../../../assets/fonts/montserrat/montserrat-v13-latin-500italic.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500italic.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500italic.woff2") format("woff2"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500italic.woff") format("woff"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500italic.ttf") format("truetype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-500italic.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat Semibold';
  font-style: normal;
  font-weight: 600;
  src: url("../../../assets/fonts/montserrat/montserrat-v13-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-600.woff2") format("woff2"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-600.woff") format("woff"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-600.ttf") format("truetype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-600.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 700;
  src: url("../../../assets/fonts/montserrat/montserrat-v13-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-700.woff2") format("woff2"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-700.woff") format("woff"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-700.ttf") format("truetype"), url("../../../assets/fonts/montserrat/montserrat-v13-latin-700.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

body {
  margin: 0; }
  body.homepage {
    overflow-x: scroll;
    overflow-y: hidden !important;
    margin: 0;
    width: max-content;
    position: relative;
    display: flex;
    align-items: flex-start; }
    body.homepage.form-validate .popin-overlay, body.homepage.form-validate .popins {
      display: none; }
    body.homepage #cookie-bar {
      display: none; }
    body.homepage main {
      display: block !important; }
      body.homepage main .container {
        display: flex;
        justify-content: space-between; }
        @supports (-ms-ime-align: auto) {
          body.homepage main .container {
            display: -ms-flexbox !important;
            flex-direction: row; } }
    body.homepage .homepage-intro-section-pp {
      width: 80vw;
      height: 100vh;
      overflow-y: hidden;
      flex: 1 0 auto; }
    body.homepage .homepage-slider-section-pp {
      width: 90vw;
      flex: 1 0 auto; }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        body.homepage .homepage-slider-section-pp {
          width: 1600px; } }
    body.homepage .homepage-footer-section-pp {
      flex: 1 0 auto;
      width: 30vw; }
    body.homepage #_progress {
      --scroll: 0%;
      background: linear-gradient(to right, #8b0000 var(--scroll), #f2f2f2 0);
      position: fixed;
      width: 100%;
      height: 10px;
      bottom: 0;
      z-index: 4; }
    body.homepage .popin-overlay {
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.5;
      position: absolute;
      top: 0;
      z-index: 6;
      display: block; }
      body.homepage .popin-overlay.hide-overlay {
        display: none; }
    body.homepage .scroll {
      display: block;
      transition: all ease 0.6s; }
      body.homepage .scroll.not-visible {
        display: none; }
      body.homepage .scroll img {
        position: fixed;
        height: 90px;
        right: 120px;
        bottom: 30px;
        animation: rotating 15s linear infinite; }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
  body h1 {
    font-family: "Montserrat Semibold";
    text-transform: uppercase;
    font-size: 30px; }
  body h2 {
    font-family: "Montserrat Medium";
    font-size: 20px;
    font-weight: 400; }
  body h3 {
    font-family: "Montserrat Semibold";
    font-size: 18px; }
  body p {
    font-family: "Montserrat Regular";
    font-size: 13px; }
  body address {
    font-family: "Montserrat Regular";
    font-size: 13px;
    font-style: normal;
    margin-block-start: 1em;
    margin-block-end: 1em; }
  body .booking-button {
    display: flex;
    align-items: center;
    position: relative;
    color: #404040;
    text-decoration: none;
    padding-left: 14px;
    padding-right: 15px;
    margin-top: 30px;
    transition: all ease 0.3s;
    width: fit-content; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      body .booking-button {
        width: 220px; } }
    @supports (-ms-ime-align: auto) {
      body .booking-button {
        width: 220px; } }
    body .booking-button:before {
      content: "";
      display: block;
      height: 35px;
      width: 35px;
      border-radius: 20px;
      background-color: #8b0000;
      position: absolute;
      left: 0;
      z-index: -1;
      transition: all ease 0.5s; }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        body .booking-button:before {
          top: 5px !important; } }
    body .booking-button .icon {
      transition: all ease 0.5s;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      background: white; }
      body .booking-button .icon.arrow {
        transition: all ease 0.5s;
        left: 0.325rem;
        width: 1.125rem;
        height: 0.12rem;
        background: none; }
        body .booking-button .icon.arrow::before {
          position: absolute;
          content: '';
          top: -0.27rem;
          right: 0.0625rem;
          width: 0.625rem;
          height: 0.625rem;
          border-top: 0.125rem solid #fff;
          border-right: 0.125rem solid #fff;
          transform: rotate(45deg); }
    body .booking-button p {
      padding-left: 40px;
      font-size: 14px;
      width: 100% !important;
      transition: all ease 0.2s; }
    body .booking-button:hover {
      color: white; }
      body .booking-button:hover:before {
        width: 100%; }
      body .booking-button:hover .icon.arrow {
        background: white;
        transform: translate(1rem, 0); }
      body .booking-button:hover p {
        color: white !important; }

@keyframes desplode {
  0% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: rgba(20, 180, 87, 0.8); }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(129, 80, 108, 0.6); } }

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(42, 53, 80, 0.2); }
  100% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: #6b0101; } }
  body .button-animation-container {
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin-top: 15px; }
    body .button-animation-container .button-circle {
      background-color: #8b0000;
      border-radius: 1000px;
      position: absolute;
      left: 0;
      top: 0;
      width: 0px;
      height: 0px;
      margin-left: 0px;
      margin-top: 0px;
      pointer-events: none; }
    body .button-animation-container .regular-button {
      display: inline-block;
      color: white;
      font-size: 16px;
      font-weight: normal;
      width: auto;
      text-align: center;
      transition: 400ms;
      text-decoration: none;
      padding: 10px 20px;
      border: none;
      cursor: pointer; }
      body .button-animation-container .regular-button.action {
        background-color: #8b0000; }
      body .button-animation-container .regular-button.dark {
        background-color: #404040; }
      body .button-animation-container .regular-button.light {
        background-color: #b9b9b9;
        font-size: 14px; }
      body .button-animation-container .regular-button:focus {
        outline: none; }
    body .button-animation-container .button-text {
      position: relative;
      font-style: inherit;
      text-transform: uppercase;
      font-size: 15px;
      font-family: "Montserrat Medium"; }
    body .button-animation-container .explode-circle {
      animation: explode 0.5s forwards; }
      body .button-animation-container .explode-circle.dark {
        animation: explodedark 0.5s forwards; }
    body .button-animation-container .desplode-circle {
      animation: desplode 0.5s forwards; }
      body .button-animation-container .desplode-circle.dark {
        animation: desplodedark 0.5s forwards; }

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: #a80101; }
  100% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: #6b0101; } }

@keyframes desplode {
  0% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: #6b0101; }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: #a80101; } }

@keyframes explodedark {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: #5b5b5b; }
  100% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: #343434; } }

@keyframes desplodedark {
  0% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: #343434; }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: #5b5b5b; } }
  body .custom-control {
    display: inline-block; }
    body .custom-control.fill-checkbox svg {
      fill: none;
      vertical-align: middle; }
      body .custom-control.fill-checkbox svg circle {
        stroke-width: 2;
        stroke: white;
        opacity: 0.4; }
      body .custom-control.fill-checkbox svg path {
        stroke: white; }
        body .custom-control.fill-checkbox svg path.inner {
          stroke-width: 6;
          stroke-dasharray: 19;
          stroke-dashoffset: 19; }
        body .custom-control.fill-checkbox svg path.outer {
          stroke-width: 2;
          stroke-dasharray: 57;
          stroke-dashoffset: 57; }
    body .custom-control.fill-checkbox .radio-date {
      display: none; }
      body .custom-control.fill-checkbox .radio-date:checked + svg path {
        transition: all .4s ease; }
        body .custom-control.fill-checkbox .radio-date:checked + svg path.inner {
          stroke-dashoffset: 38;
          transition-delay: .3s; }
        body .custom-control.fill-checkbox .radio-date:checked + svg path.outer {
          stroke-dashoffset: 0; }
    body .custom-control.fill-checkbox.white-radio svg circle {
      stroke: white; }
    body .custom-control.fill-checkbox.white-radio svg path {
      stroke: white; }
    body .custom-control.fill-checkbox.red-radio svg circle {
      stroke: #8b0000; }
    body .custom-control.fill-checkbox.red-radio svg path {
      stroke: #8b0000; }
  body span {
    font-family: "Montserrat Medium Italic";
    font-size: 11px; }
    @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
      body span {
        font-family: "Montserrat Regular";
        font-style: italic; } }
  body .scroll {
    display: none; }
  body input[type="radio"] {
    display: none; }
  body input[type="radio"] + label:before {
    content: '';
    background: #fff;
    border: 3px solid #8b0000;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    padding: 2px;
    margin-right: 10px;
    text-align: center; }
  body input[type="radio"]:checked + label:before {
    content: "";
    background: #8b0000;
    border: 3px solid #8b0000; }
  body label {
    font-family: "Montserrat Regular";
    font-size: 14px; }
  body .field.checkbox input {
    width: 6px;
    opacity: 0;
    position: absolute;
    margin-top: 0; }
  body .field.checkbox label {
    width: 95%;
    margin-top: 15px;
    color: #404040;
    font-family: "Montserrat Regular";
    font-size: 13px; }
    body .field.checkbox label a {
      color: #8b0000;
      text-decoration: none; }
  body .field.checkbox input + label:before {
    content: '';
    border: 3px solid #8b0000;
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    padding: 2px;
    margin-right: 5px;
    text-align: center; }
  body .field.checkbox input:checked + label:before {
    content: "";
    border: 3px solid #8b0000;
    background: #8b0000; }

@media screen and (max-width: 1440px) {
  body.homepage .homepage-intro-section-pp, body.homepage .homepage-slider-section-pp {
    width: 90vw; } }

@media screen and (max-width: 1440px) and (-ms-high-contrast: active), screen and (max-width: 1440px) and (-ms-high-contrast: none) {
  body.homepage .homepage-slider-section-pp {
    width: 1400px; } }

@media screen and (max-width: 1440px) {
  body.homepage .homepage-footer-section-pp {
    width: 30vw; }
  body.homepage .scroll img {
    height: 75px; } }

@media screen and (max-width: 1280px) {
  body.homepage .homepage-intro-section-pp {
    width: 100vw; }
  body.homepage .homepage-slider-section-pp {
    width: 1200px;
    max-width: 100%; } }
  @media screen and (max-width: 1280px) and (-ms-high-contrast: active), screen and (max-width: 1280px) and (-ms-high-contrast: none) {
    body.homepage .homepage-slider-section-pp {
      width: 1280px; } }

@media screen and (max-width: 1280px) {
  body.homepage .homepage-footer-section-pp {
    width: 35vw; }
  body h1 {
    font-size: 25px; }
  body h2 {
    font-size: 20px !important; }
  body h3 {
    font-size: 15px !important; } }

@media screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  body.homepage {
    overflow-x: scroll;
    overflow-y: hidden !important;
    margin: 0;
    width: max-content;
    position: relative; } }

@media screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  body.homepage {
    overflow-x: hidden;
    overflow-y: scroll !important; } }

@media screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
    overflow-y: scroll !important;
    position: relative; }
    body.homepage {
      overflow-x: hidden;
      overflow-y: scroll !important;
      width: 100%;
      flex-direction: column;
      position: relative; }
      body.homepage main {
        position: relative;
        width: 100%; }
        body.homepage main .container {
          flex-direction: column; }
      body.homepage .homepage-slider-section-pp {
        height: 100%;
        max-width: initial;
        width: 100vw; } }
      @media screen and (max-width: 1024px) and (-ms-high-contrast: active), screen and (max-width: 1024px) and (-ms-high-contrast: none) {
        body.homepage .homepage-slider-section-pp {
          width: 100%; } }

@media screen and (max-width: 1024px) {
      body.homepage .homepage-footer-section-pp {
        width: 100%; }
      body.homepage #_progress {
        display: none; }
      body.homepage .homepage-intro-section-pp {
        height: 100vh;
        overflow: hidden;
        width: 100%; }
      body.homepage .scroll {
        display: none; }
    body h1 {
      font-size: 24px; }
    body h2 {
      font-size: 16px !important; }
    body h3 {
      font-size: 15px !important; }
    body .regular-button {
      font-size: 13px; } }

@media screen and (max-width: 900px) {
  body .homepage-intro-section-pp {
    height: 100%; }
    body .homepage-intro-section-pp .block-content .float-text .bottom-content a {
      margin: 0 auto; }
  body .homepage-footer-section-pp {
    width: 100vw; }
  body h1 {
    font-size: 23px; }
  body h2 {
    font-size: 15px !important; }
  body h3 {
    font-size: 14px !important; } }

body.homepage .main-header#header-top.top {
  top: 25px; }

.main-header {
  display: flex;
  height: 77px;
  position: fixed;
  width: 97vw;
  left: 3vw;
  top: 25px;
  background-color: #404040;
  z-index: 3; }
  .main-header#header-top {
    transition: 0.7s; }
    .scrolled .main-header#header-top {
      top: 0; }
  .main-header .container-fluid {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center; }
    .main-header .container-fluid .main-header__logo {
      display: contents; }
      .main-header .container-fluid .main-header__logo img {
        height: 70px;
        margin: 10px 20px; }
    .main-header .container-fluid .menu {
      font-family: "Montserrat Regular";
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      right: 15px; }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .main-header .container-fluid .menu {
          top: -16px; } }
      @supports (-ms-ime-align: auto) {
        .main-header .container-fluid .menu {
          top: -16px; } }
      .main-header .container-fluid .menu .navigation-bar {
        height: 100%;
        position: relative; }
        .main-header .container-fluid .menu .navigation-bar .nav-mobile-logo {
          display: none; }
        .main-header .container-fluid .menu .navigation-bar .header-nav-list {
          display: flex;
          margin-block-end: 0;
          margin-block-start: 0;
          height: 100%;
          list-style: none;
          margin-right: 10px; }
          .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item {
            margin: 0 5px;
            position: relative;
            flex-direction: column; }
            .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container {
              display: flex;
              align-items: center;
              height: 100%; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container span {
                display: none; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container a {
                color: white;
                text-transform: uppercase;
                text-decoration: none;
                padding: 15px 20px;
                position: relative; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container a:after {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 20px;
                  bottom: 10px;
                  width: 0;
                  height: 2px;
                  background-color: white;
                  transition: all ease 0.5s; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container a:hover:after {
                  width: 50%;
                  height: auto; }
            .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item:hover .menu_level_1 {
              top: 77px;
              overflow: visible;
              display: block; }
            .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 {
              padding: 0px;
              display: none;
              width: 100%; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li {
                list-style-type: none;
                background-color: #404040;
                margin: 0px;
                padding: 20px 10px 0px 10px;
                transition: background-color 0.3s ease-out; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:hover {
                  background-color: #f2f2f2; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:hover span {
                    color: #404040; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:after {
                  content: '';
                  height: 1px;
                  width: 70%;
                  background-color: #f2f2f2;
                  display: block;
                  margin: 0 auto;
                  margin-top: 20px; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:last-child {
                  padding-bottom: 20px; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:last-child:after {
                    display: none; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:first-child {
                  position: relative; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:first-child:before {
                    content: '';
                    display: block;
                    height: 2px;
                    width: 100%;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    left: 0; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li span {
                  display: block;
                  color: #f2f2f2;
                  font-style: inherit;
                  font-size: 13px;
                  text-transform: uppercase;
                  text-align: center; }
      .main-header .container-fluid .menu .cart-icon {
        background-color: #8b0000;
        color: white;
        font-family: "Montserrat Regular";
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 30px; }
        .main-header .container-fluid .menu .cart-icon #sylius-cart-button {
          display: flex;
          align-items: center;
          position: relative; }
          .main-header .container-fluid .menu .cart-icon #sylius-cart-button p {
            font-size: 17px; }
          .main-header .container-fluid .menu .cart-icon #sylius-cart-button .cart-count {
            color: #8b0000;
            font-family: "Montserrat Semibold";
            position: relative;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            background-color: white;
            position: absolute;
            right: -22px;
            top: 5px;
            width: 20px;
            height: 20px;
            border-radius: 15px; }
      .main-header .container-fluid .menu .account, .main-header .container-fluid .menu .cart-icon {
        margin-right: 15px; }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          .main-header .container-fluid .menu .account, .main-header .container-fluid .menu .cart-icon {
            padding-top: 35px; } }
        .main-header .container-fluid .menu .account img, .main-header .container-fluid .menu .cart-icon img {
          height: 30px; }
      .main-header .container-fluid .menu .burger {
        display: none; }

@media screen and (max-width: 1440px) {
  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item a {
    font-size: 14px; }
  .main-header .container-fluid .menu .cart-icon #sylius-cart-button p {
    font-size: 14px; } }

@media screen and (max-width: 1280px) {
  .main-header {
    top: 15px; }
    .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item {
      font-size: 15px; }
      .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item a {
        padding: 15px; }
    .main-header .container-fluid .menu .cart-icon {
      padding: 0 15px; }
      .main-header .container-fluid .menu .cart-icon #sylius-cart-button p {
        font-size: 15px; }
      .main-header .container-fluid .menu .cart-icon #sylius-cart-button .cart-count {
        width: 17px;
        height: 17px;
        right: -11px;
        top: -4px; } }

@media screen and (max-width: 1024px) {
  body.homepage .main-header#header-top.top {
    top: 0; }
  .main-header {
    top: 0px;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    z-index: 4;
    height: 63px; }
    .main-header .container-fluid {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between; }
      .main-header .container-fluid .main-header__logo {
        display: block;
        height: 100px; }
        .main-header .container-fluid .main-header__logo img {
          display: block;
          height: 60px;
          margin: 20px 20px 0 20px; }
      .main-header .container-fluid .menu {
        right: 0; } }
      @media screen and (max-width: 1024px) and (-ms-high-contrast: active), screen and (max-width: 1024px) and (-ms-high-contrast: none) {
        .main-header .container-fluid .menu {
          top: 0; } }

@media screen and (max-width: 1024px) {
        @supports (-ms-ime-align: auto) {
          .main-header .container-fluid .menu {
            top: 0; } }
        .main-header .container-fluid .menu .navigation-bar {
          background: #8b0000;
          position: absolute;
          right: -20px;
          top: 0;
          width: 400px;
          height: 100vh;
          transform: translateX(100%);
          transition: all 250ms ease;
          border-radius: 40px;
          z-index: 2; }
          .main-header .container-fluid .menu .navigation-bar.is-active {
            transform: translateX(0); }
          .main-header .container-fluid .menu .navigation-bar .nav-mobile-logo {
            display: block;
            position: absolute;
            bottom: 30px;
            right: 30px;
            height: 150px; }
          .main-header .container-fluid .menu .navigation-bar .header-nav-list {
            padding-top: 15vh;
            padding-left: 50px;
            flex-direction: column; }
            .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item {
              font-size: 28px;
              font-family: "Montserrat Semibold";
              position: relative;
              display: flex;
              align-items: baseline;
              padding-top: 15px; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item:hover a {
                color: #8b0000; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item:before {
                content: '';
                display: block;
                position: absolute;
                right: -35px;
                top: 24px;
                height: 30px;
                width: 0;
                transition: width 0s ease, background .7s ease;
                z-index: 2; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item:after {
                content: '';
                display: block;
                position: absolute;
                left: 35px;
                bottom: 15px;
                height: 30px;
                width: 0;
                background: white;
                transition: width .4s ease;
                z-index: 2; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item:hover:before {
                width: 100%;
                background: white;
                transition: width .7s ease; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item:hover:after {
                width: 100%;
                background: transparent;
                transition: all 0s ease; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item.active:before {
                width: 100%;
                background: white;
                transition: width .7s ease; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item.active a {
                color: #8b0000; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container {
                align-items: baseline; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container span {
                  display: block;
                  color: white;
                  font-size: 20px;
                  font-family: "Montserrat Regular";
                  font-style: initial;
                  opacity: 0.5; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container a {
                  text-transform: initial;
                  transition: all ease 0.6s;
                  position: relative;
                  z-index: 3;
                  padding-left: 10px; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .nav-item-container a:after {
                    display: none; }
              .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 {
                padding-left: 40px; }
                .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li {
                  background-color: transparent;
                  padding: 20px 10px 20px 0px; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:hover {
                    background-color: transparent; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:last-child {
                    padding-bottom: 20px; }
                    .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li:last-child:after {
                      display: none; }
                  .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item .menu_level_1 li span {
                    text-align: left;
                    font-family: "Montserrat Medium"; }
        .main-header .container-fluid .menu .cart-icon {
          display: none; }
        .main-header .container-fluid .menu .burger {
          display: block;
          height: 100%;
          background-color: #8b0000;
          width: 80px;
          z-index: 3;
          cursor: pointer;
          transition: all ease 0.2s;
          transform-origin: center; }
          .main-header .container-fluid .menu .burger img {
            height: 16px;
            padding-top: 24px;
            padding-left: 23px; }
          .main-header .container-fluid .menu .burger.rotate {
            transform: rotate(-90deg); }
        .main-header .container-fluid .menu .account {
          margin-right: 15px; }
          .main-header .container-fluid .menu .account img {
            height: 26px; }
      .main-header .container-fluid .infos-block {
        width: auto;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end; }
        .main-header .container-fluid .infos-block .phone-block, .main-header .container-fluid .infos-block .address-block {
          margin-right: 5px;
          margin-bottom: 5px; }
          .main-header .container-fluid .infos-block .phone-block p, .main-header .container-fluid .infos-block .address-block p {
            margin-block-start: 0;
            margin-block-end: 0;
            min-width: 0;
            text-align: right; }
        .main-header .container-fluid .infos-block .address-block img {
          margin-right: 5px; } }

@media screen and (max-width: 640px) {
  .main-header .container-fluid .main-header__logo img {
    margin-left: 2px; }
  .main-header .container-fluid .menu .navigation-bar {
    width: 280px; }
    .main-header .container-fluid .menu .navigation-bar .header-nav-list {
      padding-top: 110px;
      padding-left: 30px; }
      .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item {
        font-size: 22px; }
        .main-header .container-fluid .menu .navigation-bar .header-nav-list .nav-item span {
          font-size: 18px; }
    .main-header .container-fluid .menu .navigation-bar .nav-mobile-logo {
      height: 110px; }
  .main-header .container-fluid .infos-block p {
    font-size: 12px; }
  .main-header .container-fluid .infos-block .address-block img, .main-header .container-fluid .infos-block .phone-block img {
    height: 10px; }
  .main-header .container-fluid .infos-block .address-block {
    align-items: initial; }
    .main-header .container-fluid .infos-block .address-block img {
      margin-top: 3px; } }

@media screen and (max-width: 420px) {
  .main-header .container-fluid .infos-block .address-block p {
    max-width: 143px; } }

body.homepage footer {
  width: 31vw;
  z-index: 1;
  height: 100vh;
  padding-top: 100px; }
  body.homepage footer .container {
    display: block;
    height: 84%; }
    body.homepage footer .container #map {
      height: 70%;
      width: 100%; }
    body.homepage footer .container .menu-sitemap {
      height: 30%;
      width: 100%; }
  body.homepage footer .sub-footer {
    height: 6%; }

footer {
  color: #404040;
  height: 240px; }
  footer .container {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative; }
    footer .container #map {
      height: 100%;
      width: 40%; }
    footer .container .menu-sitemap {
      background-color: #f2f2f2;
      height: 100%;
      width: 60%; }
      footer .container .menu-sitemap .menu-container {
        width: 85%;
        margin: 0 auto;
        padding-top: 50px; }
        footer .container .menu-sitemap .menu-container h2 {
          margin-block-end: 0;
          margin-block-start: 0; }
        footer .container .menu-sitemap .menu-container nav {
          font-family: "Montserrat Regular"; }
          footer .container .menu-sitemap .menu-container nav .header-nav-list {
            list-style: none;
            padding-left: 0;
            height: 90px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap; }
            footer .container .menu-sitemap .menu-container nav .header-nav-list .nav-item {
              margin-bottom: 5px;
              width: 50%; }
              footer .container .menu-sitemap .menu-container nav .header-nav-list .nav-item span {
                display: none; }
              footer .container .menu-sitemap .menu-container nav .header-nav-list .nav-item a {
                color: #404040;
                font-size: 13px; }
  footer .sub-footer {
    background-color: #8b0000;
    width: 100%; }
    footer .sub-footer .container-fluid .sub-footer__inner {
      display: flex;
      justify-content: center; }
      footer .sub-footer .container-fluid .sub-footer__inner p {
        color: white; }
        footer .sub-footer .container-fluid .sub-footer__inner p a {
          text-decoration: none;
          color: white; }
      footer .sub-footer .container-fluid .sub-footer__inner .footer-copyright {
        display: flex;
        justify-content: center; }
  footer #footer-mobile {
    display: none; }

@media screen and (max-width: 1536px) {
  body.homepage footer .container {
    height: 80%; }
    body.homepage footer .container .menu-container {
      padding-top: 30px; } }

@media screen and (max-width: 1440px) {
  body.homepage footer {
    width: 41vw; }
    body.homepage footer #map {
      height: 35%; }
    body.homepage footer .container {
      height: 84%; }
      body.homepage footer .container .menu-container {
        padding-top: 50px; }
  body footer {
    width: 100%; } }

@media screen and (max-width: 1366px) {
  body.homepage footer {
    width: 35vw; }
    body.homepage footer .container {
      height: 77%; }
      body.homepage footer .container .menu-container {
        padding-top: 20px; } }

@media screen and (max-width: 1280px) {
  body.homepage footer {
    padding-top: 90px; }
    body.homepage footer .container {
      height: 80%; }
    body.homepage footer .sub-footer {
      height: 7%; }
  body footer {
    width: 100%; } }

@media screen and (max-width: 1024px) {
  body.homepage footer {
    width: 100%;
    height: 100%; }
    body.homepage footer .container {
      height: 100%; }
      body.homepage footer .container #map {
        height: 300px; }
      body.homepage footer .container .menu-sitemap {
        height: auto; }
      body.homepage footer .container .menu-container {
        padding-top: 30px; }
  body footer #footer-mobile {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: white; }
    body footer #footer-mobile .container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative; }
      body footer #footer-mobile .container .phone-block, body footer #footer-mobile .container .mail-block {
        display: flex;
        justify-content: center;
        width: 30%;
        align-items: center; }
        body footer #footer-mobile .container .phone-block img, body footer #footer-mobile .container .mail-block img {
          height: 25px;
          margin-top: 10px; }
      body footer #footer-mobile .container .booking-block {
        width: 40%;
        height: 100%;
        background-color: #8b0000;
        display: flex;
        justify-content: center;
        align-items: center; }
        body footer #footer-mobile .container .booking-block a {
          color: white;
          text-transform: uppercase;
          display: flex;
          font-family: "Montserrat Regular";
          text-decoration: none; }
          body footer #footer-mobile .container .booking-block a #sylius-cart-button {
            display: flex;
            align-items: center;
            position: relative; }
            body footer #footer-mobile .container .booking-block a #sylius-cart-button p {
              font-size: 15px; }
            body footer #footer-mobile .container .booking-block a #sylius-cart-button .cart-count {
              color: #8b0000;
              font-family: "Montserrat Semibold";
              position: relative;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              background-color: white;
              position: absolute;
              right: -22px;
              top: 5px;
              width: 20px;
              height: 20px;
              border-radius: 15px; }
  body footer .sub-footer {
    padding-bottom: 50px; }
    body footer .sub-footer .container-fluid .sub-footer__inner {
      padding: 10px 0; }
      body footer .sub-footer .container-fluid .sub-footer__inner p {
        margin: 0; } }

@media screen and (max-width: 768px) {
  body.show.event footer #footer-mobile {
    display: none; }
  body footer {
    height: auto; }
    body footer .container {
      flex-direction: column; }
      body footer .container #map {
        height: 300px;
        width: 100%; }
      body footer .container .menu-sitemap {
        width: 100%; } }

@media screen and (max-width: 640px) {
  body footer #footer-mobile .container .booking-block {
    width: 50%; }
    body footer #footer-mobile .container .booking-block a #sylius-cart-button p {
      font-size: 13px; }
  body footer #footer-mobile .container .mail-block, body footer #footer-mobile .container .phone-block {
    width: 25%; }
  footer .container .menu-sitemap {
    padding-bottom: 10px; }
    footer .container .menu-sitemap .menu-container {
      width: 95%; }
  footer .sub-footer .container-fluid .sub-footer__inner {
    flex-direction: column;
    text-align: center; } }

@media screen and (max-width: 420px) {
  footer .container .menu-sitemap .menu-container h2 {
    text-align: center; }
  footer .container .menu-sitemap .menu-container nav .header-nav-list {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto; }
    footer .container .menu-sitemap .menu-container nav .header-nav-list .nav-item {
      text-align: center;
      width: 100%; } }

.contact-page .flex {
  display: flex; }

.contact-page .shows-header {
  width: 100%;
  height: 350px;
  position: relative; }
  .contact-page .shows-header .image {
    width: 70%;
    overflow: hidden;
    position: relative;
    height: 100%; }
    .contact-page .shows-header .image img {
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .contact-page .shows-header .title {
    width: 30%;
    height: 100%;
    background-color: #8b0000; }
    .contact-page .shows-header .title h1 {
      text-transform: initial;
      color: white;
      text-align: center;
      margin-top: 30%; }

.contact-page .contact-body {
  width: 90%;
  margin: 0 auto;
  padding: 70px 0; }
  .contact-page .contact-body .left-container, .contact-page .contact-body .right-container {
    width: 50%;
    color: #404040; }
    .contact-page .contact-body .left-container .inside-container, .contact-page .contact-body .right-container .inside-container {
      width: 90%;
      margin: 0 auto; }
      .contact-page .contact-body .left-container .inside-container h2, .contact-page .contact-body .right-container .inside-container h2 {
        text-transform: uppercase;
        margin-block-end: 0; }
  .contact-page .contact-body .left-container .informations {
    margin-top: 15px; }
    .contact-page .contact-body .left-container .informations span {
      font-style: inherit;
      margin-block-end: 0;
      font-family: "Montserrat Semibold"; }
    .contact-page .contact-body .left-container .informations p {
      margin-block-start: 0; }
  .contact-page .contact-body .left-container .block-details-informations {
    margin-top: 35px; }
    .contact-page .contact-body .left-container .block-details-informations h3 {
      text-transform: uppercase;
      margin-block-end: 0; }
    .contact-page .contact-body .left-container .block-details-informations a {
      color: #404040;
      font-family: "Montserrat Regular";
      text-decoration: none; }
    .contact-page .contact-body .left-container .block-details-informations img {
      height: 17px; }
    .contact-page .contact-body .left-container .block-details-informations p, .contact-page .contact-body .left-container .block-details-informations address {
      margin-left: 10px; }
    .contact-page .contact-body .left-container .block-details-informations .flex {
      align-items: center; }
  .contact-page .contact-body .right-container {
    padding-left: 30px; }
    .contact-page .contact-body .right-container form {
      margin-top: 20px;
      width: 70%; }
      .contact-page .contact-body .right-container form input, .contact-page .contact-body .right-container form textarea {
        width: 100%;
        border: none;
        border-bottom: 3px solid #8b0000;
        margin-bottom: 15px;
        font-family: "Montserrat Regular"; }
        .contact-page .contact-body .right-container form input:focus, .contact-page .contact-body .right-container form textarea:focus {
          outline: none; }
      .contact-page .contact-body .right-container form textarea {
        position: relative;
        padding-top: 50px;
        line-height: 12px; }

@media screen and (max-width: 1280px) {
  .contact-page .shows-header .title h1 {
    margin-top: 50%; }
  .contact-page .contact-body .right-container form {
    width: 100%; } }

@media screen and (max-width: 1024px) {
  .contact-page .shows-header {
    flex-direction: column;
    margin-top: 63px;
    height: inherit; }
    .contact-page .shows-header .image {
      width: 100%;
      height: 250px; }
      .contact-page .shows-header .image img {
        width: 100%;
        min-height: 100%;
        height: auto; }
    .contact-page .shows-header .title {
      width: 100%;
      height: 100px; }
      .contact-page .shows-header .title h1 {
        margin-top: 30px; }
  .contact-page .contact-body.flex {
    flex-direction: column; }
  .contact-page .contact-body .left-container, .contact-page .contact-body .right-container {
    width: 100%; }
  .contact-page .contact-body .right-container {
    margin-top: 60px;
    padding-left: 0; } }

@media screen and (max-width: 768px) {
  .contact-page .shows-header .image img {
    height: 100%;
    min-width: 100%;
    width: auto; } }

@media screen and (max-width: 420px) {
  .contact-page .shows-header .image {
    height: 180px; } }

.popins {
  position: fixed;
  background-color: white;
  height: 475px;
  width: 615px;
  top: 30%;
  left: 32%;
  z-index: 7;
  display: block; }
  .popins.not-visible {
    display: none; }
  .popins .info-box-popin-pp {
    text-align: center;
    position: relative; }
    .popins .info-box-popin-pp .popin-close {
      position: absolute;
      right: 20px;
      top: 20px;
      height: 35px;
      z-index: 7;
      cursor: pointer; }
    .popins .info-box-popin-pp .popin-image-container {
      height: 200px;
      width: 100%;
      position: relative;
      overflow: hidden; }
      .popins .info-box-popin-pp .popin-image-container .header-image {
        height: auto;
        width: 100%;
        position: absolute;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .popins .info-box-popin-pp .popin-block-text {
      width: 90%;
      margin: 0 auto; }
      .popins .info-box-popin-pp .popin-block-text p {
        color: #404040; }
      .popins .info-box-popin-pp .popin-block-text button {
        cursor: pointer;
        background-color: #8b0000;
        color: white;
        text-transform: uppercase;
        border: none;
        padding: 10px 15px;
        font-family: "Montserrat Medium"; }

@media screen and (max-width: 1440px) {
  .popins {
    left: 28%;
    top: 19%; } }

@media screen and (max-width: 1280px) {
  .popins {
    left: 28%;
    height: 420px;
    width: 515px; } }

@media screen and (max-width: 900px) {
  .popins {
    height: 390px;
    width: 415px; }
    .popins .info-box-popin-pp .popin-image-container {
      height: 150px; } }

@media screen and (max-width: 768px) {
  .popins {
    left: 23%; } }

@media screen and (max-width: 640px) {
  .popins {
    left: 21%;
    height: 390px;
    width: 305px; }
    .popins .info-box-popin-pp .popin-close {
      height: 20px; }
    .popins .info-box-popin-pp .popin-image-container {
      height: 110px; } }

@media screen and (max-width: 420px) {
  .popins {
    left: 13%;
    width: 275px; } }

.homepage-intro-section-pp {
  display: flex; }
  .homepage-intro-section-pp .block-content {
    height: 87%;
    margin-top: 13%;
    width: 40vw;
    background-color: #404040;
    color: white;
    position: relative; }
    .homepage-intro-section-pp .block-content .float-text {
      position: absolute;
      margin-top: 15vh;
      left: 100px;
      width: 100%;
      z-index: 3; }
      .homepage-intro-section-pp .block-content .float-text .ticket-price {
        display: flex;
        align-items: center;
        margin: 0 auto; }
        .homepage-intro-section-pp .block-content .float-text .ticket-price img {
          height: 20px;
          margin-right: 10px; }
        .homepage-intro-section-pp .block-content .float-text .ticket-price p {
          margin-right: 10px; }
      .homepage-intro-section-pp .block-content .float-text .bottom-content {
        margin-bottom: 20px; }
      .homepage-intro-section-pp .block-content .float-text a {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
        width: fit-content; }
        .homepage-intro-section-pp .block-content .float-text a:focus {
          outline: none; }
        .homepage-intro-section-pp .block-content .float-text a p {
          font-family: "Montserrat Semibold"; }
        .homepage-intro-section-pp .block-content .float-text a img {
          height: 23px;
          margin-right: 10px; }
    .homepage-intro-section-pp .block-content .social-medias {
      position: absolute;
      bottom: 100px;
      left: 100px; }
      .homepage-intro-section-pp .block-content .social-medias img {
        height: 30px;
        margin-right: 10px; }
      .homepage-intro-section-pp .block-content .social-medias a {
        text-decoration: none; }
  .homepage-intro-section-pp .block-video {
    width: 60vw;
    height: 100%;
    position: relative;
    overflow: hidden; }
    .homepage-intro-section-pp .block-video .bg-vid {
      min-height: 100%;
      min-width: 110%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

@media screen and (max-width: 1440px) {
  .homepage-intro-section-pp .block-content {
    width: 35vw; }
  .homepage-intro-section-pp .block-video {
    width: 55vw; } }

@media screen and (max-width: 1280px) {
  .homepage-intro-section-pp .block-content {
    width: 40vw;
    height: 88%;
    margin-top: 12%; }
    .homepage-intro-section-pp .block-content .float-text {
      margin-top: 6vh; }
  .homepage-intro-section-pp .block-video {
    width: 60vw; } }

@media screen and (max-width: 900px) {
  .homepage-intro-section-pp {
    flex-direction: column;
    height: auto !important; }
    .homepage-intro-section-pp .block-content {
      width: 100%;
      margin-top: 125px;
      height: auto;
      padding: 45px 0 15px 0; }
      .homepage-intro-section-pp .block-content .float-text {
        position: relative;
        left: initial;
        width: 85%;
        margin: 0 auto;
        text-align: center;
        z-index: initial; } }
      @media screen and (max-width: 900px) and (-ms-high-contrast: active), screen and (max-width: 900px) and (-ms-high-contrast: none) {
        .homepage-intro-section-pp .block-content .float-text {
          left: 0; } }

@media screen and (max-width: 900px) {
        .homepage-intro-section-pp .block-content .float-text .ticket-price {
          justify-content: center; }
      .homepage-intro-section-pp .block-content .social-medias {
        position: relative;
        bottom: initial;
        left: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px; }
        .homepage-intro-section-pp .block-content .social-medias img {
          margin: 5px; }
    .homepage-intro-section-pp .block-video {
      height: 670px;
      width: 100%; } }

@media screen and (max-width: 640px) {
  .homepage-intro-section-pp .block-content .float-text .ticket-price {
    width: 320px; } }

@media screen and (max-width: 420px) {
  .homepage-intro-section-pp .block-content .float-text {
    width: 100%; }
  .homepage-intro-section-pp .block-video {
    height: 500px; }
    .homepage-intro-section-pp .block-video .bg-vid {
      max-width: 175%; } }

.homepage-slider-section-pp {
  background-color: white;
  position: relative; }
  .homepage-slider-section-pp .shows-container {
    width: 97%;
    margin-right: 100px; }
    .homepage-slider-section-pp .shows-container .flex-content {
      position: relative;
      width: 100%; }
      .homepage-slider-section-pp .shows-container .flex-content .slick-containers {
        position: relative;
        display: flex;
        width: 77%;
        margin: 0 auto;
        margin-top: 16vh;
        flex: 1 0 auto; }
      .homepage-slider-section-pp .shows-container .flex-content .description-slick {
        width: 100%; }
        .homepage-slider-section-pp .shows-container .flex-content .description-slick.single-slide .slick-list {
          width: 100%; }
        .homepage-slider-section-pp .shows-container .flex-content .description-slick.single-slide .slick-track {
          width: 100% !important; }
          .homepage-slider-section-pp .shows-container .flex-content .description-slick.single-slide .slick-track .slick-slide {
            width: 100% !important; }
        .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick {
          color: white;
          display: flex;
          height: 400px;
          margin-top: 35px;
          background-color: #8b0000; }
          .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .current-slide {
            display: none; }
          .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content {
            padding: 30px;
            margin-left: 30px;
            width: 45%; }
            .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content .guest {
              background-color: white;
              color: #8b0000;
              padding: 7px 10px;
              font-style: inherit;
              text-transform: uppercase; }
            .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content h3 {
              margin-block-end: 0;
              margin-bottom: 5px; }
            .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content .date {
              font-family: "Montserrat Medium Italic";
              font-size: 13px; }
              @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
                .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content .date {
                  font-family: "Montserrat Regular";
                  font-style: italic; } }
            .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content a {
              display: flex;
              align-items: center;
              color: white;
              text-decoration: none;
              max-width: 250px; }
              .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content a:focus {
                outline: none; }
              .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content a p {
                font-family: "Montserrat Semibold"; }
              .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content a img {
                height: 23px;
                margin-right: 10px; }
          .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image {
            width: 55%;
            position: relative; }
            .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image .container {
              height: 107%;
              margin-right: 20px;
              position: absolute;
              bottom: 0;
              overflow: hidden;
              width: 100%; }
              .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image .container img {
                height: 100%;
                width: auto;
                max-width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%); }
        .homepage-slider-section-pp .shows-container .flex-content .description-slick .slick-dots {
          text-align: end; }
          .homepage-slider-section-pp .shows-container .flex-content .description-slick .slick-dots li {
            margin: 0; }
            .homepage-slider-section-pp .shows-container .flex-content .description-slick .slick-dots li button:before {
              font-size: 35px;
              opacity: 1;
              color: #f2f2f2; }
          .homepage-slider-section-pp .shows-container .flex-content .description-slick .slick-dots .slick-active button:before {
            color: #8b0000; }
      .homepage-slider-section-pp .shows-container .flex-content .big-title {
        transform: rotate(-90deg);
        font-size: 75px;
        position: absolute;
        right: 0;
        top: 117px;
        right: -239px;
        color: #404040;
        font-family: "Montserrat Semibold"; }
    .homepage-slider-section-pp .shows-container .slide-number {
      margin-left: 11%;
      font-size: 110px;
      margin-block-start: 0;
      margin-block-end: 0;
      color: #d9d9d9;
      display: flex;
      align-items: center;
      position: relative;
      max-height: 85px;
      overflow: hidden; }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .homepage-slider-section-pp .shows-container .slide-number {
          max-height: 110px; } }
      .homepage-slider-section-pp .shows-container .slide-number p {
        font-size: 110px;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-top: 0; }
        @supports (-ms-ime-align: auto) {
          .homepage-slider-section-pp .shows-container .slide-number p {
            margin-top: 100px; } }
      .homepage-slider-section-pp .shows-container .slide-number .pagination-slick {
        width: 100%; }
  .homepage-slider-section-pp .red-block {
    height: 100%;
    width: 130px;
    position: absolute;
    right: 0;
    top: 25px;
    background-color: #8b0000; }
    @supports (-ms-ime-align: auto) {
      .homepage-slider-section-pp .red-block {
        top: 75px; } }

@media screen and (max-width: 1600px) {
  .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image .container {
    bottom: -25px; }
    .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image .container img {
      height: 88%; }
  .homepage-slider-section-pp .red-block {
    width: 100px; } }

@media screen and (max-width: 1440px) {
  .homepage-slider-section-pp .shows-container .flex-content .big-title {
    font-size: 60px;
    right: -196px; }
  .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick {
    height: 370px;
    margin-top: 25px; }
    .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image .container {
      bottom: -32px; }
      .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image .container img {
        min-height: 85%;
        max-width: 102%; }
    .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content {
      padding: 15px; }
  .homepage-slider-section-pp .red-block {
    width: 70px;
    top: 80px; } }

@media screen and (max-width: 1280px) {
  .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image {
    width: 60%; }
    .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .show-image .show-image .container img {
      max-height: 80%; }
  .homepage-slider-section-pp .shows-container .flex-content .description-slick .shows-slick .text-content {
    width: 40%; }
  .homepage-slider-section-pp .shows-container .slide-number {
    margin-left: 11%; } }

@media screen and (max-width: 1024px) {
  .homepage-slider-section-pp {
    padding-bottom: 100px; }
    .homepage-slider-section-pp .shows-container {
      margin-right: 0; }
      .homepage-slider-section-pp .shows-container .flex-content .slick-containers {
        flex-direction: column-reverse;
        width: 80%;
        margin: 0 auto; }
        .homepage-slider-section-pp .shows-container .flex-content .slick-containers .big-title {
          right: auto;
          position: relative;
          transform: none;
          font-size: 30px;
          text-align: center;
          text-transform: uppercase;
          margin-top: 60px;
          margin-block-end: 0;
          top: 0; }
        .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick {
          margin-top: 0; }
          .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick {
            flex-direction: column;
            height: 500px; }
            .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick .text-content {
              width: 90%;
              margin: 0 auto; }
            .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick .show-image {
              width: 100%;
              height: 100%; }
              .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick .show-image .container {
                height: 320px;
                width: 65%;
                right: 0;
                margin-right: 0;
                bottom: -31px; }
                .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick .show-image .container img {
                  max-width: 85%;
                  min-height: 82%; }
      .homepage-slider-section-pp .shows-container .slide-number {
        display: none; }
    .homepage-slider-section-pp .red-block {
      width: 100%;
      height: 70px;
      bottom: 0;
      top: inherit; } }

@media screen and (max-width: 768px) {
  .homepage-slider-section-pp .shows-container {
    width: 100%; }
    .homepage-slider-section-pp .shows-container .flex-content .slick-containers {
      width: 100%; } }

@media screen and (max-width: 640px) {
  .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick {
    width: 100%; }
    .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick {
      width: 100%;
      height: 720px; }
      .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick .show-image .container {
        width: 100%;
        height: 100%;
        margin-right: 0; }
        .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick .show-image .container img {
          min-height: 67%;
          width: 80%;
          top: 60%;
          height: auto; } }

@media screen and (max-width: 420px) {
  .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick {
    height: 705px; }
    .homepage-slider-section-pp .shows-container .flex-content .slick-containers .description-slick .shows-slick .show-image .container img {
      min-height: 85%;
      top: 50%;
      width: 100%; } }

body.homepage main {
  display: flex;
  align-items: center; }

.homepage-sliders {
  width: 75vw;
  height: 100vh;
  color: #404040; }
  .homepage-sliders .container {
    display: block !important;
    width: 80%;
    margin: 0 auto;
    position: relative;
    height: 85vh;
    margin-top: 18vh; }
    .homepage-sliders .container h2 {
      font-size: 25px; }
    .homepage-sliders .container .sliders-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative; }
      .homepage-sliders .container .sliders-container .content-slider {
        position: relative;
        width: 35%;
        margin-right: 70px; }
        .homepage-sliders .container .sliders-container .content-slider .slider {
          position: relative; }
          .homepage-sliders .container .sliders-container .content-slider .slider h3 {
            margin-block-start: 0; }
          .homepage-sliders .container .sliders-container .content-slider .slider .cards .slick-dots {
            text-align: center; }
            .homepage-sliders .container .sliders-container .content-slider .slider .cards .slick-dots li {
              margin: 0; }
              .homepage-sliders .container .sliders-container .content-slider .slider .cards .slick-dots li button:before {
                font-size: 35px;
                opacity: 1;
                color: #f2f2f2; }
            .homepage-sliders .container .sliders-container .content-slider .slider .cards .slick-dots .slick-active button:before {
              color: #8b0000; }
          .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card {
            height: auto; }
            .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .container-image {
              width: 100%;
              height: 32vh;
              overflow: hidden;
              position: relative; }
              .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .container-image img {
                width: 100%;
                display: block;
                margin: 0 auto 0 auto;
                height: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                min-height: 100%;
                transform: translate(-50%, -50%); }
            .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body {
              padding-top: 10px;
              text-align: center;
              min-height: 30vh;
              background-color: #d9d9d9; }
              .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body .header {
                margin-bottom: 25px;
                margin-top: 5vh; }
                .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body .header p {
                  margin-block-start: 0;
                  margin-block-end: 0;
                  font-family: "Montserrat Semibold";
                  text-transform: uppercase;
                  font-size: 20px; }
                .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body .header span {
                  font-style: inherit; }
              .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body p {
                width: 90%;
                margin: 0 auto; }
              .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body .button-animation-container {
                margin-top: 25px; }

@media screen and (max-width: 1440px) {
  .homepage-sliders {
    width: 100vw; } }

@media screen and (max-width: 1024px) {
  .homepage-sliders {
    width: 100%;
    margin-bottom: 25px;
    height: auto; }
    .homepage-sliders .container {
      width: 90%; }
      .homepage-sliders .container .sliders-container .right-slider {
        margin-right: 0; }
      .homepage-sliders .container .sliders-container .content-slider {
        width: 46%; }
        .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body {
          min-height: 250px; } }

@media screen and (max-width: 768px) {
  .homepage-sliders {
    margin-bottom: 70px; }
    .homepage-sliders .container {
      height: 100%; }
      .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .container-image {
        height: 225px; }
      .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body .header {
        height: 65px; }
        .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body .header p {
          font-size: 18px; }
      .homepage-sliders .container .sliders-container .content-slider .slider .cards .single-card .card-body p {
        font-size: 12px; } }

@media screen and (max-width: 640px) {
  .homepage-sliders {
    min-height: 1230px;
    margin-bottom: 0; }
    .homepage-sliders .container {
      margin-top: 0; }
      .homepage-sliders .container .sliders-container {
        flex-direction: column; }
        .homepage-sliders .container .sliders-container .content-slider {
          margin-right: 0;
          width: 100%;
          margin-top: 30px; } }

.homepage-plate-section {
  width: 80vw; }
  .homepage-plate-section .container {
    width: 85%;
    margin: 0 auto;
    height: 80vh;
    padding-top: 23vh; }
    .homepage-plate-section .container .left-container .image-container {
      height: 70vh;
      width: 380px;
      position: relative; }
      .homepage-plate-section .container .left-container .image-container .background-image {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden; }
        .homepage-plate-section .container .left-container .image-container .background-image .photo {
          height: 100%;
          min-width: 100%;
          position: absolute;
          min-height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .homepage-plate-section .container .left-container .image-container .icon {
        position: absolute;
        height: 100px;
        left: -50px;
        bottom: 40px; }
    .homepage-plate-section .container .right-container {
      width: 73%;
      margin: 0 auto;
      padding-top: 150px;
      padding-left: 30px;
      color: #404040;
      position: relative; }
      .homepage-plate-section .container .right-container .text-container {
        position: absolute;
        left: -29px;
        top: 6vh; }
        .homepage-plate-section .container .right-container .text-container .giant-title {
          font-size: 35px;
          font-family: "Montserrat Semibold";
          width: 100%; }
          .homepage-plate-section .container .right-container .text-container .giant-title span {
            color: #8b0000;
            font-size: 35px;
            font-style: inherit;
            font-family: "Montserrat Semibold"; }
        .homepage-plate-section .container .right-container .text-container .content {
          width: 65%;
          padding-left: 50px; }

@media screen and (max-width: 1280px) {
  .homepage-plate-section {
    width: 95vw; } }

@media screen and (max-width: 1024px) {
  .homepage-plate-section {
    margin-top: 70px; }
    .homepage-plate-section .container {
      width: 85%;
      flex-direction: row !important;
      height: auto;
      padding-top: inherit; }
      .homepage-plate-section .container .left-container {
        width: 50%; }
        .homepage-plate-section .container .left-container .image-container {
          width: 300px; }
      .homepage-plate-section .container .right-container {
        width: 70%;
        padding-left: 30px; }
        .homepage-plate-section .container .right-container p {
          width: 100%; } }

@media screen and (max-width: 768px) {
  .homepage-plate-section {
    width: 100%; }
    .homepage-plate-section .container {
      width: 100%; }
      .homepage-plate-section .container .right-container .text-container {
        top: 3vh; }
        .homepage-plate-section .container .right-container .text-container .giant-title {
          font-size: 30px; }
          .homepage-plate-section .container .right-container .text-container .giant-title span {
            font-size: 30px; }
        .homepage-plate-section .container .right-container .text-container .content {
          width: 85%; }
      .homepage-plate-section .container .left-container .image-container .icon {
        left: 115px;
        bottom: -40px;
        height: 80px; } }

@media screen and (max-width: 640px) {
  .homepage-plate-section .container {
    flex-direction: column !important; }
    .homepage-plate-section .container .left-container, .homepage-plate-section .container .right-container {
      width: 100%; }
    .homepage-plate-section .container .left-container .image-container {
      width: 100%; }
      .homepage-plate-section .container .left-container .image-container .icon {
        top: inherit;
        left: inherit;
        bottom: -40px;
        right: 40%; }
    .homepage-plate-section .container .right-container {
      padding-left: 0;
      width: 95%;
      padding-top: 75px; }
      .homepage-plate-section .container .right-container .text-container {
        position: relative;
        top: inherit;
        left: inherit;
        text-align: center; }
        .homepage-plate-section .container .right-container .text-container .content {
          padding-left: 0;
          margin: 0 auto; }
          .homepage-plate-section .container .right-container .text-container .content .button a {
            margin: 0 auto; } }

.homepage-portrait-section {
  width: 60vw; }
  .homepage-portrait-section .container {
    height: 80vh;
    padding-top: 23vh; }
    .homepage-portrait-section .container .portrait-image-block {
      position: relative;
      width: 300px;
      height: 70vh; }
      .homepage-portrait-section .container .portrait-image-block .image-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%; }
        .homepage-portrait-section .container .portrait-image-block .image-container img {
          height: auto;
          min-width: 100%;
          position: absolute;
          min-height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .homepage-portrait-section .container .portrait-image-block .icon {
        position: absolute;
        height: 100px;
        left: -50px;
        bottom: 40px; }
    .homepage-portrait-section .container .text-container {
      position: relative;
      color: #404040;
      width: 73%; }
      .homepage-portrait-section .container .text-container .text-block {
        position: absolute;
        left: -40px;
        top: 6vh; }
        .homepage-portrait-section .container .text-container .text-block .giant-title {
          font-size: 35px;
          font-family: "Montserrat Semibold"; }
          .homepage-portrait-section .container .text-container .text-block .giant-title span {
            color: #8b0000;
            font-size: 35px;
            font-style: inherit;
            font-family: "Montserrat Semibold"; }
        .homepage-portrait-section .container .text-container .text-block .content {
          width: 85%;
          padding-left: 50px; }
          .homepage-portrait-section .container .text-container .text-block .content blockquote {
            margin-inline-start: 0;
            margin-inline-end: 0; }

@media screen and (max-width: 1440px) {
  .homepage-portrait-section {
    width: 70vw; } }

@media screen and (max-width: 1280px) {
  .homepage-portrait-section {
    width: 90vw; } }

@media screen and (max-width: 1024px) {
  .homepage-portrait-section {
    width: 100%;
    margin-top: 70px; }
    .homepage-portrait-section .container {
      flex-direction: row !important;
      width: 85%;
      margin: 0 auto;
      height: auto;
      padding-top: inherit; }
      .homepage-portrait-section .container .portrait-image-block {
        min-width: 300px; }
      .homepage-portrait-section .container .text-container .text-block {
        top: 0; }
        .homepage-portrait-section .container .text-container .text-block .giant-title {
          font-size: 35px; }
          .homepage-portrait-section .container .text-container .text-block .giant-title span {
            font-size: 35px; }
        .homepage-portrait-section .container .text-container .text-block .content {
          width: 100%; } }

@media screen and (max-width: 768px) {
  .homepage-portrait-section .container {
    width: 100%; }
    .homepage-portrait-section .container .portrait-image-block .icon {
      left: 115px;
      bottom: -40px;
      height: 80px; }
    .homepage-portrait-section .container .text-container .text-block {
      top: 3vh; }
      .homepage-portrait-section .container .text-container .text-block .giant-title {
        font-size: 30px; }
        .homepage-portrait-section .container .text-container .text-block .giant-title span {
          font-size: 30px; }
      .homepage-portrait-section .container .text-container .text-block .content {
        width: 85%; } }

@media screen and (max-width: 640px) {
  .homepage-portrait-section .container {
    flex-direction: column !important; }
    .homepage-portrait-section .container .portrait-image-block {
      min-width: 100%;
      width: 100%; }
      .homepage-portrait-section .container .portrait-image-block .image-container {
        width: 100%; }
        .homepage-portrait-section .container .portrait-image-block .image-container img {
          top: 80%; }
      .homepage-portrait-section .container .portrait-image-block .icon {
        top: inherit;
        left: inherit;
        bottom: -40px;
        right: 40%; }
    .homepage-portrait-section .container .text-container {
      width: 100%; }
      .homepage-portrait-section .container .text-container .text-block {
        position: relative;
        width: 90%;
        margin: 70px auto;
        top: inherit;
        left: inherit;
        text-align: center; }
        .homepage-portrait-section .container .text-container .text-block .giant-title {
          font-size: 25px; }
          .homepage-portrait-section .container .text-container .text-block .giant-title span {
            font-size: 25px; }
        .homepage-portrait-section .container .text-container .text-block .content {
          padding-left: 0;
          width: 100%; }
          .homepage-portrait-section .container .text-container .text-block .content .button a {
            margin: 0 auto; } }

.shows-listing {
  position: relative;
  width: 100%; }
  .shows-listing.over .show-list ul li .show-poster .container a .overlay {
    display: block;
    height: 100%;
    width: 100%;
    background-color: #ececec;
    opacity: 0.6; }
  .shows-listing.over .show-list ul li .show-poster .container a .ended-show {
    width: 80%;
    height: 55%;
    min-height: inherit;
    display: block; }
  .shows-listing .flex {
    display: flex;
    align-items: center; }
  .shows-listing .shows-header {
    width: 100%;
    height: 350px;
    position: relative; }
    .shows-listing .shows-header .image {
      width: 70%;
      overflow: hidden;
      position: relative;
      height: 100%; }
      .shows-listing .shows-header .image img {
        height: auto;
        min-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%; }
    .shows-listing .shows-header .title {
      width: 30%;
      height: 100%;
      background-color: #8b0000; }
      .shows-listing .shows-header .title h1 {
        text-transform: initial;
        color: white;
        text-align: center;
        margin-top: 30%; }
  .shows-listing .sub-nav {
    background-color: #8b0000;
    font-family: "Montserrat Regular"; }
    .shows-listing .sub-nav .mobile-menu {
      display: none; }
    .shows-listing .sub-nav ul {
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      width: 426px;
      margin: 0 auto;
      color: #404040;
      position: relative;
      height: 50px; }
      .shows-listing .sub-nav ul .show-status {
        text-transform: uppercase;
        font-size: 13px;
        display: flex;
        height: 100%;
        position: relative;
        align-items: center;
        padding: 0 20px;
        justify-content: center; }
        .shows-listing .sub-nav ul .show-status.active {
          background-color: white; }
          .shows-listing .sub-nav ul .show-status.active a {
            color: #404040; }
        .shows-listing .sub-nav ul .show-status:hover {
          background-color: white; }
          .shows-listing .sub-nav ul .show-status:hover a {
            color: #404040; }
        .shows-listing .sub-nav ul .show-status a {
          color: white;
          text-decoration: none;
          position: relative; }
  .shows-listing .shows-introduction {
    width: 750px;
    margin: 0 auto;
    text-align: center;
    color: #404040;
    margin-top: 40px;
    border-bottom: 2px solid #f2f2f2;
    padding-bottom: 20px; }
    .shows-listing .shows-introduction h2 {
      text-transform: uppercase; }
  .shows-listing .show-list {
    width: 1200px;
    margin: 50px auto;
    color: #404040; }
    .shows-listing .show-list ul li {
      margin: 60px 0; }
      .shows-listing .show-list ul li:nth-child(2n) {
        flex-direction: row-reverse; }
      .shows-listing .show-list ul li .show-poster {
        width: 45%; }
        .shows-listing .show-list ul li .show-poster .container {
          width: 85%;
          margin: 0 auto;
          height: 450px;
          position: relative;
          overflow: hidden; }
          .shows-listing .show-list ul li .show-poster .container img {
            width: auto;
            display: block;
            margin: 0 auto 0 auto;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            min-height: 100%;
            transform: translate(-50%, -50%); }
          .shows-listing .show-list ul li .show-poster .container a .overlay, .shows-listing .show-list ul li .show-poster .container a .ended-show {
            display: none; }
      .shows-listing .show-list ul li p {
        justify-content: center;
        display: flex;
        width: 100%; }
      .shows-listing .show-list ul li .block-show {
        width: 55%;
        padding-left: 80px; }
        .shows-listing .show-list ul li .block-show a {
          text-decoration: none;
          color: #404040; }
        .shows-listing .show-list ul li .block-show h3 {
          text-transform: uppercase;
          margin-block-end: 0; }
        .shows-listing .show-list ul li .block-show p {
          text-align: left;
          display: block; }
        .shows-listing .show-list ul li .block-show .theater p {
          padding-right: 10px;
          border-right: 1px solid #404040;
          width: auto; }
          .shows-listing .show-list ul li .block-show .theater p.author {
            border: none;
            padding-left: 10px;
            font-style: italic; }
        .shows-listing .show-list ul li .block-show .flex img {
          height: 20px;
          margin-right: 10px; }
        .shows-listing .show-list ul li .block-show .description {
          width: 70%; }

@media screen and (max-width: 1280px) {
  .shows-listing .shows-header .title h1 {
    margin-top: 50%; }
  .shows-listing .show-list {
    width: 1000px; }
    .shows-listing .show-list ul li .show-poster .container {
      height: 360px; }
    .shows-listing .show-list ul li .block-show .description {
      width: 85%; } }

@media screen and (max-width: 1024px) {
  .shows-listing .shows-header {
    flex-direction: column;
    margin-top: 63px;
    height: inherit; }
    .shows-listing .shows-header .image {
      width: 100%;
      height: 250px; }
    .shows-listing .shows-header .title {
      width: 100%;
      height: 100px; }
      .shows-listing .shows-header .title h1 {
        margin-top: 30px; }
  .shows-listing .shows-introduction {
    width: 600px; }
  .shows-listing .show-list {
    width: 95%; }
    .shows-listing .show-list ul li.flex {
      flex-direction: column; }
    .shows-listing .show-list ul li .show-poster {
      width: 80%; }
      .shows-listing .show-list ul li .show-poster .container {
        height: 550px; }
    .shows-listing .show-list ul li .block-show {
      width: 85%;
      margin-top: 30px;
      padding-left: 0; } }

@media screen and (max-width: 768px) {
  .shows-listing .show-list ul li .show-poster .container {
    height: 415px; } }

@media screen and (max-width: 640px) {
  .shows-listing .shows-header {
    z-index: 3; }
  .shows-listing .shows-introduction {
    width: 85%; }
  .shows-listing .sub-nav {
    position: relative; }
    .shows-listing .sub-nav .mobile-menu {
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      background-color: #d9d9d9;
      position: relative;
      z-index: 3; }
      .shows-listing .sub-nav .mobile-menu img {
        height: 12px;
        transform: rotate(-90deg);
        margin-left: 10px; }
    .shows-listing .sub-nav ul {
      background: #d9d9d9;
      position: absolute;
      left: 0;
      top: -100%;
      width: 100%;
      height: auto;
      z-index: 1;
      transform: translateY(-100%);
      transition: all 250ms ease;
      flex-direction: column;
      padding-left: 0; }
      .shows-listing .sub-nav ul.active {
        transform: translateY(0);
        top: 40px; }
      .shows-listing .sub-nav ul .show-status {
        margin: 8px 0; }
        .shows-listing .sub-nav ul .show-status.active {
          background-color: transparent; }
  .shows-listing .show-list ul {
    padding-left: 0; }
    .shows-listing .show-list ul li .show-poster {
      width: 100%; }
      .shows-listing .show-list ul li .show-poster .container {
        width: 100%; }
    .shows-listing .show-list ul li .block-show .description {
      width: 100%; } }

@media screen and (max-width: 420px) {
  .shows-listing .show-list ul li .show-poster .container {
    height: 325px; } }

.single-show {
  color: #404040;
  overflow: hidden; }
  .single-show .flex {
    display: flex;
    align-items: center; }
  .single-show .shows-header {
    width: 100%;
    height: 350px;
    position: relative; }
    .single-show .shows-header .image {
      width: 70%;
      overflow: hidden;
      position: relative;
      height: 100%; }
      .single-show .shows-header .image img {
        height: auto;
        min-height: 100%;
        min-width: 100%;
        max-width: 120%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .single-show .shows-header .title {
      width: 30%;
      height: 100%;
      background-color: #8b0000; }
      .single-show .shows-header .title h1 {
        text-transform: initial;
        color: white;
        text-align: center;
        margin-top: 30%; }
  .single-show .container-sticky-motion {
    height: 267px;
    transition: all ease 0.5s; }
    .single-show .container-sticky-motion.height {
      height: 190px; }
  .single-show .show-details {
    width: 100%;
    background-color: #f2f2f2;
    padding: 25px 0;
    transition: all ease 2s; }
    .single-show .show-details.sticky {
      position: fixed;
      top: 77px;
      z-index: 5;
      transition: all ease 2s;
      padding: 8px 0; }
      .single-show .show-details.sticky .container .show-image {
        display: none; }
      .single-show .show-details.sticky .container .show-description {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0; }
        .single-show .show-details.sticky .container .show-description .details-container .flex-sub {
          display: flex;
          align-items: center; }
        .single-show .show-details.sticky .container .show-description .details-container .price {
          display: none; }
        .single-show .show-details.sticky .container .show-description .details-container .address-dates .address-block {
          display: none; }
        .single-show .show-details.sticky .container .show-description .details-container .address-dates .dates-block {
          border-left: 1px solid #404040;
          padding-left: 10px;
          width: 85%; }
        .single-show .show-details.sticky .container .show-description .button {
          margin-top: 0; }
    .single-show .show-details .container {
      width: 85%;
      margin: 0 auto;
      justify-content: space-between; }
      .single-show .show-details .container img {
        max-height: 217px;
        width: auto; }
    .single-show .show-details .show-description {
      width: 40%; }
      .single-show .show-details .show-description h2, .single-show .show-details .show-description p {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-top: 10px; }
      .single-show .show-details .show-description .sub-title .show-type {
        margin-right: 10px; }
      .single-show .show-details .show-description .sub-title .author {
        border-left: 1px solid #404040;
        padding-left: 10px; }
      .single-show .show-details .show-description .address-dates {
        min-width: 400px; }
        .single-show .show-details .show-description .address-dates img {
          height: 18px;
          margin-top: 7px; }
        .single-show .show-details .show-description .address-dates .address-block {
          width: 150px; }
          .single-show .show-details .show-description .address-dates .address-block p {
            margin-left: 5px; }
        .single-show .show-details .show-description .address-dates .dates-block {
          width: 50%; }
          .single-show .show-details .show-description .address-dates .dates-block p {
            margin-left: 5px; }
        .single-show .show-details .show-description .address-dates .address-block {
          border-right: 1px solid #404040;
          padding-right: 10px; }
        .single-show .show-details .show-description .address-dates .dates-block {
          margin-left: 10px; }
      .single-show .show-details .show-description .button {
        margin-top: 50px; }
  .single-show .show-resume {
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
    transition: all ease 2s; }
    .single-show .show-resume .left-content, .single-show .show-resume .right-content {
      width: 50%; }
    .single-show .show-resume .left-content h2 {
      text-transform: uppercase; }
    .single-show .show-resume .left-content p {
      width: 65%; }
    .single-show .show-resume .right-content {
      height: 380px;
      position: relative;
      overflow: hidden; }
      .single-show .show-resume .right-content img {
        min-height: 100%;
        height: auto;
        min-width: 100%;
        max-width: 140%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .single-show .block-text {
    width: 85%;
    margin: 50px auto; }
    .single-show .block-text h2 {
      text-transform: uppercase; }
  .single-show .slider-photos {
    width: 100%;
    background-color: #f2f2f2;
    position: relative;
    height: 530px;
    overflow: hidden; }
    .single-show .slider-photos #wrap {
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      height: 100%;
      display: flex;
      align-items: stretch;
      padding: 0 0 0 25%;
      overflow: hidden; }
      .single-show .slider-photos #wrap .show-image-hover {
        position: relative;
        width: auto;
        z-index: 1;
        display: flex;
        align-items: center;
        z-index: 2;
        transform: scale(0.97); }
        .single-show .slider-photos #wrap .show-image-hover:hover .content .text {
          opacity: 1;
          transform: scaleY(1) translateY(0); }
        .single-show .slider-photos #wrap .show-image-hover:hover + .fullBg {
          opacity: 1;
          transform: scale(1.02); }
        .single-show .slider-photos #wrap .show-image-hover .content {
          position: relative;
          display: block;
          max-width: 100%;
          margin: 0 50px; }
          .single-show .slider-photos #wrap .show-image-hover .content img {
            position: relative;
            display: block;
            height: 340px;
            width: auto;
            z-index: 2;
            margin: 0 20px; }
          .single-show .slider-photos #wrap .show-image-hover .content .text {
            position: absolute;
            top: 100%;
            left: 10%;
            width: 80%;
            opacity: 0;
            padding: 1em 0 0 1em;
            border-left: 1px solid;
            z-index: 1;
            transform: scaleY(1) translateY(-50px);
            transition: transform .5s, opacity .5s;
            color: #d9d9d9; }
            .single-show .slider-photos #wrap .show-image-hover .content .text .title {
              font-size: 1.2em;
              font-weight: 700;
              text-transform: uppercase;
              margin-block-start: 0;
              margin-block-end: 0; }
      .single-show .slider-photos #wrap .fullBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: transform .5s, opacity .5s;
        transform: scale(1);
        z-index: 1; }
        .single-show .slider-photos #wrap .fullBg img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          opacity: .5; }
  .single-show .booking-show {
    margin-top: 70px; }
    .single-show .booking-show .booking-header {
      width: 85%;
      margin: 0 auto; }
    .single-show .booking-show .slick-booking-date {
      width: 100%;
      background-color: #8b0000;
      padding: 15px 0;
      color: white;
      font-size: 13px; }
      .single-show .booking-show .slick-booking-date .dates-slick {
        width: 85%;
        margin: 0 auto; }
        .single-show .booking-show .slick-booking-date .dates-slick .single-date {
          position: relative; }
          .single-show .booking-show .slick-booking-date .dates-slick .single-date p {
            font-family: "Montserrat Semibold";
            text-align: center; }
          .single-show .booking-show .slick-booking-date .dates-slick .single-date .custom-control {
            width: 100%;
            text-align: center;
            cursor: pointer; }
        .single-show .booking-show .slick-booking-date .dates-slick .slick-prev:before {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid white;
          font-size: 0; }
        .single-show .booking-show .slick-booking-date .dates-slick .slick-next:before {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid white;
          font-size: 0; }
    .single-show .booking-show .select-number {
      width: 85%;
      margin: 20px auto; }
      .single-show .booking-show .select-number p {
        margin-block-end: 0;
        margin-block-start: 0; }
      .single-show .booking-show .select-number .contact {
        font-size: 11px;
        font-style: italic;
        margin-top: 5px; }
        .single-show .booking-show .select-number .contact a {
          color: #8b0000;
          text-decoration: none; }
      .single-show .booking-show .select-number .event-date-price {
        width: 50%;
        margin-top: 20px; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form:first-child {
          display: block !important; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form.active {
          display: block !important; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form form .dates-choices {
          display: flex;
          align-items: center; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form form .date-choice-container .date-choice-price {
          color: #8b0000; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form form .date-choice-container .select-container {
          display: flex;
          align-items: center;
          margin-right: 20px; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form form .date-choice-container .select select {
          margin-left: 15px;
          border: none;
          border-bottom: 3px solid #8b0000;
          background-color: transparent; }
          .single-show .booking-show .select-number .event-date-price .event-date-price-form form .date-choice-container .select select:focus {
            outline: none; }
          .single-show .booking-show .select-number .event-date-price .event-date-price-form form .date-choice-container .select select option {
            background-color: #8b0000;
            border: none;
            color: white;
            border-bottom: 1px solid white; }
            .single-show .booking-show .select-number .event-date-price .event-date-price-form form .date-choice-container .select select option:hover {
              background-color: #6b0101 !important; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form form .button-container {
          text-align: end;
          margin-left: auto; }
          .single-show .booking-show .select-number .event-date-price .event-date-price-form form .button-container button {
            border: none; }

@media screen and (max-width: 1440px) {
  .single-show .slider-photos #wrap {
    width: 200%; }
  .single-show .show-details .show-description .address-dates {
    min-width: 550px; } }

@media screen and (max-width: 1280px) {
  .single-show .slider-photos #wrap {
    width: 250%; }
  .single-show .shows-header .title h1 {
    margin-top: 50%; } }

@media screen and (max-width: 1024px) {
  .single-show .shows-header {
    flex-direction: column;
    margin-top: 63px;
    height: inherit; }
    .single-show .shows-header .image {
      width: 100%;
      height: 250px; }
    .single-show .shows-header .title {
      width: 100%;
      height: 100px; }
      .single-show .shows-header .title h1 {
        margin-top: 35px; }
  .single-show .show-details.sticky {
    top: 63px; }
    .single-show .show-details.sticky .show-description .address-dates {
      min-width: 270px; }
      .single-show .show-details.sticky .show-description .address-dates .dates-block {
        width: 100%; }
  .single-show .show-resume.flex {
    flex-direction: column; }
  .single-show .show-resume .left-content {
    width: 87%; }
    .single-show .show-resume .left-content p {
      width: 100%; }
  .single-show .show-resume .right-content {
    width: 100%;
    margin-top: 30px; }
  .single-show .booking-show .select-number .event-date-price {
    width: 75%; }
  .single-show .slider-photos {
    overflow-x: scroll; }
    .single-show .slider-photos #wrap {
      width: 320%; }
      .single-show .slider-photos #wrap .show-image-hover .content .text {
        opacity: 1;
        transform: scaleY(1) translateY(0);
        color: #404040; }
      .single-show .slider-photos #wrap .fullBg {
        display: none; }
  .single-show .block-text {
    width: 87%; } }

@media screen and (max-width: 768px) {
  .single-show .container-sticky-motion {
    height: auto; }
  .single-show .show-details .container {
    width: 95%; }
    .single-show .show-details .container.flex {
      flex-direction: column-reverse; }
    .single-show .show-details .container .show-description {
      text-align: center;
      width: 100%; }
      .single-show .show-details .container .show-description .details-container {
        width: 100%; }
      .single-show .show-details .container .show-description .sub-title, .single-show .show-details .container .show-description .address-dates {
        justify-content: center; }
      .single-show .show-details .container .show-description .button-animation-container {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 50000; }
        .single-show .show-details .container .show-description .button-animation-container a {
          display: flex;
          justify-content: center;
          padding: 17px 0; }
      .single-show .show-details .container .show-description .address-dates {
        width: 360px;
        margin: 0 auto; }
        .single-show .show-details .container .show-description .address-dates .address-block {
          width: 140px; }
        .single-show .show-details .container .show-description .address-dates .dates-block {
          width: 238px; }
  .single-show .show-resume {
    width: 95%; }
  .single-show .booking-show .booking-header {
    width: 95%; }
  .single-show .booking-show .select-number {
    width: 95%; }
    .single-show .booking-show .select-number .event-date-price {
      width: 100%; }
      .single-show .booking-show .select-number .event-date-price .event-date-price-form form .button-container {
        text-align: center; }
      .single-show .booking-show .select-number .event-date-price .event-date-price-form form .dates-choices {
        flex-direction: column;
        align-items: flex-start; }
        .single-show .booking-show .select-number .event-date-price .event-date-price-form form .dates-choices .button-container {
          margin: 30px 0;
          margin-left: inherit; }
  .single-show .slider-photos #wrap {
    width: 400%; } }

@media screen and (max-width: 640px) {
  .single-show .slider-photos #wrap {
    width: 570%; }
  .single-show .booking-show {
    margin-top: 35px; } }

@media screen and (max-width: 420px) {
  .single-show .shows-header .title {
    height: 130px; }
    .single-show .shows-header .title h1 {
      margin-top: 50px; }
  .single-show .show-resume .right-content {
    height: 300px; }
  .single-show .show-details .container .show-description .address-dates {
    flex-direction: column;
    min-width: inherit;
    width: inherit; }
    .single-show .show-details .container .show-description .address-dates .address-block {
      border: none; }
    .single-show .show-details .container .show-description .address-dates .dates-block {
      width: 100%;
      justify-content: center; } }

body.event .event-date-price {
  position: relative; }
  body.event .event-date-price .form-alert {
    display: flex;
    border-radius: 4px;
    padding: 10px;
    margin: 5px;
    align-items: center;
    font-family: "Montserrat Regular"; }
    body.event .event-date-price .form-alert:empty {
      display: none; }
    body.event .event-date-price .form-alert p {
      font-size: 14px;
      font-weight: bold;
      color: #f7f4f6; }
    body.event .event-date-price .form-alert ul {
      list-style-type: none;
      font-size: 14px;
      color: #f7f4f6;
      padding: 0;
      margin: 0; }
    body.event .event-date-price .form-alert.success {
      background-color: #4ca14e; }
    body.event .event-date-price .form-alert.error {
      background-color: #8b0000; }
  body.event .event-date-price .event-date-price-loader.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f4f6;
    background-image: url(../../../assets//images/loader.gif);
    background-repeat: no-repeat;
    background-position: center center;
    opacity: .8;
    z-index: 2;
    background-size: auto 100%; }

.sylius-validation-error {
  font-family: "Montserrat Semibold";
  font-size: 14px; }
  .sylius-validation-error.red {
    color: #8b0000; }

.error-page {
  background-color: #f2f2f2; }
  .error-page .error-wrap {
    width: 50%;
    margin: auto;
    text-align: center;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .error-page .error-wrap h2 {
      font-size: 25px;
      text-transform: uppercase;
      color: #404040;
      margin-bottom: 0; }
    .error-page .error-wrap p {
      color: #404040;
      font-size: 14px;
      margin-bottom: 60px; }
    .error-page .error-wrap .button-wrap {
      display: flex;
      justify-content: center;
      align-items: center; }
      .error-page .error-wrap .button-wrap .button-animation-container {
        margin: 0 10px; }

@media screen and (max-width: 1024px) {
  .error-page .error-wrap {
    height: auto;
    width: 90%;
    min-height: 85vh; }
    .error-page .error-wrap img {
      width: 200px; }
    .error-page .error-wrap p {
      margin-bottom: 30px; }
    .error-page .error-wrap .button-wrap {
      flex-direction: column; }
      .error-page .error-wrap .button-wrap .button-animation-container {
        width: 100%;
        margin: 10px 0; } }

@media screen and (max-width: 640px) {
  .error-page .error-wrap {
    min-height: 80vh;
    padding-top: 50px; }
    .error-page .error-wrap .button-wrap .button-animation-container {
      width: 100%; } }

.sylius-flash-message, .ui.icon.message {
  background-color: white;
  border-radius: 50px;
  padding: 5px 30px;
  margin-bottom: 10px; }
  .sylius-flash-message.positive, .ui.icon.message.positive {
    color: #4ca14e; }
  .sylius-flash-message.negative, .ui.icon.message.negative {
    color: #8b0000; }
  .sylius-flash-message.info, .ui.icon.message.info {
    color: #4ca14e; }
  .sylius-flash-message .content, .ui.icon.message .content {
    padding-top: 10px; }
    .sylius-flash-message .content .header, .ui.icon.message .content .header {
      font-family: "Montserrat Medium";
      font-size: 14px; }
    .sylius-flash-message .content p, .ui.icon.message .content p {
      margin-top: 0; }

@media screen and (max-width: 1024px) {
  .sylius-flash-message {
    border-radius: initial;
    padding: 5px 10px; } }

/** Generic form styles **/
.generic-form input {
  width: 100%;
  border: none;
  border-bottom: #8b0000 5px solid;
  font-family: "Montserrat Regular";
  padding-bottom: 8px;
  margin-bottom: 20px; }
  .generic-form input:focus {
    outline: none; }

.generic-form select {
  width: 100%;
  border: none;
  border-bottom: #8b0000 5px solid;
  font-family: "Montserrat Regular";
  margin-top: 8px;
  margin-bottom: 20px;
  padding-bottom: 8px;
  background: none;
  background: url("../../../assets//images/arrow-select.png") no-repeat center;
  -webkit-appearance: none;
  background-position-x: 100%; }
  .generic-form select:focus {
    outline: none; }

.generic-form .field {
  position: relative; }
  .generic-form .field .checkbox {
    margin-bottom: 10px; }
    .generic-form .field .checkbox input {
      width: 6px;
      opacity: 0;
      position: absolute;
      margin-top: 0; }
    .generic-form .field .checkbox label {
      width: 95%;
      margin-top: 15px;
      color: #404040;
      font-family: "Montserrat Regular";
      font-size: 13px; }
      .generic-form .field .checkbox label a {
        color: #8b0000;
        text-decoration: none; }
    .generic-form .field .checkbox input + label:before {
      content: '';
      border: 3px solid #8b0000;
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      padding: 2px;
      margin-right: 5px;
      text-align: center; }
    .generic-form .field .checkbox input:checked + label:before {
      content: "";
      border: 3px solid #8b0000;
      background: #8b0000; }

.generic-form .field-with-label {
  display: flex;
  flex-direction: column; }

.generic-form .button-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; }
  .generic-form .button-wrap button {
    background-color: #8b0000;
    color: white;
    border: 0;
    padding: 10px 15px;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Montserrat Medium"; }
    .generic-form .button-wrap button:focus {
      outline: none; }

.single-page {
  min-height: 61vh;
  width: 100%;
  padding-top: 110px; }
  .single-page .flex {
    display: flex;
    align-items: center; }
  .single-page .header {
    width: 100%;
    height: 350px;
    position: relative; }
    .single-page .header .image {
      width: 70%;
      overflow: hidden;
      position: relative;
      height: 100%; }
      .single-page .header .image img {
        height: auto;
        min-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%; }
    .single-page .header .title {
      width: 30%;
      height: 100%;
      background-color: #8b0000; }
      .single-page .header .title h1 {
        text-transform: initial;
        color: white;
        text-align: center;
        margin-top: 30%; }
  .single-page .introduction {
    width: 750px;
    margin: 0 auto;
    text-align: center;
    color: #404040;
    margin-top: 40px;
    border-bottom: 2px solid #f2f2f2;
    padding-bottom: 20px; }
    .single-page .introduction h2 {
      text-transform: uppercase; }
  .single-page .wysiwyg {
    margin: auto;
    width: 80%;
    color: #404040;
    padding-bottom: 20px; }
  .single-page .container {
    width: 80%;
    margin: auto;
    display: flex;
    padding: 60px 0;
    justify-content: space-between; }
    .single-page .container div {
      width: 48%; }
    .single-page .container .wysiwyg {
      border: none;
      text-align: left;
      padding: 0;
      margin: auto 0; }
  .single-page .media {
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .single-page .media img {
      width: 100%; }
  .single-page .container-img {
    height: 280px;
    position: relative;
    margin-bottom: 60px; }
    .single-page .container-img .image {
      height: 280px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center; }
      .single-page .container-img .image img {
        width: 100%; }
    .single-page .container-img .content-wrap {
      position: absolute;
      top: 50px;
      width: 80%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto; }

@media screen and (max-width: 1024px) {
  .single-page {
    min-height: 70vh;
    padding-top: 75px; }
    .single-page .header {
      flex-direction: column;
      margin-top: 63px;
      height: inherit; }
      .single-page .header .image {
        width: 100%;
        height: 250px; }
      .single-page .header .title {
        width: 100%;
        height: 100px; }
        .single-page .header .title h1 {
          margin-top: 30px; }
    .single-page .introduction {
      width: 600px; }
    .single-page .wysiwyg {
      width: 95%;
      margin: auto; }
    .single-page .container {
      width: 100%;
      flex-direction: column;
      padding: 20px 0; }
      .single-page .container div {
        width: 100%; }
      .single-page .container .wysiwyg {
        width: 95%;
        margin: auto; }
      .single-page .container .media {
        height: 150px; }
    .single-page .media {
      height: 150px; }
    .single-page .container-img {
      height: 150px;
      margin-bottom: 20px; }
      .single-page .container-img .image {
        height: 150px; }
      .single-page .container-img .content-wrap {
        top: 0;
        text-align: center; }
        .single-page .container-img .content-wrap .content {
          display: none; } }

@media screen and (max-width: 1024px) {
  .single-page .introduction {
    width: 90%; } }

.cc-message {
  font-style: normal;
  font-size: 14px; }

.block-text .videos-container p {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0; }
  .block-text .videos-container p iframe {
    width: 30%;
    height: 350px;
    margin-top: 15px; }

@media screen and (max-width: 1440px) {
  .block-text .videos-container p iframe {
    height: 300px; } }

@media screen and (max-width: 1024px) {
  .block-text .videos-container p {
    justify-content: space-around; }
    .block-text .videos-container p iframe {
      width: 40%;
      height: 200px; } }

@media screen and (max-width: 768px) {
  .block-text .videos-container p {
    justify-content: space-around; }
    .block-text .videos-container p iframe {
      width: 80%;
      height: 300px; } }

@media screen and (max-width: 420px) {
  .block-text .videos-container p {
    justify-content: space-around; }
    .block-text .videos-container p iframe {
      width: 100%;
      height: 200px; } }

.login {
  padding: 150px 0 40px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .login .message {
    width: 70%; }
  .login .login-header {
    width: 80%;
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    .login .login-header .icon {
      background-image: url(../../../assets//images/icon-your-account.svg);
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      height: 100px;
      width: 100px;
      margin-right: 30px; }
    .login .login-header .title-wrap h2 {
      font-size: 26px;
      font-family: "Montserrat Semibold";
      color: #404040;
      text-transform: uppercase;
      margin: 0; }
    .login .login-header .title-wrap p {
      color: #404040;
      margin-top: 5px; }
  .login .login-form-wrap {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: flex-start; }
    .login .login-form-wrap .column {
      width: 47%;
      background: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 60px;
      padding-bottom: 80px; }
      .login .login-form-wrap .column:nth-child(2) form .submit-container .field:nth-child(2) .checkbox input {
        width: auto; }
      .login .login-form-wrap .column .message {
        border-radius: initial !important;
        padding: 0 !important; }
      .login .login-form-wrap .column h3 {
        width: 70%;
        text-transform: uppercase;
        color: #404040; }
      .login .login-form-wrap .column form {
        width: 70%; }
        .login .login-form-wrap .column form input {
          width: 100%;
          border: none;
          border-bottom: #8b0000 5px solid;
          font-family: "Montserrat Regular";
          padding-bottom: 8px;
          margin-bottom: 20px; }
          .login .login-form-wrap .column form input:focus {
            outline: none; }
        .login .login-form-wrap .column form a {
          font-family: "Montserrat Regular";
          color: #8b0000;
          text-decoration: none;
          font-size: 13px; }
        .login .login-form-wrap .column form .button-wrap {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px; }
          .login .login-form-wrap .column form .button-wrap button {
            background-color: #8b0000;
            color: white;
            border: 0;
            padding: 10px 15px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: "Montserrat Medium"; }
            .login .login-form-wrap .column form .button-wrap button:focus {
              outline: none; }
        .login .login-form-wrap .column form .remember {
          height: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 10px; }
          .login .login-form-wrap .column form .remember input {
            width: 6px;
            opacity: 0;
            position: absolute;
            margin-top: 0; }
          .login .login-form-wrap .column form .remember label {
            width: 95%;
            margin-top: 15px;
            color: #404040;
            font-family: "Montserrat Regular";
            font-size: 13px; }
            .login .login-form-wrap .column form .remember label a {
              color: #8b0000;
              text-decoration: none; }
          .login .login-form-wrap .column form .remember input + label:before {
            content: '';
            border: 3px solid #8b0000;
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            padding: 2px;
            margin-right: 5px;
            text-align: center; }
          .login .login-form-wrap .column form .remember input:checked + label:before {
            content: "";
            border: 3px solid #8b0000;
            background: #8b0000; }

@media screen and (max-width: 1366px) {
  .login .login-header {
    margin-top: 150px; }
    .login .login-header .title-wrap h2 {
      font-size: 20px; } }

@media screen and (max-width: 1280px) {
  .login .login-header {
    margin-top: 50px; } }

@media screen and (max-width: 1024px) {
  .login {
    height: auto;
    padding-top: 100px; }
    .login .login-header {
      margin-bottom: 0;
      width: 90%;
      align-items: initial;
      margin-top: 0; }
      .login .login-header .icon {
        height: 60px;
        background-size: 50px 50px;
        margin-right: 10px;
        width: 60px; }
      .login .login-header .title-wrap {
        width: 85%; }
        .login .login-header .title-wrap p {
          margin-top: 0; }
    .login .login-form-wrap {
      flex-direction: column;
      width: 90%; }
      .login .login-form-wrap .column {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 20px;
        margin-bottom: 20px; }
        .login .login-form-wrap .column h3 {
          width: 90%; }
        .login .login-form-wrap .column form {
          width: 90%; } }

@media screen and (max-width: 640px) {
  .login .login-header .icon {
    height: 50px;
    background-size: 40px 40px;
    width: 15%; } }

.cart main, .checkout main {
  width: 80%;
  margin: auto; }
  .cart main .header-wrap, .checkout main .header-wrap {
    width: 80%;
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .cart main .header-wrap .icon, .checkout main .header-wrap .icon {
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      height: 100px;
      width: 100px;
      margin-right: 30px; }
      .cart main .header-wrap .icon.info, .checkout main .header-wrap .icon.info {
        background-image: url(../../../assets//images/icon-questions.svg); }
      .cart main .header-wrap .icon.address, .checkout main .header-wrap .icon.address {
        background-image: url(../../../assets//images/icon-home.svg); }
      .cart main .header-wrap .icon.payment, .checkout main .header-wrap .icon.payment {
        background-image: url(../../../assets//images/icon-payment.svg); }
      .cart main .header-wrap .icon.final, .checkout main .header-wrap .icon.final {
        background-image: url(../../../assets//images/icon-thanks.svg); }
    .cart main .header-wrap .title-wrap h2, .checkout main .header-wrap .title-wrap h2 {
      font-size: 26px;
      font-family: "Montserrat Semibold";
      color: #404040;
      text-transform: uppercase;
      margin: 0; }
    .cart main .header-wrap .title-wrap p, .checkout main .header-wrap .title-wrap p {
      color: #404040;
      margin-top: 5px; }
  .cart main .column-wrap, .checkout main .column-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px; }
    .cart main .column-wrap .column.summary, .checkout main .column-wrap .column.summary {
      width: 65%;
      position: relative;
      background-color: white; }
      .cart main .column-wrap .column.summary .items-wrap, .checkout main .column-wrap .column.summary .items-wrap {
        width: 90%;
        margin: auto; }
    .cart main .column-wrap .column.total, .checkout main .column-wrap .column.total {
      width: 32%;
      height: fit-content; }
      .cart main .column-wrap .column.total .total-section, .checkout main .column-wrap .column.total .total-section {
        background-color: white; }
        .cart main .column-wrap .column.total .total-section .total-wrap, .checkout main .column-wrap .column.total .total-section .total-wrap {
          width: 90%;
          margin: 0 auto 20px auto;
          padding: 20px 0; }
          .cart main .column-wrap .column.total .total-section .total-wrap .column.left, .checkout main .column-wrap .column.total .total-section .total-wrap .column.left {
            width: 50%; }
          .cart main .column-wrap .column.total .total-section .total-wrap .column.center, .checkout main .column-wrap .column.total .total-section .total-wrap .column.center {
            width: 15%; }
          .cart main .column-wrap .column.total .total-section .total-wrap .column.right, .checkout main .column-wrap .column.total .total-section .total-wrap .column.right {
            width: 35%; }
      .cart main .column-wrap .column.total .change-order, .checkout main .column-wrap .column.total .change-order {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px; }
        .cart main .column-wrap .column.total .change-order .regular-button.light, .checkout main .column-wrap .column.total .change-order .regular-button.light {
          display: inline-block; }
  .cart main table, .checkout main table {
    border-collapse: collapse;
    width: 100%; }
    .cart main table .title, .checkout main table .title {
      height: 90px; }
      .cart main table .title a, .checkout main table .title a {
        font-size: 24px;
        font-family: "Montserrat Semibold";
        color: #404040;
        text-decoration: none; }
      .cart main table .title p, .checkout main table .title p {
        margin: 0; }
    .cart main table .ticket-type, .checkout main table .ticket-type {
      font-size: 20px;
      font-family: "Montserrat Semibold";
      color: #404040; }
    .cart main table .header, .checkout main table .header {
      font-size: 18px;
      font-family: "Montserrat Semibold";
      color: #404040; }
      .cart main table .header .column, .checkout main table .header .column {
        height: 40px; }
    .cart main table .info .column, .cart main table .header .column, .checkout main table .info .column, .checkout main table .header .column {
      width: 30%;
      border-bottom: #f2f2f2 5px solid; }
      .cart main table .info .column.left, .cart main table .header .column.left, .checkout main table .info .column.left, .checkout main table .header .column.left {
        text-align: left; }
      .cart main table .info .column.center, .cart main table .header .column.center, .checkout main table .info .column.center, .checkout main table .header .column.center {
        text-align: center; }
      .cart main table .info .column.right, .cart main table .header .column.right, .checkout main table .info .column.right, .checkout main table .header .column.right {
        text-align: right; }
      .cart main table .info .column.last, .cart main table .header .column.last, .checkout main table .info .column.last, .checkout main table .header .column.last {
        width: 10%; }
    .cart main table .info, .checkout main table .info {
      font-family: "Montserrat Regular";
      font-size: 14px; }
      .cart main table .info .column, .checkout main table .info .column {
        height: 70px; }
      .cart main table .info button, .checkout main table .info button {
        background: none;
        border: none;
        outline: none; }
        .cart main table .info button .deleted-icon, .checkout main table .info button .deleted-icon {
          background-repeat: no-repeat;
          background-position: center center;
          display: flex;
          height: 20px;
          width: 20px;
          background-size: 20px 20px;
          background-image: url(../../../assets//images/icon-delete.svg); }
        .cart main table .info button:hover, .checkout main table .info button:hover {
          cursor: pointer; }
      .cart main table .info .field label, .checkout main table .info .field label {
        visibility: hidden; }
      .cart main table .info .field input, .checkout main table .info .field input {
        text-align: center;
        border: none;
        border-bottom: #404040 1px solid;
        font-family: "Montserrat Regular";
        margin-bottom: 20px;
        width: 80%; }
        .cart main table .info .field input:focus, .checkout main table .info .field input:focus {
          outline: none; }
      .cart main table .info span, .checkout main table .info span {
        font-style: normal; }
    .cart main table .space, .checkout main table .space {
      height: 40px; }
    .cart main table .taxes, .cart main table .total, .cart main table .discount, .checkout main table .taxes, .checkout main table .total, .checkout main table .discount {
      font-family: "Montserrat Regular";
      font-size: 14px;
      color: #404040; }
      .cart main table .taxes .right, .cart main table .total .right, .cart main table .discount .right, .checkout main table .taxes .right, .checkout main table .total .right, .checkout main table .discount .right {
        text-align: right; }
    .cart main table .taxes, .checkout main table .taxes {
      height: 50px; }
      .cart main table .taxes #sylius-cart-tax-included, .checkout main table .taxes #sylius-cart-tax-included {
        font-size: 14px;
        font-style: normal; }
    .cart main table .total, .checkout main table .total {
      font-size: 18px;
      font-family: "Montserrat Semibold"; }
  .cart main #sylius-coupon, .checkout main #sylius-coupon {
    background-color: white;
    margin-bottom: 30px; }
    .cart main #sylius-coupon .coupon-wrap, .checkout main #sylius-coupon .coupon-wrap {
      width: 90%;
      margin: auto;
      padding: 20px 0; }
      .cart main #sylius-coupon .coupon-wrap input, .checkout main #sylius-coupon .coupon-wrap input {
        width: 100%;
        border: none;
        border-bottom: #404040 1px solid;
        font-family: "Montserrat Regular";
        padding-bottom: 8px;
        margin-bottom: 20px; }
        .cart main #sylius-coupon .coupon-wrap input:focus, .checkout main #sylius-coupon .coupon-wrap input:focus {
          outline: none; }
      .cart main #sylius-coupon .coupon-wrap button, .checkout main #sylius-coupon .coupon-wrap button {
        font-size: 12px; }
  .cart main .button-wrap, .checkout main .button-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 40px auto; }
    .cart main .button-wrap.clear, .checkout main .button-wrap.clear {
      position: absolute;
      width: auto;
      right: 5%;
      top: 20px;
      margin: 0; }
      .cart main .button-wrap.clear button, .checkout main .button-wrap.clear button {
        font-size: 12px; }
    .cart main .button-wrap .regular-button.dark, .checkout main .button-wrap .regular-button.dark {
      font-size: 15px; }
    .cart main .button-wrap .regular-button.large, .checkout main .button-wrap .regular-button.large {
      font-size: 15px;
      padding: 10px 40px; }
  .cart main .cart-empty, .checkout main .cart-empty {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .cart main .cart-empty h3, .checkout main .cart-empty h3 {
      margin: 50px 0; }
    .cart main .cart-empty .button-wrap, .checkout main .cart-empty .button-wrap {
      justify-content: center; }
      .cart main .cart-empty .button-wrap .button-animation-container, .checkout main .cart-empty .button-wrap .button-animation-container {
        margin: 0 10px;
        font-size: 15px; }

@media screen and (max-width: 1280px) {
  .cart main table .title a, .checkout main table .title a {
    font-size: 18px; }
  .cart main table .ticket-type, .checkout main table .ticket-type {
    font-size: 16px; }
  .cart main table .header, .checkout main table .header {
    font-size: 15px; } }

@media screen and (max-width: 1024px) {
  .cart main, .checkout main {
    width: 90%; }
    .cart main .header-wrap, .checkout main .header-wrap {
      width: 100%;
      background-color: white;
      padding: 10px 0 20px 0;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .cart main .header-wrap .icon, .checkout main .header-wrap .icon {
        height: 50px;
        width: 100%;
        background-size: 45px 45px;
        margin: 0 0 10px 0; }
      .cart main .header-wrap .title-wrap h2, .cart main .header-wrap .title-wrap p, .checkout main .header-wrap .title-wrap h2, .checkout main .header-wrap .title-wrap p {
        text-align: center; }
      .cart main .header-wrap .title-wrap p, .checkout main .header-wrap .title-wrap p {
        width: 90%;
        margin: auto;
        margin-bottom: 5px; }
    .cart main .column-wrap, .checkout main .column-wrap {
      flex-direction: column;
      margin-bottom: 20px; }
      .cart main .column-wrap .column, .checkout main .column-wrap .column {
        width: 100%; }
        .cart main .column-wrap .column.summary, .checkout main .column-wrap .column.summary {
          width: 100%; }
        .cart main .column-wrap .column.total, .checkout main .column-wrap .column.total {
          width: 100%;
          margin-top: 20px; }
          .cart main .column-wrap .column.total .total-section .total-wrap, .checkout main .column-wrap .column.total .total-section .total-wrap {
            width: 90%;
            margin: 0 auto 20px auto;
            padding: 20px 0; }
          .cart main .column-wrap .column.total .change-order, .checkout main .column-wrap .column.total .change-order {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px; }
            .cart main .column-wrap .column.total .change-order .regular-button.light, .checkout main .column-wrap .column.total .change-order .regular-button.light {
              display: inline-block; }
    .cart main table .title, .checkout main table .title {
      height: 70px; }
      .cart main table .title a, .checkout main table .title a {
        font-size: 15px; }
    .cart main table .header .column, .checkout main table .header .column {
      height: 40px; }
    .cart main table .info .column, .cart main table .header .column, .checkout main table .info .column, .checkout main table .header .column {
      width: 30%; }
    .cart main table .space, .checkout main table .space {
      height: 40px; }
    .cart main #sylius-coupon, .checkout main #sylius-coupon {
      margin-bottom: 20px; }
    .cart main .button-wrap.clear, .checkout main .button-wrap.clear {
      display: none; }
    .cart main .cart-empty, .checkout main .cart-empty {
      width: 100%;
      min-height: 80vh; }
      .cart main .cart-empty .button-wrap, .checkout main .cart-empty .button-wrap {
        flex-direction: column; }
        .cart main .cart-empty .button-wrap .button-animation-container, .checkout main .cart-empty .button-wrap .button-animation-container {
          margin-bottom: 10px;
          text-align: center;
          font-size: 13px; } }

@media screen and (max-width: 640px) {
  .cart main .button-wrap, .checkout main .button-wrap {
    flex-direction: column; }
    .cart main .button-wrap.clear, .checkout main .button-wrap.clear {
      display: none; }
    .cart main .button-wrap .regular-button, .checkout main .button-wrap .regular-button {
      margin-bottom: 10px;
      text-align: center; } }

.cart, .checkout {
  background-color: #f2f2f2; }
  .cart header, .checkout header {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    align-items: center;
    height: 150px; }
    .cart header .logo, .checkout header .logo {
      width: 150px;
      display: contents; }
      .cart header .logo img, .checkout header .logo img {
        height: 70px; }
    .cart header h1, .checkout header h1 {
      color: #404040; }
    .cart header .account, .checkout header .account {
      width: 150px;
      display: flex;
      justify-content: flex-end; }
      .cart header .account img, .checkout header .account img {
        width: 40px;
        height: auto; }

@media screen and (max-width: 1024px) {
  .cart header, .checkout header {
    height: 75px; }
    .cart header .logo, .checkout header .logo {
      width: auto; }
      .cart header .logo img, .checkout header .logo img {
        height: 50px; }
    .cart header h1, .checkout header h1 {
      font-size: 14px; }
    .cart header .account, .checkout header .account {
      width: auto; }
      .cart header .account img, .checkout header .account img {
        width: 25px; } }

.stepper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 50px;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px; }
  .stepper.mobile {
    display: none; }
  .stepper .step {
    width: 25%;
    display: flex;
    justify-content: space-around;
    border-right: dotted 2px grey;
    height: 100px;
    opacity: 0.4; }
    .stepper .step.active {
      opacity: 1; }
      .stepper .step.active .title-wrap h3 {
        color: #8b0000; }
      .stepper .step.active .icon-wrap .icon {
        filter: initial;
        opacity: 1; }
    .stepper .step .icon-wrap {
      display: flex;
      align-items: center; }
      .stepper .step .icon-wrap .icon {
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        height: 60px;
        width: 60px;
        background-size: 60px 60px;
        filter: grayscale(100%);
        opacity: 0.7; }
        .stepper .step .icon-wrap .icon.info {
          background-image: url(../../../assets//images/icon-questions.svg); }
        .stepper .step .icon-wrap .icon.address {
          background-image: url(../../../assets//images/icon-home.svg); }
        .stepper .step .icon-wrap .icon.payment {
          background-image: url(../../../assets//images/icon-payment.svg); }
        .stepper .step .icon-wrap .icon.final {
          background-image: url(../../../assets//images/icon-thanks.svg); }
    .stepper .step .title-wrap {
      width: 70%; }
      .stepper .step .title-wrap h3 {
        font-family: "Montserrat Semibold";
        color: #404040;
        text-transform: uppercase;
        margin: 0;
        font-size: 16px;
        margin-top: 30px; }
      .stepper .step .title-wrap p {
        color: #404040;
        margin-top: 5px; }
  .stepper .step:last-child {
    border: none; }

@media screen and (max-width: 1440px) {
  .stepper .step .icon-wrap .icon {
    height: 50px;
    width: 50px;
    background-size: 50px 50px; }
  .stepper .step .title-wrap h3 {
    margin-top: 15px;
    font-size: 14px !important; } }

@media screen and (max-width: 1024px) {
  .stepper {
    display: none; }
    .stepper.mobile {
      display: block;
      margin: 0;
      padding: 0;
      font-style: normal;
      text-align: center;
      position: absolute;
      bottom: 10px;
      right: 0; } }

.cart footer, .checkout footer {
  background-color: #404040;
  height: 42px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0; }
  .cart footer nav ul, .checkout footer nav ul {
    display: flex;
    list-style-type: none; }
    .cart footer nav ul li, .checkout footer nav ul li {
      padding-left: 10px;
      padding-right: 10px; }
      .cart footer nav ul li span, .checkout footer nav ul li span {
        display: none; }
      .cart footer nav ul li a, .checkout footer nav ul li a {
        color: white;
        font-family: "Montserrat Regular";
        text-decoration: none;
        font-size: 13px; }
  .cart footer img, .checkout footer img {
    height: 40px;
    width: auto; }

@media screen and (max-width: 1024px) {
  .cart footer, .checkout footer {
    flex-direction: column;
    height: auto;
    position: relative; }
    .cart footer nav, .checkout footer nav {
      width: 100%; }
      .cart footer nav ul, .checkout footer nav ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        padding: 0; }
        .cart footer nav ul li, .checkout footer nav ul li {
          margin-bottom: 10px; } }

.checkout .column-wrap .column.address {
  width: 65%;
  background-color: white; }
  .checkout .column-wrap .column.address h3 {
    color: #404040; }
  .checkout .column-wrap .column.address form {
    width: 90%;
    margin: auto; }
    .checkout .column-wrap .column.address form .address-form input {
      width: 100%;
      border: none;
      border-bottom: #8b0000 5px solid;
      font-family: "Montserrat Regular";
      padding-bottom: 8px;
      margin-bottom: 20px; }
      .checkout .column-wrap .column.address form .address-form input:focus {
        outline: none; }
    .checkout .column-wrap .column.address form .address-form select {
      width: 47%;
      border: none;
      border-bottom: #8b0000 5px solid;
      font-family: "Montserrat Regular";
      margin-top: 8px;
      margin-bottom: 20px;
      padding-bottom: 8px;
      background: none;
      background: url("../../../assets//images/arrow-select.png") no-repeat center;
      -webkit-appearance: none;
      background-position-x: 100%; }
      .checkout .column-wrap .column.address form .address-form select:focus {
        outline: none; }
    .checkout .column-wrap .column.address form .address-form .fields {
      display: flex;
      justify-content: space-between; }
      .checkout .column-wrap .column.address form .address-form .fields input {
        width: 47%; }
    .checkout .column-wrap .column.address form .address-book-select p {
      margin: 0; }
    .checkout .column-wrap .column.address form .address-book-select label {
      display: flex; }
      .checkout .column-wrap .column.address form .address-book-select label p {
        font-family: "Montserrat Medium";
        font-size: 13px;
        cursor: pointer;
        margin-left: 5px; }
    .checkout .column-wrap .column.address form .address-content {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: #f2f2f2 3px solid; }
      .checkout .column-wrap .column.address form .address-content .address-details {
        margin-bottom: 10px; }

@media screen and (max-width: 1024px) {
  .checkout .column-wrap .column.address {
    width: 100%; } }

.checkout .column-wrap .column.payment {
  width: 65%;
  background-color: white;
  position: relative; }
  .checkout .column-wrap .column.payment h3 {
    color: #404040; }
  .checkout .column-wrap .column.payment #sylius-payment-methods {
    width: 90%;
    margin: auto; }
    .checkout .column-wrap .column.payment #sylius-payment-methods .unmargined {
      margin: 40px 0; }
      .checkout .column-wrap .column.payment #sylius-payment-methods .unmargined .header {
        font-family: "Montserrat Medium"; }
    .checkout .column-wrap .column.payment #sylius-payment-methods .item {
      margin-bottom: 10px; }
      .checkout .column-wrap .column.payment #sylius-payment-methods .item .custom-control {
        display: flex;
        align-items: center; }
        .checkout .column-wrap .column.payment #sylius-payment-methods .item .custom-control p {
          margin-left: 5px; }
    .checkout .column-wrap .column.payment #sylius-payment-methods .img-wrap {
      padding-left: 25px; }
      .checkout .column-wrap .column.payment #sylius-payment-methods .img-wrap span {
        background-repeat: no-repeat;
        display: flex;
        height: 40px;
        width: 100px; }
        .checkout .column-wrap .column.payment #sylius-payment-methods .img-wrap span.bankcard {
          background-image: url(../../../assets//medias/cards.png); }
        .checkout .column-wrap .column.payment #sylius-payment-methods .img-wrap span.paypal {
          background-image: url(../../../assets//medias/paypal.png); }
  .checkout .column-wrap .column.payment .button-wrap {
    position: absolute;
    bottom: 0;
    width: 90%;
    align-items: center; }
    .checkout .column-wrap .column.payment .button-wrap .regular-button.action {
      font-size: 15px; }

@media screen and (max-width: 1024px) {
  .checkout .column-wrap .column.payment {
    width: 100%; }
    .checkout .column-wrap .column.payment .button-wrap {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column; }
      .checkout .column-wrap .column.payment .button-wrap .regular-button {
        text-align: center; }
        .checkout .column-wrap .column.payment .button-wrap .regular-button.dark {
          font-size: 13px !important;
          display: inline-block;
          width: -webkit-fill-available; }
        .checkout .column-wrap .column.payment .button-wrap .regular-button.action {
          width: 100%; } }

@media screen and (max-width: 640px) {
  .checkout .column-wrap .column.payment .button-wrap {
    position: relative;
    width: 100%;
    align-items: initial; }
    .checkout .column-wrap .column.payment .button-wrap .regular-button.dark {
      font-size: 13px !important;
      display: inline-block;
      width: -webkit-fill-available; }
    .checkout .column-wrap .column.payment .button-wrap .regular-button.action {
      width: 100%; } }

.complete {
  flex-direction: column; }
  .complete .recap {
    background: white;
    padding-bottom: 30px; }
    .complete .recap h3 {
      width: 95%;
      margin: auto;
      color: #404040;
      font-size: 22px;
      padding: 20px 0 10px 0; }
    .complete .recap table {
      width: 95%;
      margin: auto; }
      .complete .recap table a {
        font-family: "Montserrat Regular";
        font-size: 14px;
        color: #404040;
        text-decoration: none; }
      .complete .recap table .info .column, .complete .recap table .header .column {
        width: 17.5%; }
        .complete .recap table .info .column.left, .complete .recap table .header .column.left {
          width: 30%; }
      .complete .recap table .totals {
        display: flex;
        justify-content: space-between; }
      .complete .recap table .taxes {
        height: initial; }
        .complete .recap table .taxes .totals {
          padding-top: 20px; }
        .complete .recap table .taxes #sylius-cart-tax-included {
          font-size: 14px;
          font-style: normal; }
      .complete .recap table .total {
        font-size: 11px;
        font-family: "Montserrat Regular";
        font-weight: 600;
        border-bottom: #f2f2f2 5px solid; }
        .complete .recap table .total .totals {
          padding-bottom: 20px; }
  .complete .two-column {
    display: flex; }
    .complete .two-column .column {
      background-color: white;
      width: 30%;
      margin: 30px 20px 20px 0;
      padding: 20px; }
      .complete .two-column .column h3 {
        margin-top: 0;
        color: #404040; }
      .complete .two-column .column address {
        font-family: "Montserrat Regular";
        font-style: normal;
        font-size: 14px; }
    .complete .two-column #sylius-payments {
      position: relative; }
      .complete .two-column #sylius-payments .message {
        font-family: "Montserrat Regular";
        font-style: normal;
        font-size: 14px;
        border-radius: initial;
        padding: 0; }
      .complete .two-column #sylius-payments .img-wrap span {
        background-repeat: no-repeat;
        display: flex;
        height: 40px;
        width: 100px; }
        .complete .two-column #sylius-payments .img-wrap span.bankcard {
          background-image: url(../../../assets//medias/cards.png); }
        .complete .two-column #sylius-payments .img-wrap span.paypal {
          background-image: url(../../../assets//medias/paypal.png); }
  .complete .complete-footer .button-container {
    width: 100%; }
  .complete .complete-footer button {
    width: 100%;
    font-size: 20px; }
  .complete .complete-footer .checkbox {
    margin: 10px 0;
    height: 20px;
    display: flex;
    align-items: center; }

@media screen and (max-width: 1024px) {
  .complete .recap {
    overflow-x: scroll;
    /* Track */
    /* Handle */ }
    .complete .recap::-webkit-scrollbar {
      width: 10%;
      height: 7px; }
    .complete .recap::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px; }
    .complete .recap::-webkit-scrollbar-thumb {
      background: #8b0000;
      border-radius: 10px;
      width: 20px; }
    .complete .recap table {
      min-width: 800px;
      margin: 0 5%; }
      .complete .recap table .info .right, .complete .recap table .total .right {
        padding-right: 10px; }
      .complete .recap table .tax-disabled {
        padding-right: 10px; }
    .complete .recap h3 {
      width: 90%; }
  .complete .two-column {
    flex-direction: column; }
    .complete .two-column .column {
      padding: 0; }
      .complete .two-column .column h3 {
        margin: 20px; }
      .complete .two-column .column address {
        margin: 0 20px; }
      .complete .two-column .column .regular-button {
        margin: 20px; }
    .complete .two-column #sylius-payments {
      margin-top: 10px; }
      .complete .two-column #sylius-payments .message {
        padding: 0 5% 10px 5%; }
  .complete .complete-footer button {
    font-size: 16px; } }

.checkout .thank-you {
  width: 50%;
  margin: auto;
  text-align: center;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .checkout .thank-you h3 {
    font-size: 25px;
    text-transform: uppercase;
    color: #404040;
    margin-bottom: 0; }
  .checkout .thank-you .subtitle {
    margin-top: 0;
    color: #404040;
    font-size: 14px; }
  .checkout .thank-you .text {
    color: #404040;
    font-size: 14px;
    margin-bottom: 60px; }
  .checkout .thank-you .button-wrap .button-animation-container {
    margin: 0 10px; }

@media screen and (max-width: 1024px) {
  .checkout .thank-you {
    height: auto;
    width: 90%;
    min-height: 85vh; }
    .checkout .thank-you img {
      width: 200px; }
    .checkout .thank-you h3 {
      font-size: 25px;
      text-transform: uppercase;
      color: #404040;
      margin-bottom: 0; }
    .checkout .thank-you .subtitle {
      margin-top: 0;
      color: #404040;
      font-size: 14px; }
    .checkout .thank-you .text {
      margin-bottom: 30px; } }

@media screen and (max-width: 640px) {
  .checkout .thank-you {
    min-height: 80vh; }
    .checkout .thank-you .button-wrap .button-animation-container {
      margin: 10px 0; } }

.create-account-page {
  background: #f2f2f2; }
  .create-account-page .container {
    width: 80%;
    margin: 0 auto;
    padding-top: 150px;
    font-family: "Montserrat Regular";
    min-height: 90vh; }
    .create-account-page .container .header-account {
      width: 80%;
      display: flex;
      align-items: center;
      margin-bottom: 40px; }
      .create-account-page .container .header-account .icon {
        background-image: url(../../../assets//images/icon-your-account.svg);
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        height: 100px;
        width: 100px;
        margin-right: 30px; }
      .create-account-page .container .header-account h1 {
        font-size: 26px;
        font-family: "Montserrat Semibold";
        color: #404040;
        text-transform: uppercase;
        margin: 0; }
      .create-account-page .container .header-account .login-path a {
        color: #8b0000;
        font-size: 13px;
        margin-top: 5px; }
    .create-account-page .container .create-account-container {
      background: white;
      padding: 70px; }
      .create-account-page .container .create-account-container form {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap; }
        .create-account-page .container .create-account-container form .left-container, .create-account-page .container .create-account-container form .right-container {
          width: 50%; }
          .create-account-page .container .create-account-container form .left-container input, .create-account-page .container .create-account-container form .right-container input {
            width: 100%;
            border: none;
            border-bottom: 3px solid #8b0000;
            margin-bottom: 15px;
            font-family: "Montserrat Regular"; }
            .create-account-page .container .create-account-container form .left-container input:focus, .create-account-page .container .create-account-container form .right-container input:focus {
              outline: none; }
          .create-account-page .container .create-account-container form .left-container .global-informations, .create-account-page .container .create-account-container form .right-container .global-informations {
            width: 90%; }
          .create-account-page .container .create-account-container form .left-container .field .checkbox, .create-account-page .container .create-account-container form .right-container .field .checkbox {
            margin-bottom: 10px; }
            .create-account-page .container .create-account-container form .left-container .field .checkbox input, .create-account-page .container .create-account-container form .right-container .field .checkbox input {
              width: 6px;
              opacity: 0;
              position: absolute;
              margin-top: 0; }
            .create-account-page .container .create-account-container form .left-container .field .checkbox label, .create-account-page .container .create-account-container form .right-container .field .checkbox label {
              width: 95%;
              margin-top: 15px;
              color: #404040;
              font-family: "Montserrat Regular";
              font-size: 13px; }
              .create-account-page .container .create-account-container form .left-container .field .checkbox label a, .create-account-page .container .create-account-container form .right-container .field .checkbox label a {
                color: #8b0000;
                text-decoration: none; }
            .create-account-page .container .create-account-container form .left-container .field .checkbox input + label:before, .create-account-page .container .create-account-container form .right-container .field .checkbox input + label:before {
              content: '';
              border: 3px solid #8b0000;
              display: inline-block;
              vertical-align: middle;
              width: 6px;
              height: 6px;
              padding: 2px;
              margin-right: 5px;
              text-align: center; }
            .create-account-page .container .create-account-container form .left-container .field .checkbox input:checked + label:before, .create-account-page .container .create-account-container form .right-container .field .checkbox input:checked + label:before {
              content: "";
              border: 3px solid #8b0000;
              background: #8b0000; }
        .create-account-page .container .create-account-container form .submit-container {
          width: 100%;
          display: flex;
          justify-content: flex-end; }

@media screen and (max-width: 1024px) {
  .create-account-page .container {
    width: 90%;
    padding-top: 100px; }
    .create-account-page .container .header-account h1 {
      font-size: 16px; }
    .create-account-page .container .header-account .icon {
      height: 60px;
      background-size: 50px 50px;
      margin-right: 10px;
      width: 60px; }
    .create-account-page .container .create-account-container {
      padding: 50px; }
      .create-account-page .container .create-account-container form {
        flex-direction: column; }
        .create-account-page .container .create-account-container form .left-container, .create-account-page .container .create-account-container form .right-container {
          width: 100%; }
          .create-account-page .container .create-account-container form .left-container .global-informations, .create-account-page .container .create-account-container form .right-container .global-informations {
            width: 100%; } }

@media screen and (max-width: 420px) {
  .create-account-page .container .header-account {
    width: 100%; }
    .create-account-page .container .header-account .icon {
      flex-shrink: 0; }
  .create-account-page .container .create-account-container {
    padding: 20px; } }

.customer-account {
  background: #f2f2f2;
  padding: 150px 0 40px 0;
  min-height: 60vh;
  font-family: "Montserrat Regular"; }
  .customer-account .sylius-flash-message {
    width: 70%;
    margin: auto; }
  .customer-account .account-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative; }
    .customer-account .account-container .account-nav {
      width: 300px;
      padding: 40px;
      background: white; }
      .customer-account .account-container .account-nav .menu {
        display: flex;
        flex-direction: column; }
        .customer-account .account-container .account-nav .menu .item {
          text-decoration: none;
          color: #404040;
          padding: 5px 0; }
          .customer-account .account-container .account-nav .menu .item.header {
            font-family: "Montserrat Semibold";
            text-transform: uppercase;
            color: #8b0000;
            margin-bottom: 15px;
            font-size: 20px; }
    .customer-account .account-container .account-content {
      width: auto;
      margin-left: 30px;
      background: white;
      padding: 40px;
      flex-grow: 1; }
      .customer-account .account-container .account-content h1 {
        font-size: 24px;
        margin: 0;
        margin-bottom: 20px; }
        .customer-account .account-container .account-content h1 .sub {
          font-size: 16px;
          text-transform: none; }
      .customer-account .account-container .account-content .logout {
        position: absolute;
        top: 0;
        right: 15px; }
      .customer-account .account-container .account-content form {
        width: 70%; }
      .customer-account .account-container .account-content .sylius-grid-wrapper .pagination, .customer-account .account-container .account-content .sylius-grid-wrapper .dropdown {
        flex-direction: row;
        margin: 10px 0; }
        .customer-account .account-container .account-content .sylius-grid-wrapper .pagination .item, .customer-account .account-container .account-content .sylius-grid-wrapper .dropdown .item {
          background: initial;
          color: initial;
          margin: 0 10px;
          padding: initial; }
        .customer-account .account-container .account-content .sylius-grid-wrapper .pagination a.item:hover, .customer-account .account-container .account-content .sylius-grid-wrapper .dropdown a.item:hover {
          color: #8b0000;
          transition: color .3s ease-in-out; }
        .customer-account .account-container .account-content .sylius-grid-wrapper .pagination .menu, .customer-account .account-container .account-content .sylius-grid-wrapper .dropdown .menu {
          display: inline-flex;
          flex-direction: row; }
      .customer-account .account-container .account-content .booking-button:before {
        z-index: 0; }
      .customer-account .account-container .account-content .booking-button p {
        z-index: 1; }
      .customer-account .account-container .account-content .styled.header {
        font-family: "Montserrat Semibold";
        font-size: 17px;
        margin: 5px 0; }
      .customer-account .account-container .account-content .buttons .button {
        padding: 10px 20px;
        background: #8b0000;
        color: white;
        display: inline-block;
        border: 0px;
        margin: 10px 0;
        text-decoration: none;
        font-family: "Montserrat Regular";
        font-size: 16px;
        cursor: pointer;
        border-radius: 0px; }
      .customer-account .account-container .account-content .horizontal {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .customer-account .account-container .account-content .horizontal .item {
          margin-right: 10px; }
      .customer-account .account-container .account-content table {
        border: 1px solid #d9d9d9;
        width: 100%;
        border-collapse: collapse; }
        .customer-account .account-container .account-content table thead th {
          font-family: "Montserrat Semibold";
          padding: 5px;
          color: #404040;
          border: 1px solid #d9d9d9; }
          .customer-account .account-container .account-content table thead th.quantity {
            display: none; }
          .customer-account .account-container .account-content table thead th a {
            color: #404040;
            text-decoration: none; }
        .customer-account .account-container .account-content table tbody tr td {
          text-align: center;
          font-size: 14px;
          border: 1px solid #d9d9d9; }
          .customer-account .account-container .account-content table tbody tr td .sylius-product-name {
            font-style: normal;
            font-size: 15px; }
          .customer-account .account-container .account-content table tbody tr td.quantity {
            display: none; }
        .customer-account .account-container .account-content table tfoot .totals {
          padding-left: 15px; }
      .customer-account .account-container .account-content #customer-information {
        margin-bottom: 10px; }
      .customer-account .account-container .account-content .green {
        background: #4ca14e;
        color: white;
        padding: 5px;
        font-style: normal;
        border-radius: 4px;
        display: inline-block;
        margin: 5px 0; }
      .customer-account .account-container .account-content .red {
        background: #a80101;
        color: white;
        padding: 5px;
        font-style: normal;
        border-radius: 4px;
        display: inline-block;
        margin: 5px 0; }
      .customer-account .account-container .account-content .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        .customer-account .account-container .account-content .menu a.item {
          color: white;
          background: #8b0000;
          padding: 10px 20px;
          text-decoration: none;
          margin: 5px 0; }

@media screen and (max-width: 1024px) {
  .customer-account {
    padding: 100px 0 40px 0; }
    .customer-account .account-container {
      flex-direction: column;
      width: 90%; }
      .customer-account .account-container .account-nav {
        width: auto; }
      .customer-account .account-container .account-content {
        margin-left: 0;
        margin-top: 15px; }
        .customer-account .account-container .account-content form {
          width: 100%; }
        .customer-account .account-container .account-content .logout .regular-button {
          padding: 5px 10px; }
          .customer-account .account-container .account-content .logout .regular-button .button-text {
            font-size: 11px; } }

@media screen and (max-width: 640px) {
  .customer-account .account-container {
    width: 100%; }
    .customer-account .account-container .account-nav {
      width: auto;
      padding: 15px; }
    .customer-account .account-container .account-content {
      padding: 15px; }
      .customer-account .account-container .account-content .logout {
        right: 0; }
      .customer-account .account-container .account-content table thead th {
        font-size: 13px; }
      .customer-account .account-container .account-content table tbody tr td {
        font-size: 12px; }
        .customer-account .account-container .account-content table tbody tr td .sylius-product-name {
          font-size: 13px; }
      .customer-account .account-container .account-content table tfoot .totals {
        font-size: 14px; } }
