.homepage-plate-section {
    width: 80vw;
    .container {
        width: 85%;
        margin: 0 auto;
        height: 80vh;
        padding-top: 23vh;
        .left-container {
            .image-container {
                height: 70vh;
                width: 380px;
                position: relative;
                .background-image {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    .photo {
                        height: 100%;
                        min-width: 100%;
                        position: absolute;
                        min-height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .icon {
                    position: absolute;
                    height: 100px;
                    left: -50px;
                    bottom: 40px;
                }
            }
        }
        .right-container {
            width: 73%;
            margin: 0 auto;
            padding-top: 150px;
            padding-left: 30px;
            color: $main-color;
            position: relative;
            .text-container {
                position: absolute;
                left: -29px;
                top: 6vh;
                .giant-title {
                    font-size: 35px;
                    font-family: $font-semibold;
                    width: 100%;
                    span {
                        color: $secondary-color;
                        font-size: 35px;
                        font-style: inherit;
                        font-family: $font-semibold;
                    }
                }
                .content {
                    width: 65%;
                    padding-left: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .homepage-plate-section {
        width: 95vw;
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .homepage-plate-section {
        margin-top: 70px;
        .container {
            width: 85%;
            flex-direction: row !important;
            height: auto;
            padding-top: inherit;
            .left-container {
                width: 50%;
                .image-container {
                    width: 300px;
                }
            }
            .right-container {
                width: 70%;
                padding-left: 30px;
                p {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    .homepage-plate-section {
        width: 100%;
        .container {
            width: 100%;
            .right-container {
                .text-container {
                    top: 3vh;
                    .giant-title {
                        font-size: 30px;
                        span {
                            font-size: 30px;
                        }
                    }
                    .content {
                        width: 85%;
                    }
                }
            }
            .left-container {
                .image-container {
                    .icon {
                        left: 115px;
                        bottom: -40px;
                        height: 80px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .homepage-plate-section {
        .container {
            flex-direction: column !important;
            .left-container, .right-container {
                width: 100%;
            }
            .left-container {
                .image-container {
                    width: 100%;
                    .icon {
                        top: inherit;
                        left: inherit;
                        bottom: -40px;
                        right: 40%;
                    }
                }
            }
            .right-container {
                padding-left: 0;
                width: 95%;
                padding-top: 75px;
                .text-container {
                    position: relative;
                    top: inherit;
                    left: inherit;
                    text-align: center;
                    .content {
                        padding-left: 0;
                        margin: 0 auto;
                        .button a {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}