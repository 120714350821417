.cart, .checkout {
    background-color: $light-bg;
    header {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        align-items: center;
        height: 150px;
        .logo {
            width: 150px;
            display: contents;
            img {
                height: 70px;
            }
        }
        h1 {
            color: $main-color;
        }
        .account {
            width: 150px;
            display: flex;
            justify-content: flex-end;
            img {
                width: 40px;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .cart, .checkout {
        header {
            height: 75px;
            .logo {
                width: auto;
                img {
                    height: 50px;
                }
            }
            h1 {
                font-size: 14px;
            }
            .account {
                width: auto;
                img {
                    width: 25px;
                }
            }
        }
    }
}