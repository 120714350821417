.complete {
    flex-direction: column;
    .recap {
        background: white;
        padding-bottom: 30px;
        h3 {
            width: 95%;
            margin: auto;
            color: $main-color;
            font-size: 22px;
            padding: 20px 0 10px 0;
        }
        table {
            width: 95%;
            margin: auto;
            a {
                font-family: $font-regular;
                font-size: 14px;
                color: $main-color;
                text-decoration: none;
            }
            .info, .header {
                .column {
                    width: 17.5%;
                    &.left {
                        width: 30%;
                    }
                }
            }
            .totals {
                display: flex;
                justify-content: space-between;
            }
            .taxes {
                height: initial;
                .totals {
                    padding-top: 20px;
                }
                #sylius-cart-tax-included {
                    font-size: 14px;
                    font-style: normal;
                }
            }
            .total {
                font-size: 11px;
                font-family: $font-regular;
                font-weight: 600;
                border-bottom: $light-bg 5px solid;
                .totals {
                    padding-bottom: 20px;
                }
            }
        }
    }
    .two-column {
        display: flex;
        .column {
            background-color: white;
            width: 30%;
            margin: 30px 20px 20px 0;
            padding: 20px;
            h3 {
                margin-top: 0;
                color: $main-color;
            }
            address {
                font-family: $font-regular;
                font-style: normal;
                font-size: 14px;
            }
        }
        #sylius-payments {
            position: relative;
            .message {
                font-family: $font-regular;
                font-style: normal;
                font-size: 14px;
                border-radius: initial;
                padding: 0;
            }

            .img-wrap {
                span {
                    background-repeat: no-repeat;
                    display: flex;
                    height: 40px;
                    width: 100px;
                    &.bankcard {
                        background-image: url(#{$medias_dir}/medias/cards.png);
                    }
                    &.paypal {
                        background-image: url(#{$medias_dir}/medias/paypal.png);
                    }
                }
            }
        }
    }
    .complete-footer {
        .button-container {
            width: 100%;
        }

        button {
            width: 100%;
            font-size: 20px;
        }
        .checkbox {
            margin: 10px 0;
            height: 20px;
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .complete {
        .recap {
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 10%;
                height: 7px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background-color: $medium-grey;
              border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: $secondary-color;
              border-radius: 10px;
              width: 20px;
            }
            table {
                min-width: 800px;
                margin: 0 5%;
                .info, .total {
                    .right {
                        padding-right: 10px;
                    }
                }
                .tax-disabled {
                    padding-right: 10px;
                }
            }
            h3 {
                width: 90%;
            }
        }
        .two-column {
            flex-direction: column;
            .column {
                padding: 0;
                h3 {
                    margin: 20px;
                }
                address {
                    margin: 0 20px;
                }
                .regular-button {
                    margin: 20px;
                }
            }
            #sylius-payments {
                margin-top: 10px;
                .message {
                    padding: 0 5% 10px 5%;
                }
                //.regular-button {
                //    &.light {
                //        position: relative;
                //        margin-bottom: 0;
                //    }
                //}
            }
        }
        .complete-footer {
            button {
                font-size: 16px;
            }
        }
    }
}
