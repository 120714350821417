.contact-page {
    .flex {
        display: flex;
    }
    .shows-header {
        width: 100%;
        height: 350px;
        position: relative;
        .image {
            width: 70%;
            overflow: hidden;
            position: relative;
            height: 100%;
            img {
                height: auto;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .title {
            width: 30%;
            height: 100%;
            background-color: $secondary-color;
            h1 {
                text-transform: initial;
                color: white;
                text-align: center;
                margin-top: 30%;
            }
        }
    }
    .contact-body {
        width: 90%;
        margin: 0 auto;
        padding: 70px 0;
        .left-container, .right-container {
            width: 50%;
            color: $main-color;
            .inside-container {
                width: 90%;
                margin: 0 auto;
                h2 {
                    text-transform: uppercase;
                    margin-block-end: 0;
                }
            }
        }
        .left-container {
            .informations {
                margin-top: 15px;
                span {
                    font-style: inherit;
                    margin-block-end: 0;
                    font-family: $font-semibold;
                }
                p {
                    margin-block-start: 0;
                }
            }
            .block-details-informations {
                margin-top: 35px;
                h3 {
                    text-transform: uppercase;
                    margin-block-end: 0;
                }
                a {
                    color: $main-color;
                    font-family: $font-regular;
                    text-decoration: none;
                }
                img {
                    height: 17px;
                }
                p, address {
                    margin-left: 10px;
                }
                .flex {
                    align-items: center;
                }
            }
        }
        .right-container {
            padding-left: 30px;
            form {
                margin-top: 20px;
                width: 70%;
                input, textarea {
                    width: 100%;
                    border: none;
                    border-bottom: 3px solid $secondary-color;
                    margin-bottom: 15px;
                    font-family: $font-regular;
                    &:focus {
                        outline: none;
                    }
                }
                textarea {
                    position: relative;
                    padding-top: 50px;
                    line-height: 12px;

                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .contact-page {
        .shows-header {
            .title {
                h1 {
                    margin-top: 50%;
                }
            }
        }
        .contact-body {
            .right-container {
                form {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .contact-page {
        .shows-header {
            flex-direction: column;
            margin-top: 63px;
            height: inherit;
            .image {
                width: 100%;
                height: 250px;
                img {
                    width: 100%;
                    min-height: 100%;
                    height: auto;
                }
            }
            .title {
                width: 100%;
                height: 100px;
                h1 {
                    margin-top: 30px;
                }
            }
        }
        .contact-body {
            &.flex {
                flex-direction: column;
            }
            .left-container, .right-container {
                width: 100%;
            }
            .right-container {
                margin-top: 60px;
                padding-left: 0;
            }
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    .contact-page {
        .shows-header {
            .image {
                img {
                    height: 100%;
                    min-width: 100%;
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    .contact-page {
        .shows-header {
            .image {
                height: 180px;
            }
        }
    }
}
