.stepper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    background-color: white;
    border-radius: 50px;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    &.mobile {
        display: none;
    }
    .step {
        width: 25%;
        display: flex;
        justify-content: space-around;
        border-right: dotted 2px grey;
        height: 100px;
        opacity: 0.4;
        &.active {
            opacity: 1;
            .title-wrap {
                h3 {
                    color: $secondary-color;
                }
            }
            .icon-wrap {
                .icon {
                    filter: initial;
                    opacity: 1;
                }
            }
        }
        .icon-wrap {
            display: flex;
            align-items: center;
            .icon {
                background-repeat: no-repeat;
                background-position: center center;
                display: flex;
                height: 60px;
                width: 60px;
                background-size: 60px 60px;
                filter: grayscale(100%);
                opacity: 0.7;
                &.info {
                    background-image: url(#{$medias_dir}/images/icon-questions.svg);
                }
                &.address {
                    background-image: url(#{$medias_dir}/images/icon-home.svg);
                }
                &.payment {
                    background-image: url(#{$medias_dir}/images/icon-payment.svg);
                }
                &.final {
                    background-image: url(#{$medias_dir}/images/icon-thanks.svg);
                }
            }
        }
        .title-wrap {
            width: 70%;
            h3 {
                font-family: $font-semibold;
                color: $main-color;
                text-transform: uppercase;
                margin: 0;
                font-size: 16px;
                margin-top: 30px;
            }
            p {
                color: $main-color;
                margin-top: 5px;
            }
        }
    }
    .step:last-child {
        border: none;
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    .stepper {
        .step {
            .icon-wrap {
                .icon {
                    height: 50px;
                    width: 50px;
                    background-size: 50px 50px;
                }
            }
            .title-wrap {
                h3 {
                    margin-top: 15px;
                    font-size: 14px!important;
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .stepper {
        display: none;
        &.mobile {
            display: block;
            margin: 0;
            padding: 0;
            font-style: normal;
            text-align: center;
            position: absolute;
            bottom: 10px;
            right: 0;
        }
    }
}