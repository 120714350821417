.cart, .checkout {
    main {
        width: 80%;
        margin: auto;
        .header-wrap {
            width: 80%;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .icon {
                background-repeat: no-repeat;
                background-position: center center;
                display: flex;
                height: 100px;
                width: 100px;
                margin-right: 30px;
                &.info {
                    background-image: url(#{$medias_dir}/images/icon-questions.svg);
                }
                &.address {
                    background-image: url(#{$medias_dir}/images/icon-home.svg);
                }
                &.payment {
                    background-image: url(#{$medias_dir}/images/icon-payment.svg);
                }
                &.final {
                    background-image: url(#{$medias_dir}/images/icon-thanks.svg);
                }
            }
            .title-wrap {
                h2 {
                    font-size: 26px;
                    font-family: $font-semibold;
                    color: $main-color;
                    text-transform: uppercase;
                    margin: 0;
                }
                p {
                    color: $main-color;
                    margin-top: 5px;
                }
            }
        }
        .column-wrap {
            display: flex;
            justify-content: space-between;
            margin-bottom: 150px;
            .column {
                &.summary {
                    width: 65%;
                    position: relative;
                    background-color: white;
                    .items-wrap {
                        width: 90%;
                        margin: auto;
                    }
                }
                &.total {
                    width: 32%;
                    height: fit-content;
                    .total-section {
                        background-color: white;
                        .total-wrap {
                            width: 90%;
                            margin: 0 auto 20px auto;
                            padding: 20px 0;
                            .column {
                                &.left {
                                    width: 50%;
                                }
                                &.center {
                                    width: 15%;
                                }
                                &.right {
                                    width: 35%;
                                }
                            }
                        }
                    }
                    .change-order {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 30px;
                        .regular-button {
                            &.light {
                                display: inline-block;

                            }
                        }
                    }
                }
            }
        }
        table {
            border-collapse: collapse;
            width: 100%;
            .title {
                height: 90px;
                a {
                    font-size: 24px;
                    font-family: $font-semibold;
                    color: $main-color;
                    text-decoration: none;
                }
                p {
                    margin: 0;
                }
            }
            .ticket-type {
                font-size: 20px;
                font-family: $font-semibold;
                color: $main-color;
            }
            .header {
                font-size: 18px;
                font-family: $font-semibold;
                color: $main-color;
                .column {
                    height: 40px;
                }
            }
            .info, .header {
                .column {
                    width: 30%;
                    border-bottom: $light-bg 5px solid;
                    &.left {
                        text-align: left;
                    }
                    &.center {
                        text-align: center;
                    }
                    &.right {
                        text-align: right;
                    }
                    &.last {
                        width: 10%;
                    }
                }
            }
            .info {
                font-family: $font-regular;
                font-size: 14px;
                .column {
                    height: 70px;
                }
                button {
                    background: none;
                    border: none;
                    outline: none;
                    .deleted-icon {
                        background-repeat: no-repeat;
                        background-position: center center;
                        display: flex;
                        height: 20px;
                        width: 20px;
                        background-size: 20px 20px;
                        background-image: url(#{$medias_dir}/images/icon-delete.svg);
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
                .field {
                    label {
                        visibility: hidden;
                    }
                    input {
                        text-align: center;
                        border: none;
                        border-bottom: $main-color 1px solid;
                        font-family: $font-regular;
                        margin-bottom: 20px;
                        width: 80%;
                        &:focus {
                            outline: none;
                        }
                    }
                }
                span {
                    font-style: normal;
                }
            }
            .space {
                height: 40px;
            }
            .taxes, .total, .discount {
                font-family: $font-regular;
                font-size: 14px;
                color: $main-color;
                .right {
                    text-align: right;
                }
            }
            .taxes {
                height: 50px;
                #sylius-cart-tax-included {
                    font-size: 14px;
                    font-style: normal;
                }
            }
            .total {
                font-size: 18px;
                font-family: $font-semibold;
            }
        }
        #sylius-coupon {
            background-color: white;
            margin-bottom: 30px;
            .coupon-wrap {
                width: 90%;
                margin: auto;
                padding: 20px 0;
                input {
                    width: 100%;
                    border: none;
                    border-bottom: $main-color 1px solid;
                    font-family: $font-regular;
                    padding-bottom: 8px;
                    margin-bottom: 20px;
                    &:focus {
                        outline: none;
                    }
                }
                button {
                    font-size: 12px;
                }
            }
        }
        .button-wrap {
            display: flex;
            justify-content: space-between;
            margin: 0 auto 40px auto;
            &.clear {
                position: absolute;
                width: auto;
                right: 5%;
                top: 20px;
                margin: 0;
                button {
                    font-size: 12px;
                }
            }
            .regular-button {
                &.dark {
                    font-size: 15px;
                }
                &.large {
                    font-size: 15px;
                    padding: 10px 40px;
                }
            }
        }
        .cart-empty {
            width: 50%;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h3 {
                margin: 50px 0;
            }
            .button-wrap {
                justify-content: center;
                .button-animation-container{
                    margin: 0 10px;
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .cart, .checkout {
        main {
            table {
                .title {
                    a {
                        font-size: 18px;
                    }
                }
                .ticket-type {
                    font-size: 16px;
                }
                .header {
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .cart, .checkout {
        main {
            width: 90%;
            .header-wrap {
                width: 100%;
                background-color: white;
                padding: 10px 0 20px 0;
                position: relative;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .icon {
                    height: 50px;
                    width: 100%;
                    background-size: 45px 45px;
                    margin: 0 0 10px 0;
                }
                .title-wrap {
                    h2, p {
                        text-align: center;
                    }
                    p {
                        width: 90%;
                        margin: auto;
                        margin-bottom: 5px;
                    }
                }
            }
            .column-wrap {
                flex-direction: column;
                margin-bottom: 20px;
                .column {
                    width: 100%;
                    &.summary {
                        width: 100%;
                    }
                    &.total {
                        width: 100%;
                        margin-top: 20px;
                        .total-section {
                            .total-wrap {
                                width: 90%;
                                margin: 0 auto 20px auto;
                                padding: 20px 0;
                            }
                        }
                        .change-order {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 30px;
                            .regular-button {
                                &.light {
                                    display: inline-block;

                                }
                            }
                        }
                    }
                }
            }
            table {
                .title {
                    height: 70px;
                    a {
                        font-size: 15px;
                    }
                }
                .header {
                    .column {
                        height: 40px;
                    }
                }
                .info, .header {
                    .column {
                        width: 30%;
                    }
                }
                .space {
                    height: 40px;
                }
            }
            #sylius-coupon {
                margin-bottom: 20px;
            }
            .button-wrap {
                &.clear {
                    display: none;
                }
            }
            .cart-empty {
                width: 100%;
                min-height: 80vh;
                .button-wrap {
                    flex-direction: column;
                    .button-animation-container {
                        margin-bottom: 10px;
                        text-align: center;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .cart, .checkout {
        main {
            .button-wrap {
                flex-direction: column;
                &.clear {
                    display: none;
                }
                .regular-button {
                    margin-bottom: 10px;
                    text-align: center;
                }
            }
        }
    }
}
