body {
    margin: 0;
    &.homepage {
        overflow-x: scroll;
        overflow-y: hidden !important;
        margin: 0;
        width: max-content;
        position: relative;
        display: flex;
        align-items: flex-start;
        &.form-validate {
            .popin-overlay, .popins {
                display: none;
            }
        }
        #cookie-bar {
            display: none;
        }
        main {
            display: block !important;
            .container {
                @supports (-ms-ime-align:auto) {
                    display: -ms-flexbox !important;
                    flex-direction: row;
                }
                display: flex;
                justify-content: space-between;

            }
        }
        .homepage-intro-section-pp {
            width: 80vw;
            height: 100vh;
            overflow-y: hidden;
            flex: 1 0 auto;
        }
        .homepage-slider-section-pp {
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                width: 1600px;
            }

            width: 90vw;
            flex: 1 0 auto;
        }
        .homepage-footer-section-pp {
            flex: 1 0 auto;
            width: 30vw;
        }
        #_progress {
            --scroll: 0%;
            background: linear-gradient(to right, $secondary-color var(--scroll),$light-bg 0);
            position: fixed;
            width: 100%;
            height: 10px;
            bottom: 0;
            z-index: 4;
        }
        .popin-overlay {
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.5;
            position: absolute;
            top: 0;
            z-index: 6;
            display: block;
            &.hide-overlay {
                display: none;
            }
        }
        .scroll {
            display: block;
            transition: all ease 0.6s;
            &.not-visible {
                display: none;
            }
            img {
                position: fixed;
                height: 90px;
                right: 120px;
                bottom: 30px;
                animation: rotating 15s linear infinite;
                @keyframes rotating {
                    from {
                      transform: rotate(0deg);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                }
            }
        }
    }
    h1 {
        font-family: $font-semibold;
        text-transform: uppercase;
        font-size: 30px;
    }
    h2 {
        font-family: $font-medium;
        font-size: 20px;
        font-weight: 400;
    }
    h3 {
        font-family: $font-semibold;
        font-size: 18px;
    }
    p {
        font-family: $font-regular;
        font-size: 13px;
    }
    address {
        font-family: $font-regular;
        font-size: 13px;
        font-style: normal;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    .booking-button {
        display: flex;
        align-items: center;
        position: relative;
        color: $main-color;
        text-decoration: none;
        padding-left: 14px;
        padding-right: 15px;
        margin-top: 30px;
        transition: all ease 0.3s;
        width: fit-content;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: 220px;
        }
        @supports (-ms-ime-align:auto) {
            width: 220px;
        }
        &:before {
            content: "";
            display: block;
            height: 35px;
            width: 35px;
            border-radius: 20px;
            background-color: $secondary-color;
            position: absolute;
            left: 0;
            z-index: -1;
            transition: all ease 0.5s;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                top: 5px !important;
            }
        }
        .icon {
            transition: all ease 0.5s;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            background: white;
            &.arrow {
                transition: all ease 0.5s;
                left: 0.325rem;
                width: 1.125rem;
                height: 0.12rem;
                background: none;
                &::before {
                    position: absolute;
                    content: '';
                    top: -0.27rem;
                    right: 0.0625rem;
                    width: 0.625rem;
                    height: 0.625rem;
                    border-top: 0.125rem solid #fff;
                    border-right: 0.125rem solid #fff;
                    transform: rotate(45deg);
                }
            }
        }
        p {
            padding-left: 40px;
            font-size: 14px;
            width: 100% !important;
            transition: all ease 0.2s;
        }
        &:hover {
            color: white;
            &:before {
                width: 100%;
            }
            .icon {
                &.arrow {
                background: white;
                transform: translate(1rem, 0);
                }
            }
            p {
                color: white !important;
            }
        }
    }
    @keyframes desplode {
        0% {
          width: 400px;
          height: 400px;
          margin-left: -200px;
          margin-top: -200px;
          background-color: rgba(20, 180, 87,0.8);
        }
        100% {
          width: 0px;
          height: 0px;
          margin-left: 0px;
          margin-top: 0px;
          background-color: rgba(129, 80, 108,0.6);
        }
    }
    @keyframes explode {
        0% {
          width: 0px;
          height: 0px;
          margin-left: 0px;
          margin-top: 0px;
          background-color: rgba(42, 53, 80,0.2);
        }
        100% {
          width: 400px;
          height: 400px;
          margin-left: -200px;
          margin-top: -200px;
          background-color: $darker-secondary-color;
        }
      }
    .button-container {
        // overflow: hidden;
        // position: relative;
        // display: inline-block;
        // .button-animation {
        //     border-radius: 1000px;
        //     position: absolute;
        //     left:0;
        //     top:0;
        //     width: 0px;
        //     height: 0px;
        //     margin-left: 0px;
        //     margin-top: 0px;
        //     pointer-events: none;
        //     z-index: 8;
        //     &.explode-circle {
        //         animation: explode 0.5s forwards;

        //     }

        //     &.desplode-circle{
        //         animation: desplode 0.5s forwards;
        //     }
        // }

    }
    .button-animation-container {
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin-top: 15px;
        .button-circle{
            background-color: $secondary-color;
            border-radius: 1000px;
            position: absolute;
            left:0;
            top:0;
            width: 0px;
            height: 0px;
            margin-left: 0px;
            margin-top: 0px;
            pointer-events: none;
        }

        .regular-button {
            display: inline-block;
            color: white;
            font-size: 16px;
            font-weight: normal;
            width: auto;
            text-align: center;
            transition: 400ms;
            text-decoration: none;
            padding: 10px 20px;
            border: none;
            cursor: pointer;
            &.action {
                background-color: $secondary-color;
            }
            &.dark {
                background-color: $main-color;
            }
            &.light {
                background-color: #b9b9b9;
                font-size: 14px;
            }
            &:focus {
                outline: none;
            }
        }
        .button-text {
           position:relative;
           font-style: inherit;
           text-transform: uppercase;
           font-size: 15px;
           font-family: $font-medium;
        }
        .explode-circle {
            animation: explode 0.5s forwards;
            &.dark {
                animation: explodedark 0.5s forwards;
            }
        }

        .desplode-circle{
            animation: desplode 0.5s forwards;
            &.dark {
                animation: desplodedark 0.5s forwards;
            }
        }

        @keyframes explode {
            0% {
                width: 0px;
                height: 0px;
                margin-left: 0px;
                margin-top: 0px;
                background-color: $lighter-secondary-color;
            }
            100% {
                width: 400px;
                height: 400px;
                margin-left: -200px;
                margin-top: -200px;
                background-color: $darker-secondary-color;
            }
        }

        @keyframes desplode {
            0% {
                width: 400px;
                height: 400px;
                margin-left: -200px;
                margin-top: -200px;
                background-color: $darker-secondary-color;
            }
            100% {
                width: 0px;
                height: 0px;
                margin-left: 0px;
                margin-top: 0px;
                background-color: $lighter-secondary-color;
            }
        }

        @keyframes explodedark {
            0% {
                width: 0px;
                height: 0px;
                margin-left: 0px;
                margin-top: 0px;
                background-color: $lighter-main-color;
            }
            100% {
                width: 400px;
                height: 400px;
                margin-left: -200px;
                margin-top: -200px;
                background-color: $darker-main-color;
            }
        }

        @keyframes desplodedark {
            0% {
                width: 400px;
                height: 400px;
                margin-left: -200px;
                margin-top: -200px;
                background-color: $darker-main-color;
            }
            100% {
                width: 0px;
                height: 0px;
                margin-left: 0px;
                margin-top: 0px;
                background-color: $lighter-main-color;
            }
        }
    }


    .custom-control {
        display: inline-block;
        &.fill-checkbox {
            svg {
                fill: none;
                vertical-align: middle;
                circle {
                    stroke-width: 2;
                    stroke: white;
                    opacity: 0.4;
                }
                path {
                    stroke: white;
                    &.inner {
                        stroke-width: 6;
                        stroke-dasharray: 19;
                        stroke-dashoffset: 19;
                    }
                    &.outer {
                        stroke-width: 2;
                        stroke-dasharray: 57;
                        stroke-dashoffset: 57;
                    }
                }
            }
            .radio-date {
                display: none;
                &:checked + svg {
                    path {
                        transition: all .4s ease;
                        &.inner {
                        stroke-dashoffset: 38;
                        transition-delay: .3s;
                        }
                        &.outer {
                        stroke-dashoffset: 0;
                        }
                    }
                }
            }
            &.white-radio {
                svg {
                    circle {
                        stroke: white;
                    }
                    path {
                        stroke: white;
                    }
                }
            }
            &.red-radio {
                svg {
                    circle {
                        stroke: $secondary-color;
                    }
                    path {
                        stroke: $secondary-color;
                    }
                }
            }
        }
    }
    span {
        font-family: $font-medium-italic;
        font-size: 11px;
        @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
            font-family: $font-regular;
            font-style: italic;
        }
    }
    .scroll {
        display: none;
    }
    input[type="radio"] {
        display: none;
    }
    input[type="radio"] + label:before {
        content: '';
        background: #fff;
        border: 3px solid $secondary-color;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        padding: 2px;
        margin-right: 10px;
        text-align: center;
    }

    input[type="radio"]:checked + label:before {
        content: "";
        background: $secondary-color;
        border: 3px solid $secondary-color;
    }
    label {
        font-family: $font-regular;
        font-size: 14px;
    }
    .field {
        &.checkbox {
            input {
                width: 6px;
                opacity: 0;
                position: absolute;
                margin-top: 0;
            }
            label {
                width: 95%;
                margin-top: 15px;
                color: $main-color;
                font-family: $font-regular;
                font-size: 13px;
                a {
                    color: $secondary-color;
                    text-decoration: none;
                }
            }
            input + label:before {
                content: '';
                border: 3px solid $secondary-color;
                display: inline-block;
                vertical-align: middle;
                width: 6px;
                height: 6px;
                padding: 2px;
                margin-right: 5px;
                text-align: center;
            }

            input:checked + label:before {
                content: "";
                border: 3px solid $secondary-color;
                background: $secondary-color;
            }
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    body {
        &.homepage {
            .homepage-intro-section-pp, .homepage-slider-section-pp {
                width: 90vw;
            }
            .homepage-slider-section-pp {
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: 1400px;
                }
            }
            .homepage-footer-section-pp {
                width: 30vw;
            }
            .scroll {
                img {
                    height: 75px;
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    body {
        &.homepage {
            .homepage-intro-section-pp {
                width: 100vw;
            }
            .homepage-slider-section-pp {
                width: 1200px;
                max-width: 100%;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: 1280px;
                }
            }
            .homepage-footer-section-pp {
                width: 35vw;
            }
        }
        h1 {
            font-size: 25px;
        }
        h2 {
            font-size: 20px!important;
        }
        h3 {
            font-size: 15px!important;
        }
    }
}

@media screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    body {
        &.homepage {
            overflow-x: scroll;
            overflow-y: hidden !important;
            margin: 0;
            width: max-content;
            position: relative;
        }
    }
}

@media screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
        body {
            &.homepage {
                overflow-x: hidden;
                overflow-y: scroll !important;
            }
        }
  }

@media screen and (max-width: #{$tablet-landscape}px) {
    body {
        overflow-x: hidden;
        overflow-y: scroll !important;
        position: relative;
        &.homepage {
            overflow-x: hidden;
            overflow-y: scroll !important;
            width: 100%;
            flex-direction: column;
            position: relative;
            main {
                position: relative;
                width: 100%;
                .container {
                    flex-direction: column;
                }
            }
            .homepage-slider-section-pp {
                height: 100%;
                max-width: initial;
                width: 100vw;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: 100%;
                }
            }
            .homepage-footer-section-pp {
                width: 100%;
            }
            #_progress {
                display: none;
            }
            .homepage-intro-section-pp {
                height: 100vh;
                overflow: hidden;
                width: 100%;
            }
            .scroll {
                display: none;
            }
        }
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 16px!important;
        }
        h3 {
            font-size: 15px!important;
        }
        .regular-button {
            font-size: 13px;
        }
    }
}

@media screen and (max-width: #{$tablet-medium}px) {
    body {
        .homepage-intro-section-pp {
            height: 100%;
            .block-content {
                .float-text {
                    .bottom-content {
                        a {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        .homepage-footer-section-pp {
            width: 100vw;
        }
        h1 {
            font-size: 23px;
        }
        h2 {
            font-size: 15px!important;
        }
        h3 {
            font-size: 14px!important;
        }
    }
}
