.popins {
    position: fixed;
    background-color: white;
    height: 475px;
    width: 615px;
    top: 30%;
    left: 32%;
    z-index: 7;
    display: block;
    &.not-visible {
        display: none;
    }
    .info-box-popin-pp {
        text-align: center;
        position: relative;
        .popin-close {
            position: absolute;
            right: 20px;
            top: 20px;
            height: 35px;
            z-index: 7;
            cursor: pointer;
        }
        .popin-image-container {
            height: 200px;
            width: 100%;
            position: relative;
            overflow: hidden;
            .header-image {
                height: auto;
                width: 100%;
                position: absolute;
                min-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .popin-block-text {
            width: 90%;
            margin: 0 auto;
            p {
                color: $main-color;
            }
            button {
                cursor: pointer;
                background-color: $secondary-color;
                color: white;
                text-transform: uppercase;
                border: none;
                padding: 10px 15px;
                font-family: $font-medium;
            }
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    .popins {
        left: 28%;
        top: 19%;
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .popins {
        left: 28%;
        height: 420px;
        width: 515px;
    }
}

@media screen and (max-width: #{$tablet-medium}px) {
    .popins {
        height: 390px;
        width: 415px;
        .info-box-popin-pp {
            .popin-image-container {
                height: 150px;
            }
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    .popins {
        left: 23%;
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .popins {
        left: 21%;
        height: 390px;
        width: 305px;
        .info-box-popin-pp {
            .popin-close {
                height: 20px;
            }
            .popin-image-container {
                height: 110px;
            }
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    .popins {
        left: 13%;
        width: 275px;
    }
}