.cart, .checkout {
    footer {
        background-color: $main-color;
        height: 42px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0;
        nav {
            ul {
                display: flex;
                list-style-type: none;
                li {
                    padding-left: 10px;
                    padding-right: 10px;
                    span {
                        display: none;
                    }
                    a {
                        color: white;
                        font-family: $font-regular;
                        text-decoration: none;
                        font-size: 13px;
                    }
                }
            }
        }
        img {
            height: 40px;
            width: auto;
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .cart, .checkout {
        footer {
            flex-direction: column;
            height: auto;
            position: relative;
            nav {
                width: 100%;
                ul {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0;
                    padding: 0;
                    li {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}