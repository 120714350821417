.homepage-portrait-section {
    width: 60vw;
    .container {
        height: 80vh;
        padding-top: 23vh;
        .portrait-image-block {
            position: relative;
            width: 300px;
            height: 70vh;
            .image-container {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                img {
                    height: auto;
                    min-width: 100%;
                    position: absolute;
                    min-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .icon {
                position: absolute;
                height: 100px;
                left: -50px;
                bottom: 40px;
            }
        }
        .text-container {
            position: relative;
            color: $main-color;
            width: 73%;
            .text-block {
                position: absolute;
                left: -40px;
                top: 6vh;
                .giant-title {
                    font-size: 35px;
                    font-family: $font-semibold;
                    span {
                        color: $secondary-color;
                        font-size: 35px;
                        font-style: inherit;
                        font-family: $font-semibold;
                    }
                }
                .content {
                    width: 85%;
                    padding-left: 50px;
                    blockquote {
                        margin-inline-start: 0;
                        margin-inline-end: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    .homepage-portrait-section {
        width: 70vw;
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .homepage-portrait-section {
        width: 90vw;
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .homepage-portrait-section {
        width: 100%;
        margin-top: 70px;
        .container {
            flex-direction: row !important;
            width: 85%;
            margin: 0 auto;
            height: auto;
            padding-top: inherit;
            .portrait-image-block {
                min-width: 300px;
            }
            .text-container {
                .text-block {
                    top: 0;
                    .giant-title {
                        font-size: 35px;
                        span {
                            font-size: 35px;
                        }
                    }
                    .content {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    .homepage-portrait-section {
        .container {
            width: 100%;
            .portrait-image-block {
                .icon {
                    left: 115px;
                    bottom: -40px;
                    height: 80px;
                }
            }
            .text-container {
                .text-block {
                    top: 3vh;
                    .giant-title {
                        font-size: 30px;
                        span {
                            font-size: 30px;
                        }
                    }
                    .content {
                        width: 85%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .homepage-portrait-section {
        .container {
            flex-direction: column !important;
            .portrait-image-block {
                min-width: 100%;
                width: 100%;
                .image-container {
                    width: 100%;
                    img {
                        top: 80%;
                    }
                }
                .icon {
                    top: inherit;
                    left: inherit;
                    bottom: -40px;
                    right: 40%;
                }
            }
            .text-container {
                width: 100%;
                .text-block {
                    position: relative;
                    width: 90%;
                    margin: 70px auto;
                    top: inherit;
                    left: inherit;
                    text-align: center;
                    .giant-title {
                        font-size: 25px;
                        span {
                            font-size: 25px;
                        }
                    }
                    .content {
                        padding-left: 0;
                        width: 100%;
                        .button a {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}