body {
    &.homepage {
        footer {
            width: 31vw;
            z-index: 1;
            height: 100vh;
            padding-top: 100px;
            .container {
                display: block;
                height: 84%;
                #map {
                    height: 70%;
                    width: 100%;
                }
                .menu-sitemap {
                    height: 30%;
                    width: 100%;
                }
            }
            .sub-footer {
                height: 6%;
            }
        }
    }
}

footer {
    color: $main-color;
    height: 240px;
    .container {
        height: 100%;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        #map {
            height: 100%;
            width: 40%;
        }
        .menu-sitemap {
            background-color: $light-bg;
            height: 100%;
            width: 60%;
            .menu-container {
                width: 85%;
                margin: 0 auto;
                padding-top: 50px;
                h2 {
                    margin-block-end: 0;
                    margin-block-start: 0;
                }
                nav {
                    font-family: $font-regular;
                    .header-nav-list {
                        list-style: none;
                        padding-left: 0;
                        height: 90px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        .nav-item {
                            margin-bottom: 5px;
                            width: 50%;
                            span {
                                display: none;
                            }
                            a {
                                color: $main-color;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
    .sub-footer {
        background-color: $secondary-color;
        width: 100%;
        .container-fluid {
            .sub-footer__inner {
                display: flex;
                justify-content: center;
                p {
                    color: white;
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
                .footer-copyright {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    #footer-mobile {
        display: none;
    }
}

@media screen and (max-width: 1536px) {
    body {
        &.homepage {
            footer {
                .container {
                    height: 80%;
                    .menu-container {
                        padding-top: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    body {
        &.homepage {
            footer {
                width: 41vw;
                #map {
                    height: 35%;
                }
                .container {
                    height: 84%;
                    .menu-container {
                        padding-top: 50px;
                    }
                }
            }
        }
        footer {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1366px) {
    body {
        &.homepage {
            footer {
                width: 35vw;
                .container {
                    height: 77%;
                    .menu-container {
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    body {
        &.homepage {
            footer {
                padding-top: 90px;
                .container {
                    height: 80%;
                }
                .sub-footer {
                    height: 7%;
                }
            }
        }
        footer {
            width: 100%;
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    body {
        &.homepage {
            footer {
                width: 100%;
                height: 100%;
                .container {
                    height: 100%;
                    #map {
                        height: 300px;
                    }
                    .menu-sitemap {
                        height: auto;
                    }
                    .menu-container {
                        padding-top: 30px;
                    }
                }
            }
        }
        footer {
            #footer-mobile {
                display: block;
                position: fixed;
                bottom: 0;
                width: 100%;
                height: 50px;
                background-color: white;
                .container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    position: relative;
                    .phone-block, .mail-block {
                        display: flex;
                        justify-content: center;
                        width: 30%;
                        align-items: center;
                        img {
                            height: 25px;
                            margin-top: 10px;
                        }
                    }
                    .booking-block {
                        width: 40%;
                        height: 100%;
                        background-color: $secondary-color;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a {
                            color: white;
                            text-transform: uppercase;
                            display: flex;
                            font-family: $font-regular;
                            text-decoration: none;
                            #sylius-cart-button {
                                display: flex;
                                align-items: center;
                                position: relative;
                                p {
                                    font-size: 15px;
                                }
                                .cart-count {
                                    color: $secondary-color;
                                    font-family: $font-semibold;
                                    position: relative;
                                    text-align: center;
                                    font-size: 14px;
                                    font-style: normal;
                                    background-color: white;
                                    position: absolute;
                                    right: -22px;
                                    top: 5px;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 15px;
                                }
                            }
                        }
                    }
                }
            }
            .sub-footer {
                padding-bottom: 50px;
                .container-fluid {
                    .sub-footer__inner {
                        padding: 10px 0;
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    body {
        &.show {
            &.event {
                footer {
                    #footer-mobile {
                        display: none;
                    }
                }
            }
        }
        footer {
            height: auto;
            .container {
                flex-direction: column;
                #map {
                    height: 300px;
                    width: 100%;
                }
                .menu-sitemap {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    body {
        footer {
            #footer-mobile {
                .container {
                    .booking-block {
                        width: 50%;
                        a {
                            #sylius-cart-button {
                                p {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                    .mail-block, .phone-block {
                        width: 25%;
                    }
                }
            }
        }
    }
    footer {
        .container {
            .menu-sitemap {
                padding-bottom: 10px;
                .menu-container {
                    width: 95%;
                }
            }
        }
        .sub-footer {
            .container-fluid {
                .sub-footer__inner {
                    flex-direction: column;
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    footer {
        .container {
            .menu-sitemap {
                .menu-container {
                    h2 {
                        text-align: center;
                    }
                    nav {
                        .header-nav-list {
                            flex-direction: column;
                            flex-wrap: nowrap;
                            height: auto;
                            .nav-item {
                                text-align: center;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
