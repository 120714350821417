.sylius-validation-error {
    font-family: $font-semibold;
    font-size: 14px;
    &.red {
        color: $secondary-color;
    }
}

.error-page {
    background-color: $light-bg;
    .error-wrap {
        width: 50%;
        margin: auto;
        text-align: center;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            font-size: 25px;
            text-transform: uppercase;
            color: $main-color;
            margin-bottom: 0;
        }
        p {
            color: $main-color;
            font-size: 14px;
            margin-bottom: 60px;
        }
        .button-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            .button-animation-container {
                margin: 0 10px;
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px){
    .error-page {
        .error-wrap {
            height: auto;
            width: 90%;
            min-height: 85vh;
            img {
                width: 200px;
            }
            p {
                margin-bottom: 30px;
            }
            .button-wrap {
                flex-direction: column;
                .button-animation-container {
                    width: 100%;
                    margin: 10px 0;
                }
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px){
    .error-page {
        .error-wrap {
            min-height: 80vh;
            padding-top: 50px;
            .button-wrap {
                .button-animation-container {
                    width: 100%;
                }
            }
        }
    }
}