.checkout {
    .column-wrap {
        .column {
            &.address {
                width: 65%;
                background-color: white;
                h3 {
                    color: $main-color;
                }
                form {
                    width: 90%;
                    margin: auto;
                    .address-form {
                        input {
                            width: 100%;
                            border: none;
                            border-bottom: $secondary-color 5px solid;
                            font-family: $font-regular;
                            padding-bottom: 8px;
                            margin-bottom: 20px;
                            &:focus {
                                outline: none;
                            }
                        }
                        select {
                            width: 47%;
                            border: none;
                            border-bottom: $secondary-color 5px solid;
                            font-family: $font-regular;
                            margin-top: 8px;
                            margin-bottom: 20px;
                            padding-bottom: 8px;
                            background: none;
                            background: url("#{$medias_dir}/images/arrow-select.png") no-repeat center;
                            -webkit-appearance: none;
                            background-position-x: 100%;
                            &:focus {
                                outline: none;
                            }
                        }
                        .fields {
                            display: flex;
                            justify-content: space-between;
                            input {
                                width: 47%;
                            }
                        }
                    }

                    .address-book-select {
                        p {
                            margin: 0;
                        }
                        label {
                            display: flex;
                            p {
                                font-family: $font-medium;
                                font-size: 13px;
                                cursor: pointer;
                                margin-left: 5px;
                            }
                        }
                    }
                    .address-content {
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                        border-bottom: $light-bg 3px solid;
                        .address-details {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .checkout {
        .column-wrap {
            .column {
                &.address {
                    width: 100%;
                }
            }
        }
    }
}