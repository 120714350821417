.login {
    padding: 150px 0 40px;
    //min-height: 95vh;
    background-color: $light-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .message {
        width: 70%;
    }

    .login-header {
        width: 80%;
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .icon {
            background-image: url(#{$medias_dir}/images/icon-your-account.svg);
            background-repeat: no-repeat;
            background-position: center center;
            display: flex;
            height: 100px;
            width: 100px;
            margin-right: 30px;
        }

        .title-wrap {
            h2 {
                font-size: 26px;
                font-family: $font-semibold;
                color: $main-color;
                text-transform: uppercase;
                margin: 0;
            }

            p {
                color: $main-color;
                margin-top: 5px;
            }
        }
    }

    .login-form-wrap {
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: flex-start;

        .column {
            width: 47%;
            background: white;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-top: 60px;
            padding-bottom: 80px;
            &:nth-child(2) {
                form {
                    .submit-container {
                        .field:nth-child(2) {
                            .checkbox {
                                input {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            .message {
                border-radius: initial!important;
                padding: 0!important;
            }

            h3 {
                width: 70%;
                text-transform: uppercase;
                color: $main-color;
            }

            form {
                width: 70%;

                input {
                    width: 100%;
                    border: none;
                    border-bottom: $secondary-color 5px solid;
                    font-family: $font-regular;
                    padding-bottom: 8px;
                    margin-bottom: 20px;

                    &:focus {
                        outline: none;
                    }
                }

                a {
                    font-family: $font-regular;
                    color: $secondary-color;
                    text-decoration: none;
                    font-size: 13px;
                }

                .button-wrap {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;

                    button {
                        background-color: $secondary-color;
                        color: white;
                        border: 0;
                        padding: 10px 15px;
                        text-transform: uppercase;
                        cursor: pointer;
                        font-family: $font-medium;

                        &:focus {
                            outline: none;
                        }
                    }
                }

                .remember {
                    height: 20px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    input {
                        width: 6px;
                        opacity: 0;
                        position: absolute;
                        margin-top: 0;
                    }

                    label {
                        width: 95%;
                        margin-top: 15px;
                        color: $main-color;
                        font-family: $font-regular;
                        font-size: 13px;

                        a {
                            color: $secondary-color;
                            text-decoration: none;
                        }
                    }

                    input + label:before {
                        content: '';
                        border: 3px solid $secondary-color;
                        display: inline-block;
                        vertical-align: middle;
                        width: 6px;
                        height: 6px;
                        padding: 2px;
                        margin-right: 5px;
                        text-align: center;
                    }

                    input:checked + label:before {
                        content: "";
                        border: 3px solid $secondary-color;
                        background: $secondary-color;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 1366px) {
    .login {
        .login-header {
            margin-top: 150px;

            .title-wrap {
                h2 {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-small}px) {
    .login {
        .login-header {
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .login {
        height: auto;
        padding-top: 100px;

        .login-header {
            margin-bottom: 0;
            width: 90%;
            align-items: initial;
            margin-top: 0;

            .icon {
                height: 60px;
                background-size: 50px 50px;
                margin-right: 10px;
                width: 60px;
            }

            .title-wrap {
                width: 85%;

                p {
                    margin-top: 0;
                }
            }
        }

        .login-form-wrap {
            flex-direction: column;
            width: 90%;

            .column {
                width: 100%;
                padding-top: 10px;
                padding-bottom: 20px;
                margin-bottom: 20px;

                h3 {
                    width: 90%;
                }

                form {
                    width: 90%;
                }
            }

        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .login {
        .login-header {
            .icon {
                height: 50px;
                background-size: 40px 40px;
                width: 15%;
            }
        }
    }
}
