.single-show {
    color: $main-color;
    overflow: hidden;
    .flex {
        display: flex;
        align-items: center;
    }
    .shows-header {
        width: 100%;
        height: 350px;
        position: relative;
        .image {
            width: 70%;
            overflow: hidden;
            position: relative;
            height: 100%;
            img {
                height: auto;
                min-height: 100%;
                min-width: 100%;
                max-width: 120%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .title {
            width: 30%;
            height: 100%;
            background-color: $secondary-color;
            h1 {
                text-transform: initial;
                color: white;
                text-align: center;
                margin-top: 30%;
            }
        }
    }
    .container-sticky-motion {
        height: 267px;
        transition: all ease 0.5s;
        &.height {
            height: 190px;
        }
    }
    .show-details {
        width: 100%;
        background-color: $light-bg;
        padding: 25px 0;
        transition: all ease 2s;
        &.sticky {
            position: fixed;
            top: 77px;
            z-index: 5;
            transition: all ease 2s;
            padding: 8px 0;
            .container {
                .show-image {
                    display: none;
                }
                .show-description {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 0;
                    .details-container {
                        .flex-sub {
                            display: flex;
                            align-items: center;
                        }
                        .price {
                            display: none;
                        }

                        .address-dates {
                            .address-block {
                                display: none;
                            }
                            .dates-block {
                                border-left: 1px solid $main-color;
                                padding-left: 10px;
                                width: 85%;
                            }
                        }
                    }
                    .button {
                        margin-top: 0;
                    }
                }
            }
        }
        .container {
            width: 85%;
            margin: 0 auto;
            justify-content: space-between;
            img {
                max-height: 217px;
                width: auto;
            }
        }
        .show-description {
            width: 40%;
            h2, p {
                margin-block-start: 0;
                margin-block-end: 0;
                margin-top: 10px;
            }
            .sub-title {
                .show-type {
                    margin-right: 10px;
                }
                .author {
                    border-left: 1px solid $main-color;
                    padding-left: 10px;
                }
            }
            .address-dates {
                min-width: 400px;
                img {
                    height: 18px;
                    margin-top: 7px;
                }
                .address-block {
                    width: 150px;
                    p {
                        margin-left: 5px;
                    }
                }
                .dates-block {
                    width: 50%;
                    p {
                        margin-left: 5px;
                    }
                }
                .address-block {
                    border-right: 1px solid $main-color;
                    padding-right: 10px;
                }
                .dates-block {
                    margin-left: 10px;
                }
            }
            .button {
                margin-top: 50px;
            }
        }
    }
    .show-resume {
        width: 85%;
        margin: 0 auto;
        margin-top: 50px;
        transition: all ease 2s;
        .left-content, .right-content {
            width: 50%;
        }
        .left-content {
            h2 {
                text-transform: uppercase;
            }
            p {
                width: 65%;
            }
        }
        .right-content {
            height: 380px;
            position: relative;
            overflow: hidden;
            img {
                min-height: 100%;
                height: auto;
                min-width: 100%;
                max-width: 140%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .block-text {
        width: 85%;
        margin: 50px auto;
        h2 {
            text-transform: uppercase;
        }
    }
    .slider-photos {
        width: 100%;
        background-color: $light-bg;
        position: relative;
        height: 530px;
        overflow: hidden;
        #wrap{
            position: absolute;
            left: 0; 
            top: 0;
            width: auto;
            height: 100%;
            display: flex;
            align-items: stretch;
            padding: 0 0 0 25%;
            overflow: hidden;
            .show-image-hover {
                position: relative;
                width: auto;
                z-index: 1;
                display: flex;
                align-items: center;
                z-index: 2;
                transform: scale(.97);
                &:hover {
                    .content {
                        .text {
                            opacity: 1;
                            transform: scaleY(1) translateY(0);
                        }
                    }
                } 
                &:hover + .fullBg {
                    opacity: 1;
                    transform: scale(1.02);
                }
                .content {
                    position: relative;
                    display: block;
                    max-width: 100%;
                    margin: 0 50px;
                    img {
                        position: relative;
                        display: block;
                        height: 340px;
                        width: auto;
                        z-index: 2;
                        margin: 0 20px;
                    }
                    .text {
                        position: absolute;
                        top: 100%; 
                        left: 10%;
                        width: 80%;
                        opacity: 0;
                        padding: 1em 0 0 1em;
                        border-left: 1px solid;
                        z-index: 1;
                        transform: scaleY(1) translateY(-50px);
                        transition: transform .5s, opacity .5s;
                        color: $medium-grey;
                        .title {
                            font-size: 1.2em;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-block-start: 0;
                            margin-block-end: 0;
                        }
                    }
                }
            }
            .fullBg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: transform .5s, opacity .5s;
                transform: scale(1);
                z-index: 1;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    opacity: .5;
                }
            }
        }
    }
    .booking-show {
        margin-top: 70px;
        .booking-header {
            width: 85%;
            margin: 0 auto;
        }
        .slick-booking-date {
            width: 100%;
            background-color: $secondary-color;
            padding: 15px 0;
            color: white;
            font-size: 13px;
            .dates-slick {
                width: 85%;
                margin: 0 auto;
                .single-date {
                    position: relative;
                    p {
                        font-family: $font-semibold;
                        text-align: center;
                    }
                    .custom-control {
                        width: 100%;
                        text-align: center;
                        cursor: pointer;
                    }
                }
                .slick-prev {
                    &:before {
                        width: 0; 
                        height: 0; 
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent; 
                        border-right: 10px solid white;
                        font-size: 0;
                    }
                }
                .slick-next {
                    &:before {
                        width: 0; 
                        height: 0; 
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-left: 10px solid white;
                        font-size: 0;
                    }
                }
            }
        }
        .select-number {
            width: 85%;
            margin: 20px auto;
            p {
                margin-block-end: 0;
                margin-block-start: 0;
            }
            .contact {
                font-size: 11px;
                font-style: italic;
                margin-top: 5px;
                a {
                    color: $secondary-color;
                    text-decoration: none;
                }
            }
            .event-date-price {
                width: 50%;
                margin-top: 20px;
                .event-date-price-form {
                    &:first-child {
                        display: block !important;
                    }
                    &.active {
                        display: block !important;
                    }
                    form {
                        .dates-choices {
                            display: flex;
                            align-items: center
                        }
                        .date-choice-container {
                            .date-choice-price {
                                color: $secondary-color;
                            }
                            .select-container {
                                display: flex;
                                align-items: center;
                                margin-right: 20px;
                            }
                            .select {
                                select {
                                    margin-left: 15px;
                                    border: none;
                                    border-bottom: 3px solid $secondary-color;
                                    background-color: transparent;
                                    &:focus {
                                        outline: none;
                                    }
                                    option {
                                        background-color: $secondary-color;
                                        border: none;
                                        color: white;
                                        border-bottom: 1px solid white;
                                        &:hover {
                                            background-color: $darker-secondary-color !important;
                                        }
                                    }
                                }
                            }
                        }
                        .button-container {
                            text-align: end;
                            margin-left: auto;
                            button {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$desktop-medium}px) {
    .single-show {
        .slider-photos {
            #wrap {
                width: 200%;
            }
        }
        .show-details {
            .show-description {
                .address-dates {
                    min-width: 550px;
                }
            }
        }
    }
}

@media screen and (max-width :#{$desktop-small}px) {
    .single-show {
        .slider-photos {
            #wrap {
                width: 250%;
            }
        }
        .shows-header {
            .title {
                h1 {
                    margin-top: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: #{$tablet-landscape}px) {
    .single-show {
        .shows-header {
            flex-direction: column;
            margin-top: 63px;
            height: inherit;
            .image {
                width: 100%;
                height: 250px;
            }
            .title {
                width: 100%;
                height: 100px;
                h1 {
                    margin-top: 35px;
                }
            }
        }
        .show-details {
            &.sticky {
                top: 63px;
                .show-description {
                    .address-dates {
                        min-width: 270px;
                        .dates-block {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .show-resume {
            &.flex {
                flex-direction: column;
            }
            .left-content {
                width: 87%;
                p {
                    width: 100%;
                }
            }
            .right-content {
                width: 100%;
                margin-top: 30px;
            }
        }
        .booking-show {
            .select-number {
                .event-date-price {
                    width: 75%;
                }
            }
        }
        .slider-photos {
            overflow-x: scroll;
            #wrap {
                width: 320%;
                .show-image-hover {
                    .content {
                        .text {
                            opacity: 1;
                            transform: scaleY(1) translateY(0);
                            color: $main-color;
                        }
                    }
                }
                .fullBg {
                    display: none;
                }
            }
        }
        .block-text {
            width: 87%;
        }
    }
}

@media screen and (max-width: #{$tablet-portrait}px) {
    .single-show {
        .container-sticky-motion {
            height: auto;
        }
        .show-details {
            .container {
                width: 95%;
                &.flex {
                    flex-direction: column-reverse;
                }
                .show-description {
                    text-align: center;
                    width: 100%;
                    .details-container {
                        width: 100%;
                    }
                    .sub-title, .address-dates {
                        justify-content: center;
                    }
                    .button-animation-container {
                        position: fixed;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        z-index: 50000;
                        a {
                            display: flex;
                            justify-content: center;
                            padding: 17px 0;
                        }
                    }
                    .address-dates {
                        width: 360px;
                        margin: 0 auto;
                        .address-block {
                            width: 140px;
                        }
                        .dates-block {
                            width: 238px;
                        }
                    }
                }
            }
        }
        .show-resume {
            width: 95%;
        }
        .booking-show {
            .booking-header {
                width: 95%;
            }
            .select-number {
                width: 95%;
                .event-date-price {
                    width: 100%;
                    .event-date-price-form {
                        form {
                            .button-container {
                                text-align: center;
                            }
                            .dates-choices {
                                flex-direction: column;
                                align-items: flex-start;
                                .button-container {
                                    margin: 30px 0;
                                    margin-left: inherit;
                                }
                            }
                        }
                    }
                }
            }
        }
        .slider-photos {
            #wrap {
                width: 400%;
            }
        }
    }
}

@media screen and (max-width: #{$mobile-landscape}px) {
    .single-show {
        .slider-photos {
            #wrap {
                width: 570%;
            }
        }
        .booking-show {
            margin-top: 35px;
        }
    }
}

@media screen and (max-width: #{$mobile-portrait}px) {
    .single-show {
        .shows-header {
            .title {
                height: 130px;
                h1 {
                    margin-top: 50px;
                }
            }
        }
        .show-resume {
            .right-content {
                height: 300px;
            }
        }
        .show-details {
            .container {
                .show-description {
                    .address-dates {
                        flex-direction: column;
                        min-width: inherit;
                        width: inherit;
                        .address-block {
                            border: none;
                        }
                        .dates-block {
                            width: 100%;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}