@charset "UTF-8";

.customer-account {
  background: $light-bg;
  padding: 150px 0 40px 0;
  min-height: 60vh;
  font-family: $font-regular;

  .sylius-flash-message {
    width: 70%;
    margin: auto;
  }

  .account-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;

    .account-nav {
      width: 300px;
      padding: 40px;
      background: white;

      .menu {
        display: flex;
        flex-direction: column;

        .item {
          text-decoration: none;
          color: $main-color;
          padding: 5px 0;
          &.header {
            font-family: $font-semibold;
            text-transform: uppercase;
            color: $secondary-color;
            margin-bottom: 15px;
            font-size: 20px;
          }
        }
      }
    }

    .account-content {
      width: auto;
      margin-left: 30px;
      background: white;
      padding: 40px;
      flex-grow: 1;

      h1 {
        font-size: 24px;
        margin: 0;
        .sub {
          font-size: 16px;
          text-transform: none;
        }
        margin-bottom: 20px;
      }

      .logout {
        position: absolute;
        top: 0;
        right: 15px;
      }

      form {
        width: 70%;
      }

        .sylius-grid-wrapper {
            .pagination, .dropdown {
                flex-direction: row;
                margin: 10px 0;

                .item {
                    background: initial;
                    color: initial;
                    margin: 0 10px;
                    padding: initial;
                }

                a.item {
                    &:hover {
                        color: $secondary-color;
                        transition: color .3s ease-in-out;
                    }
                }

                .menu {
                    display: inline-flex;
                    flex-direction: row;
                }
            }
        }

      .booking-button {
        &:before {
          z-index: 0;
        }
        p {
          z-index: 1;
        }
      }

      .styled {
        &.header {
          font-family: $font-semibold;
          font-size: 17px;
          margin: 5px 0;
        }
      }

      .buttons {
        .button {
          padding: 10px 20px;
          background: $secondary-color;
          color: white;
          display: inline-block;
          border: 0px;
          margin: 10px 0;
          text-decoration: none;
          font-family: $font-regular;
          font-size: 16px;
          cursor: pointer;
          border-radius: 0px;
        }
      }

      .horizontal {
        display: flex;
        flex-direction: row;
        align-items: center;

        .item {
          margin-right:10px;
        }
      }

      table {
        border: 1px solid $medium-grey;
        width: 100%;
        border-collapse: collapse;

        thead {
          th {
            font-family: $font-semibold;
            padding: 5px;
            color: $main-color;
            border: 1px solid $medium-grey;

            &.quantity {
              display: none;
            }

            a {
              color: $main-color;
              text-decoration: none;
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: center;
              font-size: 14px;
              border: 1px solid $medium-grey;

              .sylius-product-name {
                font-style: normal;
                font-size: 15px;
              }

              &.quantity {
                display: none;
              }
            }
          }
        }

        tfoot {
          .totals {
            padding-left: 15px;
          }
        }
      }

      #customer-information {
        margin-bottom: 10px;
      }

      .green {
        background: $success-color;
        color: white;
        padding: 5px;
        font-style: normal;
        border-radius: 4px;
        display: inline-block;
        margin: 5px 0;
      }

      .red {
        background: $lighter-secondary-color;
        color: white;
        padding: 5px;
        font-style: normal;
        border-radius: 4px;
        display: inline-block;
        margin: 5px 0;
      }

      .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
          &.item {
            color: white;
            background: $secondary-color;
            padding: 10px 20px;
            text-decoration: none;
            margin: 5px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: #{$tablet-landscape}px) {
  .customer-account {
    padding: 100px 0 40px 0;
    .account-container {
      flex-direction: column;
      width: 90%;
      .account-nav {
        width: auto;
      }

      .account-content {
        margin-left: 0;
        margin-top: 15px;

        form {
          width: 100%;
        }

        .logout {
          .regular-button {
            padding: 5px 10px;
            .button-text {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: #{$mobile-landscape}px) {
  .customer-account {
    .account-container {
      width: 100%;
      .account-nav {
        width: auto;
        padding: 15px;
      }

      .account-content {
        padding: 15px;

        .logout {
          right: 0;
        }

        table {
          thead {
            th {
              font-size: 13px;
            }
          }

          tbody {
            tr {
              td {
                font-size: 12px;

                .sylius-product-name {
                  font-size: 13px;
                }
              }
            }
          }

          tfoot {
            .totals {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
