@charset "utf-8";

body.event {
    .event-date-price {
        position: relative;

        .form-alert {
            display: flex;
            border-radius: 4px;
            padding: 10px;
            margin: 5px;
            align-items: center;
            font-family: $font-regular;

            &:empty {
                display: none;
            }

            p {
                font-size: 14px;
                font-weight: bold;
                color: $lighter-gray;
            }

            ul {
                list-style-type: none;
                font-size: 14px;
                color: $lighter-gray;
                padding: 0;
                margin: 0;
            }

            &.success {
                background-color: $success-color;
            }

            &.error {
                background-color: $secondary-color;
            }
        }

        .event-date-price-loader {
            &.loading {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $lighter-gray;
                background-image: url(#{$medias_dir}/images/loader.gif);
                background-repeat: no-repeat;
                background-position: center center;
                opacity: .8;
                z-index: 2;
                background-size: auto 100%;
            }
        }
    }
}
