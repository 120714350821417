@charset "utf-8";

.block-text {
    .videos-container {
        p {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0;
            padding: 0;

            iframe {
                width: 30%;
                height: 350px;
                margin-top: 15px;
            }
        }
    }
}


@media screen and (max-width :#{$desktop-medium}px) {
    .block-text {
        .videos-container {
            p {
                iframe {
                    height: 300px;
                }
            }
        }
    }
}

@media screen and (max-width :#{$tablet-landscape}px) {
    .block-text {
        .videos-container {
            p {
                justify-content: space-around;

                iframe {
                    width: 40%;
                    height: 200px;
                }
            }
        }
    }
}

@media screen and (max-width :#{$tablet-portrait}px) {
    .block-text {
        .videos-container {
            p {
                justify-content: space-around;

                iframe {
                    width: 80%;
                    height: 300px;
                }
            }
        }
    }
}

@media screen and (max-width :#{$mobile-portrait}px) {
    .block-text {
        .videos-container {
            p {
                justify-content: space-around;

                iframe {
                    width: 100%;
                    height: 200px;
                }
            }
        }
    }
}
